import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import RoomerModal from "../RoomerModal";
import usePageApi from "../../../hooks/usePageApi";
import ActionButtons from "../../../components/ActionButtons";
import { filter, map } from "lodash";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
import { showSuccessToast } from "../../../utils/services/toast";
import useLoader from "../../../hooks/useLoader";

export default function RoomerDetails({ selected }) {
  const { dummyIndividualId } = selected;
  const [roomerOpen, setRoomerOpen] = useState(false);
  // const [editRoomerOpen, setEditRoomerOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [createNew, setCreateNew] = useState(true);
  const [columnData, setColumnData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const { pageData, labels, retrieveJson, saveJson } = usePageApi({
    screenDefn: "/dashboard/consumer-app/intake-app/members/room",
    screenId: 156,
  });

  useEffect(() => {
    if (pageData) {
      setApiData(pageData);
    }
  }, [pageData]);

  const AddRoomerOpen = () => {
    setEditData({});
    setCreateNew(true);
    setRoomerOpen(true);
  };

  const toggleClose = () => {
    setEditData({});
    setRoomerOpen(false);
    setIsDeleteOpen(false);
  };

  const editRoomer = (data) => {
    setCreateNew(true);
    setEditData(data);
    setRoomerOpen(true);
  };

  const handleDeleteModal = (data) => {
    let { id } = data;
    setDeleteData(id);
    setIsDeleteOpen(true);
  };

  const roomerColumns = useMemo(() => [
    {
      field: "effectiveBeginDate",
      headerName: "Begin Date",
      width: 150,
      renderCell: (params) => {
        const bgnDt = params?.row?.roomerBoarder?.effectiveBeginDate;
        if (bgnDt) {
          const dateObj = new Date(bgnDt);
          return dateObj.toLocaleDateString();
        } else {
          return "";
        }
      },
    },
    {
      field: "effectiveEndDate",
      headerName: "End Date",
      width: 130,
      renderCell: (params) => {
        const endDt = params?.row?.roomerBoarder?.effectiveEndDate;
        if (endDt) {
          const dateObj = new Date(endDt);
          return dateObj.toLocaleDateString();
        } else {
          return "-";
        }
      },
    },
    {
      field: "numberOfMealsPerDay",
      headerName: "Meals Per Day",
      width: 170,
      renderCell: (params) =>
        `${params?.row?.roomerBoarder?.numberOfMealsPerDay ?? "-"}`,
    },
    {
      field: "numberOfHouseholdMembers",
      headerName: "Household Members",
      width: 230,
      renderCell: (params) =>
        `${params?.row?.roomerBoarder?.numberOfHouseholdMembers ?? "-"}`,
    },
    {
      field: "amountPaid",
      headerName: "Amount Paid",
      width: 190,
      renderCell: (params) =>
        ` $ ${params?.row?.roomerBoarder?.amountPaid ?? "-"}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div>
          <ActionButtons
            onEdit={() => editRoomer(params?.row)}
            onDelete={() => handleDeleteModal(params?.row)}
          />
        </div>
      ),
    },
  ]);

  const handleDelete = async () => {
    try {
      showLoader();
      if (modifiedData && modifiedData.length > 0) {
        const payload = modifiedData.filter((item) => item.id !== deleteData);
        const res = await saveJson(payload);
        if (res.status === 200) {
          showSuccessToast("Sucessfully Deleted");
          retrieveJson();
          setDeleteData("");
          setIsDeleteOpen(false);
        }
      }
    } catch (err) {
    } finally {
      hideLoader();
    }
  };

  const modifiedData = map(pageData, (item, index) => ({
    ...item,
    id: index + 1,
  }));

  useEffect(() => {
    setColumnData(roomerColumns);
    setRowData(
      filter(modifiedData, (value) => value.individualId === dummyIndividualId)
    );
  }, [pageData, dummyIndividualId]);

  return (
    <>
      <Grid mt={2}>
        <Stack direction={"row"} mb={1} justifyContent="end">
          {/* <Button variant="outlined" size="small">
            Upload Files
          </Button> */}
          <Button onClick={() => setRoomerOpen(true)}>
            + Add Roomer Boarder
          </Button>
        </Stack>
        <DataGrid
          autoHeight
          rows={rowData ?? []}
          columns={roomerColumns ?? []}
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "1.1rem",
              fontWeight: "700",
              padding: "0rem 0.6rem",
              color: "#000000",
            },
            "& .MuiDataGrid-row": {
              fontSize: "1rem",
              color: "black",
              padding: "0rem 0.6rem",
              textAlign: "center",
            },
          }}
          hideFooter
        />
      </Grid>
      <Dialog maxWidth="md" open={roomerOpen} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Roomer Boarder Details</Typography>
            <IconButton onClick={toggleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <RoomerModal
            toggleClose={toggleClose}
            pageData={modifiedData}
            dummyIndividualId={dummyIndividualId}
            saveJson={saveJson}
            editData={editData}
            retrieveJson={retrieveJson}
            createNew={createNew}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteOpen} onClose={toggleClose}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React, { useEffect } from "react";
import PageContainer from "../../components/PageContainer";
import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Field from "../../components/Field";
import { Controller, useForm } from "react-hook-form";
import YesNoOption from "../../components/YesNoOption";
import usePageApi from "../../hooks/usePageApi";
import useDynamicNav from "../../hooks/useDynamicNav";
import CInput from "../../components/inputBox";
import CCheckBox from "../../components/checkBox";
import usePageNav from "../../hooks/usePageNav";
import ScreenProvider from "../../containers/ScreenProvider";
import useLoader from "../../hooks/useLoader";
import { showErrorToast } from "../../utils/services/toast";
import CoTextField from "../../controllers/CoTextField";
import { formatCurrency } from "../../utils/normalizers";

export default function SnapQualifier({ screenId }) {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const { labels, pageData, saveJson, retrieveJson } = usePageApi({
    callRetrieveStatus: true,
    screenId,
    screenDefn: "/dashboard/consumer-app/intake-app/assistance/fspQualifier",
  });

  const [showLoader, hideLoader] = useLoader();
  const { control, watch, getValues, setValue, register } = useForm({
    defaultValues: {
      hohIncome: "",
      hohIncomeamount: "",
      hohAccount: "",
      hohAccAmount: "",
      bankAmount: "",
      hohFarm: "",
      snapDetails: "",
      ...pageData,
    },
  });

  const isHohIncome = watch("hohIncome") === "Y";
  const isHohAccount = watch("hohAccount") === "Y";

  const onBack = () => {
    navigations.toGetStarted();
  };

  const onSave = async () => {
    const payload = {
      dirtyStatus: "INSERT",
      controlId: null,
      screenDefn: "/dashboard/consumer-app/intake-app/assistance/fspQualifier",
      screenId: 197,
      memId: "",
      hohMonthlyRent: getValues("hohIncome"),
      monthRentOrMortgage: getValues("hohIncomeamount"),
      lessIncomeHoh: getValues("hohAccount"),
      grossMonthlyIncome: getValues("hohAccAmount"),
      bankAmount: getValues("bankAmount"),
      hohIsMigrant: getValues("hohFarm"),
      hohSnapcheck: getValues("snapDetails"),
    };
    try {
      showLoader();
      const res = await saveJson(payload);
      if (res?.status === 200) {
        handleNext();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const data = pageData;
  console.log(data);

  useEffect(() => {
    if (data) {
      setValue("hohIncome", data.hohMonthlyRent);
      setValue("hohIncomeamount", data.monthRentOrMortgage);
      setValue("hohAccount", data.lessIncomeHoh);
      setValue("hohAccAmount", data.grossMonthlyIncome);
      setValue("bankAmount", data.bankAmount);
      setValue("hohFarm", data.hohIsMigrant);
      setValue("snapDetails", data.hohSnapcheck);
    }
  }, [data]);

  const snapChecked = watch("snapDetails");

  const isNextDis = snapChecked !== true;

  return (
    <ScreenProvider labels={labels}>
      <Box sx={{ paddingLeft: "1rem" }}>
        <PageContainer
          title="SNAP(Supplemental Nutrition Assistance Program) Qualification"
          onNext={onSave}
          onBack={onBack}
          disableNext={isNextDis}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Paper
              sx={{
                mt: "2rem",
                paddingRight: "5rem",
                paddingBottom: "2rem",
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                paddingTop: "1rem",
              }}
            >
              <Grid container spacing={2} ml="0.1rem" mr="0.1rem">
                <Grid item xs={12}>
                  <Typography variant="h6" color="grey">
                    {labels?.snapDetails ??
                      "If you are applying for the Supplemental Nutrition Assistance Program (SNAP), you can complete all of the online application form and send it to us now or you may fill in your name and address, sign the application and send it to us. You may get benefits faster if you complete the entire form now. Your Supplemental Nutrition Assistance Program (SNAP) benefit is based on the date your signed application is received in the Department of Social Services."}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                // spacing={2}
                ml="1rem"
                padding={5}
                sx={{
                  padding: "1rem",
                  border: "1px solid var(--grey-350)",
                  borderRadius: "6px",
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="h6" color="grey">
                    {labels?.qcConditions ??
                      " You may get qualified right away if you meet one of the following conditions:"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ gap: "2rem", display: "flex", flexDirection: "column" }}
                >
                  <Stack xs={12}>
                    <Field
                      name="hohIncome"
                      label="Is your household's income and resources less than your household's monthly rent or mortgage and utilities?"
                    >
                      <Controller
                        name="hohIncome"
                        control={control}
                        render={({ field }) => {
                          return <YesNoOption {...field} name="hohIncome" />;
                        }}
                      />
                    </Field>
                  </Stack>
                  {isHohIncome && (
                    <>
                      <Stack
                        direction="row"
                        spacing={4}
                        mt={1}
                        ml={2}
                        alignItems="center"
                      >
                        <Typography variant="h6" color="grey">
                          {labels?.rentMortage ??
                            " How much is your monthly Rent or mortgage and utilities"}
                        </Typography>
                        <CoTextField
                          name="hohIncomeamount"
                          control={control}
                          normalizers={[formatCurrency]}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </>
                  )}
                  <Stack xs={12}>
                    <Field
                      name="hohAccount"
                      label="Is your household's gross monthly income less than $150 and your resources such as bank accounts $100 or less?"
                    >
                      <Controller
                        name="hohAccount"
                        control={control}
                        render={({ field }) => {
                          return <YesNoOption {...field} name="hohAccount" />;
                        }}
                      />
                    </Field>
                  </Stack>
                  {isHohAccount && (
                    <>
                      <Stack
                        direction="row"
                        spacing={4}
                        mt={1}
                        ml={2}
                        alignItems="center"
                      >
                        <Typography
                          variant="h6"
                          color="grey"
                          sx={{ width: "30%" }}
                        >
                          {labels?.grossIncome ??
                            " How much is your gross monthly income?"}
                        </Typography>
                        <CoTextField
                          name="hohAccAmount"
                          control={control}
                          normalizers={[formatCurrency]}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </>
                  )}
                  {isHohAccount && (
                    <>
                      <Stack
                        direction="row"
                        spacing={4}
                        mt={1}
                        ml={2}
                        alignItems="center"
                      >
                        <Typography
                          variant="h6"
                          color="grey"
                          sx={{ width: "30%" }}
                        >
                          {labels?.bankAccounts ??
                            " How much do you have in resources, such as bank accounts?"}
                        </Typography>
                        <CoTextField
                          name="bankAmount"
                          control={control}
                          normalizers={[formatCurrency]}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </>
                  )}
                  <Stack xs={12}>
                    <Field
                      name="hohFarm"
                      label="Is your household a migrant or seasonal farm worker household?"
                    >
                      <Controller
                        name="hohFarm"
                        control={control}
                        render={({ field }) => {
                          return <YesNoOption {...field} name="hohFarm" />;
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
                <Grid item xs={12} mt="1rem" color="grey">
                  <CCheckBox
                    control={control}
                    name="snapDetails"
                    label="I want to sign the application now and complete the rest at a different time. By clicking the Submit Application button, I understand that I may be interviewed and may be required to provide proof of who I am before I can get any benefits."
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </PageContainer>
      </Box>
    </ScreenProvider>
  );
}

import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import { Typography } from "@mui/material";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import clsx from "clsx";

function SummaryHeader({ isOpen, title, renderRtContent }) {
  return (
    <div
      className={clsx(styles.title, {
        [styles.collapsed]: !isOpen,
      })}
    >
      <Typography variant="h4">{title}</Typography>
      {renderRtContent && renderRtContent()}
      {isOpen ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
    </div>
  );
}

SummaryHeader.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  renderRtContent: PropTypes.func,
};

SummaryHeader.defaultProps = {
  isOpen: true,
  title: "",
  renderRtContent: null,
};

export default SummaryHeader;

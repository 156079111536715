import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import CheckIcon from "@mui/icons-material/Check";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ClearIcon from "@mui/icons-material/Clear";

export default function EligibilitySummary({ data, toggleClose }) {
  const abbreviation = {
    ltc: "Long Term Care (LTC)",
    fsp: "Food Supplement Program (FSP)",
    tca: "Temporary Cash Assistance (TCA)",
    eusp: "Electric Universal Service Program (EUSP)",
    meap: "Energy Assistance Program (EAP)",
    tdap: "Temporary Disability Assistance Program (TDAP) ",
    maFam: "Medical Assistance or Medicaid For Family (MAFAM)",
    maNotFam:
      "Age Blind Disabled for Medical Assistance or Medicaid (MANOTFAM)",
    fsa: "Food Supplement Benefits Right (FSA)",
    mchp: "Children's Health Program (CHP)",
  };
  const extraDetails = [
    "You have less than $150 gross monthly income and less than $100 in money or bank accounts",
    "Your household's combined gross income and money in the bank areless than your monthly rent or mortgage and utilities.",
    "Someone in your household is a migrant or seasonal farm worker.",
  ];

  const eligibleKeys = [
    "ltc",
    "fsp",
    "tca",
    "eusp",
    "eap",
    "tdap",
    "maFam",
    "maNotFam",
    "fsa",
    "mchp",
    "meap",
  ];

  const eligiblePrograms = Object.entries(data).filter(
    ([key, value]) => eligibleKeys.includes(key) && value === true
  );

  // const eligiblePrograms = Object.entries(data).filter(([key, value]) => value);

  console.log(eligiblePrograms, "0000");
  const nonEligiblePrograms = Object.entries(data).filter(
    ([key, value]) => eligibleKeys.includes(key) && value === false
  );
  return (
    <Box>
      <Box
        sx={{
          border: "1px solid lightgrey",
          margin: " 0 1rem 1rem 1rem",
          borderRadius: "0.5rem",
          height: "100%",
          p: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                border: "1px solid var(--grey-350)",
                borderRadius: "0.5rem",
                height: "100%",
                padding: "0 0 0.8rem 0",
              }}
            >
              <Box sx={{ textAlign: "-webkit-center" }}>
                <Box
                  sx={{
                    background: "var(--eligibileBackgroud)",
                    borderRadius: "8px 8px 0px 0px",
                  }}
                >
                  <Box
                    sx={{
                      background: "var(--eligibileBackgroud)",
                      // padding: "0.8rem 2rem",
                      display: "flex",
                      gap: "1rem",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "5rem",
                        height: "5rem",
                        alignContent: "center",
                        textAlignLast: "center",
                        borderRadius: "0.8rem",
                        marginBottom: "0.5rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <GppGoodOutlinedIcon
                        sx={{
                          fontSize: "3rem",
                          color: "var(--eligible-green)",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "1.4rem",
                          fontWeight: "700",
                          marginTop: "0.5rem",
                        }}
                      >
                        Eligible Programs
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          marginTop: "0.5rem",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--eligible-green)",
                            fontSize: "1.2rem",
                            fontWeight: "700",
                          }}
                        >
                          {`0${eligiblePrograms.length}/08`}{" "}
                        </Typography>
                        <Typography sx={{ fontSize: "1.1rem" }}>
                          Programs
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <List>
                  <ListItem>
                    <Typography variant="h4grey">Program Summary</Typography>
                  </ListItem>

                  {eligiblePrograms.map(([key, value]) => (
                    <ListItem
                      key={key}
                      sx={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <ListItemAvatar sx={{ minWidth: "2.3rem" }}>
                        <CheckIcon sx={{ color: "var(--eligible-green)" }} />
                      </ListItemAvatar>
                      <Typography sx={{ fontSize: "1.2rem" }}>
                        {abbreviation[key]}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                border: "1px solid var(--grey-350)",
                borderRadius: "0.5rem",
                height: "100%",
                padding: "0 0 0.8rem 0",
              }}
            >
              <Box sx={{ textAlign: "-webkit-center" }}>
                <Box
                  sx={{
                    background: "var(--eligibileBackgroud)",
                    borderRadius: "8px 8px 0px 0px",
                  }}
                >
                  <Box
                    sx={{
                      background: "var(eligibileBackgroud)",
                      // padding: "0.8rem 2rem",
                      display: "flex",
                      gap: "1rem",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "5rem",
                        height: "5rem",
                        // alignContent: "center",
                        // textAlignLast: "center",
                        borderRadius: "0.8rem",
                        marginBottom: "0.5rem",
                        paddingTop: "0.5rem",
                        // marginTop: "0.5rem",
                      }}
                    >
                      <GppBadOutlinedIcon
                        style={{
                          fontSize: "3rem",
                          color: "var(--inEligible-red)",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "1.4rem",
                          fontWeight: "700",
                          marginTop: "0.5rem",
                        }}
                      >
                        Ineligible Programs
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          marginTop: "0.5rem",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--inEligible-red)",
                            fontSize: "1.2rem",
                            fontWeight: "700",
                          }}
                        >
                          {`0${nonEligiblePrograms.length}/08`}
                        </Typography>
                        <Typography sx={{ fontSize: "1.1rem" }}>
                          Programs
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <List>
                  <ListItem>
                    <Typography variant="h4grey">Program Summary</Typography>
                  </ListItem>
                  {nonEligiblePrograms.map(([key, value]) => (
                    <ListItem
                      key={key}
                      sx={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <ListItemAvatar sx={{ minWidth: "2.3rem" }}>
                        <ClearIcon sx={{ color: "var(--inEligible-red)" }} />
                      </ListItemAvatar>
                      <Typography sx={{ fontSize: "1.2rem" }}>
                        {abbreviation[key]}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            border: "1px solid  var(--grey-350)",
            margin: "1rem 0rem",
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              background: "var(--eligibileBackgroud)",
              padding: "1rem ",
              marginBottom: "1rem",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            You may be eligible for Food Supplement Benefits Right away if:
          </Typography>
          {extraDetails.map((values, key) => (
            <Box key={key} sx={{ display: "flex", p: "0.3rem 0.5rem" }}>
              <CheckIcon sx={{ color: "var(--eligible-green)" }} />
              <Typography sx={{ paddingLeft: "0.8rem" }}>{values}</Typography>
            </Box>
          ))}

          <Box sx={{ display: "flex", p: "0.3rem 0.5rem" }}>
            <ErrorOutlineIcon sx={{ color: "var(--grey-400)" }} />
            <Typography
              variant="caption"
              sx={{ color: "black", paddingLeft: "0.8rem" }}
            >
              Please Note: pre-screening is for informational purposes only. To
              confirm your eligibility for any benefits program, you must
              complete and submit an application for services. You have the
              right to apply for services whether you are eligible for a program
              or not.
            </Typography>
          </Box>
        </Box>
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" onClick={toggleClose}>
            Close
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

import Field from "../../../components/Field";
import { DatePicker } from "@mui/x-date-pickers";
import CDatePicker from "../../../components/DatePicker";
import { useForm } from "react-hook-form";
import CoTextField from "../../../controllers/CoTextField";
import * as yup from "yup";
import { formatCurrency, maxLength, number } from "../../../utils/normalizers";
import { yupResolver } from "@hookform/resolvers/yup";
import useLoader from "../../../hooks/useLoader";
import { RoomerBoarderPayload } from "../../../services/RoomerBoarder/apiPayload";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../utils/services/toast";

export default function RoomerModal({
  toggleClose,
  dummyIndividualId,
  saveJson,
  retrieveJson,
  createNew,
  editData,
  pageData,
}) {
  console.log(editData, "000000");

  const {
    effectiveBeginDate,
    effectiveEndDate,
    numberOfMealsPerDay,
    numberOfHouseholdMembers,
    amountPaid,
  } = editData?.roomerBoarder ?? {};
  const [showLoader, hideLoader] = useLoader();
  const validationSchema = yup.object({
    beginDate: yup.string().required("Please Select Effective Begin Date"),
    mealsDay: yup
      .number()
      .typeError("Please enter the number of meals per day")
      .lessThan(4, "Meals per day can not be more than 3")
      .required("Please enter the number of meals per day"),
    householdMem: yup
      .number()
      .typeError("Please enter number of household members")
      .moreThan(
        0,
        "Number of Household Members should be more than or equal to 1"
      )
      .required("Please enter number of household members"),
  });
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      beginDate: effectiveBeginDate ?? "",
      endDate: effectiveEndDate ?? "",
      mealsDay: numberOfMealsPerDay ?? "",
      householdMem: numberOfHouseholdMembers ?? "",
      amountPaid: amountPaid ?? "",
      dirtyStatus: createNew ? "INSERT" : "UPDATE",
      individualId: dummyIndividualId,
      id: editData?.id ?? null,
    },
  });

  const onSave = async (values) => {
    showLoader();
    saveJson(RoomerBoarderPayload(values, pageData))
      .then(() => {
        showSuccessToast("Successfully added the Roomer Boarder Details");
        retrieveJson();
        toggleClose();
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <>
      <Box sx={{ border: "1px solid #e4e4e4", padding: "1rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              name="beginDate"
              label="Effective Begin Date"
              required
              helperText={errors.beginDate?.message}
              error={errors.beginDate?.message}
            >
              <CDatePicker control={control} name="beginDate" disableFuture />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="endDate" label="Effective End Date">
              <CDatePicker control={control} name="endDate" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="mealsDay"
              label="Number of Meals Per Day"
              required
              helperText={errors.mealsDay?.message}
              error={errors.mealsDay?.message}
            >
              <CoTextField
                name="mealsDay"
                control={control}
                normalizers={[maxLength(1), number]}
              />
            </Field>
            {/* <p style={{ color: "red", fontSize: "12px" }}>
              Number of Meals Per Day should be more than or equal to 1
            </p> */}
          </Grid>
          <Grid item xs={6}>
            <Field
              name="householdMem"
              label="Number of Household Members"
              required
              helperText={errors.householdMem?.message}
              error={errors.householdMem?.message}
            >
              <CoTextField
                name="householdMem"
                control={control}
                normalizers={[maxLength(1), number]}
              />
              {/* <p style={{ color: "red", fontSize: "12px" }}>
                Number of Household Members should be more than or equal to 1
              </p> */}
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="amountPaid" label="Amount Paid">
              <CoTextField
                name="amountPaid"
                control={control}
                normalizers={[formatCurrency]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Field>
          </Grid>
        </Grid>
      </Box>

      <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
          {/* {labels?.discard ?? "  Discard"} */}
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={handleSubmit(onSave)}
        >
          Save
          {/* {labels?.saveClose ?? " Save"} */}
        </Button>
      </Stack>
    </>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "../../../../components/Dropdown";
import usePageApi from "../../../../hooks/usePageApi";
import {
  Button,
  Grid,
  Stack,
  Typography,
  Box,
  Dialog,
  RadioGroup,
} from "@mui/material";
import CInput from "../../../../components/inputBox";
import CCheckBox from "../../../../components/checkBox";
import Field from "../../../../components/Field";
import {
  alphaNumeric,
  maxLength,
  number,
  onlyAlphabet,
  onlyAlphabetWithSpace,
} from "../../../../utils/normalizers";
import CoTextField from "../../../../controllers/CoTextField";
import useLookup from "../../../../hooks/useLookup";
import { postSmarty } from "../../../../services/apiRequests";
import useLoader from "../../../../hooks/useLoader";
import CRadioButton from "../../../../components/RadioButton";
import { addressPayload } from "../../../../services/apiPayload";
import { selecttActiveContId } from "../../../../store/selectors/appSelector";
import { useSelector } from "react-redux";

function AddressInfo({
  watch,
  register,
  control,
  errors,
  getValues,
  setOpen,
  contactInfo,
  reset,
  setValue,
}) {
  const { getOptions } = useLookup("addressInfo");
  const stateOpt = getOptions("State");
  const isHomeless = watch("homeLess");
  const isInstitution = watch("institution");
  const [smarty, setSmarty] = useState(false);
  const [smartyAddress, setSmartyAddress] = useState("");
  const [showLoader, hideLoader] = useLoader();
  const controlId = useSelector(selecttActiveContId);
  const { pageData, labels } = usePageApi({
    screenId: 416,
    screenDefn: "/dashboard/consumer-app/intake-app/members/AddressInfo",
    callRetriveJson: false,
  });

  const {
    addressLine1,
    addressLine2,
    city,
    state,
    zipcode,
    zip4code,
    sameMailingAddressYN,
  } =
    contactInfo?.[0]?.contactInformationDetails?.contactInformation
      ?.homeAddress ?? {};
  const sameAddress =
    contactInfo?.[0]?.contactInformationDetails?.contactInformation
      ?.sameMailingAddressYN === "Y"
      ? true
      : false;
  const newAddress =
    pageData?.[0]?.contactInformationDetails?.contactInformation?.homeAddress;
  // useEffect(() => {
  //   setValue("addressLine1", addressLine1);
  //   setValue("addressLine2", addressLine2);
  //   setValue("city", city);
  //   setValue("state", state);
  //   setValue("zipcode", zipcode);
  //   setValue("asSame", sameAddress);
  // }, [zipcode, addressLine1]);

  const onSave = async (data) => {
    const payload = {
      addressId: 0,
      homeAddressYN: getValues("homeLess") ? "Y" : "N",
      institutionYN: getValues("institution") ? "Y" : "N",
      addressLine1: getValues("addressLine1") ?? "",
      addressLine2: getValues("addressLine2") ?? "",
      city: getValues("city") ?? "",
      zipcode: getValues("zipcode") ?? "",
      zip4code: getValues("zip4code") ?? "",
      state: getValues("state") ?? "",
      county: "",
      district: "",
      addressVerifiedYN: "",
    };

    // if (isDirty) {
    try {
      showLoader();
      const res = await postSmarty(payload);

      if (res.status === 200) {
        setSmartyAddress(res.data.addressSearches[0].results);
        setSmarty(true);
      }
    } catch (error) {
      alert(error);
    } finally {
      hideLoader();
    }
    // } else {
    // navigations.toContactInfo();
    // }
  };
  const toggleClose = () => {
    setSmarty(false);
  };

  const saveJsonApi = () => {
    const payload = {
      addressId: 0,
      homeAddressYN: getValues("homeLess") ? "Y" : "N",
      institutionYN: getValues("institution") ? "Y" : "N",
      addressLine1: getValues("addressLine1") ?? "",
      addressLine2: getValues("addressLine2") ?? "",
      city: getValues("city") ?? "",
      zipcode: getValues("zipcode") ?? "",
      zip4code: getValues("zip4code") ?? "",
      state: getValues("state") ?? "",
      county: "",
      district: "",
      addressVerifiedYN: "N",
      asSame: getValues("asSame") ? "Y" : "N",
      controlId: controlId,
    };

    if (parseInt(watch("payloadAddress")) === 0) {
      // saveJson(addressPayload(payload));
      toggleClose();
      // navigations.toContactInfo();
    } else {
      if (smartyAddress) {
        const {
          addressLine1,
          addressVerifiedYN,
          city,
          state,
          zipcode,
          zip4code,
        } = smartyAddress[0] ?? {};
        const payload2 = {
          addressId: 0,
          homeAddressYN: getValues("homeLess") ? "Y" : "N",
          institutionYN: getValues("institution") ? "Y" : "N",
          addressLine1: addressLine1,
          addressLine2: "",
          city: city,
          zipcode: zipcode,
          zip4code: zip4code,
          state: state,
          county: "",
          district: "",
          addressVerifiedYN: addressVerifiedYN,
          asSame: getValues("asSame") ? "Y" : "N",
          controlId: controlId,
        };

        // saveJson(addressPayload(payload2));
        // navigations.toContactInfo();
      }
    }
  };
  const lengthCheck = watch("zipcode")?.length === 5;
  const checkSame = watch("asSame") === true;

  useEffect(() => {
    if (watch("asSame")) {
      setValue("mailline1", getValues("line1"));
      setValue("mailline2", getValues("line2"));
      setValue("mailcity", getValues("city"));
      setValue("mailstate", getValues("state"));
      setValue("mailzipCode", getValues("zipcode"));
      setValue("mailzipCodePluse", getValues("zip4code"));
    } else {
      setValue("mailline1", "");
      setValue("mailline2", "");
      setValue("mailcity", "");
      setValue("mailstate", "");
      setValue("mailzipCode", "");
      setValue("mailzipCodePluse", null);
    }
  }, [checkSame]);

  useEffect(() => {
    if (smartyAddress?.length === 0) {
      setValue("payloadAddress", 0);
    } else {
      setValue("payloadAddress", 1);
    }
  }, [smartyAddress]);

  useMemo(() => {
    if (pageData) {
      const { email, phoneNumberDetails, mailingAddress, homeAddress } =
        pageData[0]?.contactInformationDetails?.contactInformation ?? {};
      setValue("mailline1", mailingAddress?.addressLine1);
      setValue("mailline2", mailingAddress?.addressLine2);
      setValue("mailcity", mailingAddress?.city);
      setValue("mailstate", mailingAddress?.state);
      setValue("mailzipCode", mailingAddress?.zipcode);
      setValue("mailzipCodePluse", mailingAddress?.zip4code);
      setValue("asSame", mailingAddress?.homeAddressYN === "Y" ? true : false);
    }
  }, [pageData]);

  return (
    <div>
      <Dialog open={smarty} onClose={toggleClose} title="Verify the address">
        <Box sx={{ width: "31.2rem", margin: 2, padding: 2 }}>
          <Typography variant="h4" marginBottom={2}>
            Home Address
          </Typography>

          <RadioGroup row>
            <Grid container direction="column">
              <Typography variant="h5" color={"gray"}>
                Original Address:
              </Typography>
              <CRadioButton
                {...register("payloadAddress")}
                control={control}
                radioArray={[
                  {
                    label: `                               
                    ${getValues("addressLine1") ?? ""}
                    ${getValues("addressLine2") ?? ""}
                    ${getValues("city") ?? ""}
                    ${getValues("zipcode") ?? ""}
                    ${getValues("zip4code") ?? ""}
                    ${getValues("state") ?? ""}
                 `,
                    value: 0,
                  },
                ]}
              />
            </Grid>

            <Grid>
              <Typography variant="h5" color={"gray"}>
                Suggested Address:
              </Typography>
              <CRadioButton
                row
                {...register("payloadAddress")}
                control={control}
                radioArray={[
                  {
                    label: ` ${smartyAddress?.[0]?.addressLine1 ?? "-"} 
                  ${smartyAddress?.[0]?.addressVerifiedYN ?? ""}
                  ${smartyAddress?.[0]?.city ?? ""}
                  ${smartyAddress?.[0]?.state ?? ""}
                  ${smartyAddress?.[0]?.zipcode ?? ""}
                  ${smartyAddress?.[0]?.zip4code ?? ""} `,
                    value: 1,
                    disabled: smartyAddress?.length === 0,
                  },
                ]}
              />
            </Grid>
          </RadioGroup>

          <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
            <Button variant="outlined" onClick={toggleClose}>
              Close
            </Button>
            <Button
              // disabled={!watch("payloadAddress") ? true : false}
              variant="contained"
              onClick={saveJsonApi}
            >
              Save & Next
            </Button>
          </Stack>
        </Box>
      </Dialog>

      <Typography variant="subtitle1">
        {labels?.residentialAddress ?? "Residential Address"}
      </Typography>
      <Grid container direction="row" spacing={2}>
        {!isInstitution && (
          <Grid item xs={6}>
            <CCheckBox
              {...register("homeLess")}
              control={control}
              label={labels?.homeless ?? "Homeless"}
            />
          </Grid>
        )}
        {!isHomeless && (
          <Grid item xs={6}>
            <CCheckBox
              {...register("institution")}
              control={control}
              label={labels?.institution ?? "Institution"}
            />
          </Grid>
        )}
      </Grid>

      {isInstitution && (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={5}>
            <Field label="Institution ID" required>
              <CoTextField
                name="institutionId"
                control={control}
                disabled={true}
                value={getValues("institutionId")}
              />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field label="Institution Name" required>
              <CoTextField
                disabled={true}
                name="institutionName"
                control={control}
                value={getValues("institutionName")}
              />
            </Field>
          </Grid>
          <Stack justifyContent="flex-end" ml={2} mb={1.3}>
            <Button variant="contained" onClick={() => setOpen(true)}>
              Search
            </Button>
          </Stack>
        </Grid>
      )}

      <Grid container spacing={2} direction={"row"} mt={1}>
        {!isHomeless && (
          <>
            <Grid item xs={6}>
              <Field
                name="addressLine1"
                error={errors.line1?.message}
                showError={errors.line1?.message}
                label="Address Line 1"
                required
              >
                <CoTextField
                  name="line1"
                  normalizers={[alphaNumeric, maxLength(50)]}
                  control={control}
                  placeholder={labels?.flatDetails}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="addressLine2" label="Address Line 2">
                <CoTextField
                  // {...register("addressLine2")}
                  name="line2"
                  normalizers={[alphaNumeric, maxLength(25)]}
                  control={control}
                  placeholder={labels?.areaDetails}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="city"
                label="City"
                error={errors.city?.message}
                showError={errors.city?.message}
                required
              >
                <CoTextField
                  // {...register("city", {
                  //   required: "City is required",
                  //   pattern: {
                  //     value: "/^[a-zA-Z]+$/",
                  //     message: "Enter valid details",
                  //   },
                  // })}
                  name="city"
                  normalizers={[onlyAlphabetWithSpace, maxLength(20)]}
                  control={control}
                  placeholder={labels?.enterCity}
                />
              </Field>
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <Field
            name="state"
            label="State"
            error={errors.state?.message}
            showError={errors.state?.message}
            required
          >
            <Dropdown
              // {...register("state")}
              name="state"
              control={control}
              options={stateOpt}
              placeholder={labels?.selectState}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="zipcode"
            label="Zip Code"
            error={errors.zipcode?.message}
            showError={errors.zipcode?.message}
            required
          >
            <CoTextField
              name="zipcode"
              normalizers={[number, maxLength(5)]}
              type="number"
              onBlur={() => lengthCheck && onSave()}
              control={control}
              placeholder={labels?.enterZipCode}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="zip4code" label="Zip +4 Code">
            <CoTextField
              // {...register("zip4code")}
              name="zip4code"
              normalizers={[number, maxLength(5)]}
              type="number"
              control={control}
              placeholder={labels?.enterZip4Code}
            />
          </Field>
        </Grid>
      </Grid>
      <Typography variant="subtitle1" mt={2}>
        {labels?.mailingAddress ?? "Mailing Address"}
      </Typography>

      <CCheckBox
        name="asSame"
        // checked={watch("asSame")}
        control={control}
        label={labels?.sameAddress}
      />

      <Grid container spacing={2} direction={"row"} mt={1}>
        {!isHomeless && (
          <>
            <Grid item xs={6}>
              <Field name="addressLine1" label="Address Line 1">
                <CoTextField
                  disabled={watch("asSame")}
                  // {...register("mailline1")}
                  name="mailline1"
                  normalizers={[alphaNumeric, maxLength(50)]}
                  control={control}
                  placeholder={labels?.flatDetails}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="addressLine2" label="Address Line 2">
                <CoTextField
                  disabled={watch("asSame")}
                  // {...register("mailline2")}
                  name="mailline2"
                  normalizers={[alphaNumeric, maxLength(25)]}
                  control={control}
                  placeholder={labels?.areaDetails}
                />
              </Field>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Field name="city" label="City">
            <CoTextField
              disabled={watch("asSame")}
              // {...register("mailcity")}
              name="mailcity"
              normalizers={[onlyAlphabetWithSpace, maxLength(20)]}
              control={control}
              placeholder={labels?.enterCity}
            />
          </Field>
        </Grid>

        <Grid item xs={6}>
          <Field
            name="state"
            error={errors.mailstate?.message}
            showError={errors.mailstate?.message}
            label="State"
          >
            <Dropdown
              disabled={watch("asSame")}
              control={control}
              name="mailstate"
              options={stateOpt}
              placeholder={labels?.selectState}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="zipCode" label="Zip Code">
            <CoTextField
              disabled={watch("asSame")}
              type="number"
              // {...register("mailzipCode")}
              name="mailzipCode"
              normalizers={[number, maxLength(5)]}
              control={control}
              placeholder={labels?.enterZipCode}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="zip4code" label="Zip +4 Code">
            <CoTextField
              disabled={watch("asSame")}
              type="number"
              // {...register("mailzipCodePluse")}
              name="mailzipCodePluse"
              normalizers={[number, maxLength(5)]}
              control={control}
              placeholder={labels?.enterZip4Code}
            />
          </Field>
        </Grid>
      </Grid>

      {/* <Typography variant="subtitle1">
        {labels?.residentialAddress ?? "Residential Address"}
      </Typography>
      <Grid container direction="row" spacing={2}>
        {!isInstitution && (
          <Grid item xs={6}>
            <CCheckBox
              {...register("homeLess")}
              control={control}
              label={labels?.homeless ?? "Homeless"}
            />
          </Grid>
        )}
        {!isHomeless && (
          <Grid item xs={6}>
            <CCheckBox
              {...register("institution")}
              control={control}
              label={labels?.institution ?? "Institution"}
            />
          </Grid>
        )}
      </Grid>

      {isInstitution && (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={5}>
            <Field label="Institution ID" required>
              <CInput
                // {...register("institutionId")}
                name="institutionId"
                control={control}
                disabled={true}
                value={getValues("institutionId")}
              />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field label="Institution Name" required>
              <CInput
                disabled={true}
                // {...register("institutionName")}
                name="institutionName"
                control={control}
                value={getValues("institutionName")}
              />
            </Field>
          </Grid>
          <Stack justifyContent="flex-end" ml={2} mb={1.3}>
            <Button variant="contained" onClick={() => setOpen(true)}>
              Search
            </Button>
          </Stack>
        </Grid>
      )}

      <Grid container spacing={2} direction={"row"} mt={1}>
        {!isHomeless && (
          <>
            <Grid item xs={6}>
              <Field
                name="addressLine1"
                error={errors.line1?.message}
                showError={errors.line1?.message}
                label="Address Line 1"
                required
              >
                <CoTextField
                  name="line1"
                  normalizers={[alphaNumeric, maxLength(50)]}
                  control={control}
                  placeholder={labels?.flatDetails}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="addressLine2" label="Address Line 2">
                <CoTextField
                  name="line2"
                  // {...register("line2")}
                  normalizers={[alphaNumeric, maxLength(25)]}
                  control={control}
                  placeholder={labels?.areaDetails}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="city"
                label="City"
                error={errors.city?.message}
                showError={errors.city?.message}
                required
              >
                <CoTextField
                  // {...register("city", {
                  //   required: "City is required",
                  //   pattern: {
                  //     value: "/^[a-zA-Z]+$/",
                  //     message: "Enter valid details",
                  //   },
                  // })}
                  name="city"
                  normalizers={[onlyAlphabet, maxLength(20)]}
                  control={control}
                  placeholder={labels?.enterCity}
                />
              </Field>
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <Field
            name="state"
            label="State"
            error={errors.state?.message}
            showError={errors.state?.message}
            required
          >
            <Dropdown
              {...register("state")}
              control={control}
              options={stateOpt}
              placeholder={labels?.selectState}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="zipCode"
            label="ZipCode"
            error={errors.zipCode?.message}
            showError={errors.zipCode?.message}
            required
          >
            <CoTextField
              // {...register("zipCode", {
              //   required: "Zipcode is required",
              //   maxLength: 50,
              //   pattern: {
              //     value: "^-?d+(.d+)?$",
              //     message: "Enter valid details",
              //   },
              // })}
              name="zipCode"
              type="number"
              normalizers={[number, maxLength(5)]}
              control={control}
              placeholder={labels?.enterZipCode}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="zip4code" label="Zip4Code">
            <CoTextField
              // {...register("zipCodePluse")}
              name="zipCodePluse"
              normalizers={[number, maxLength(5)]}
              type="number"
              control={control}
              placeholder={labels?.enterZip4Code}
            />
          </Field>
        </Grid>
      </Grid>
      <Typography variant="subtitle1" mt={2}>
        {labels?.mailingAddress ?? "Mailing Address"}
      </Typography>
      <CCheckBox
        {...register("asSame")}
        control={control}
        label={labels?.sameAddress}
      />

      <Grid container spacing={2} direction={"row"} mt={1}>
        <Grid item xs={6}>
          <Field
            name="addressLine1"
            errors={errors.mailline1?.message}
            showError={errors.mailline1?.message}
            label="Address Line 1"
          >
            <CoTextField
              disabled={watch("asSame")}
              // {...register("mailline1")}
              name="mailline1"
              normalizers={[alphaNumeric, maxLength(50)]}
              control={control}
              placeholder={labels?.flatDetails}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="addressLine2" label="Address Line 1">
            <CoTextField
              disabled={watch("asSame")}
              // {...register("mailline2")}
              name="mailline2"
              normalizers={[alphaNumeric, maxLength(25)]}
              control={control}
              placeholder={labels?.areaDetails}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="city"
            error={errors.mailcity?.message}
            showError={errors.mailcity?.message}
            label="City"
          >
            <CoTextField
              disabled={watch("asSame")}
              // {...register("mailcity")}
              name="mailcity"
              normalizers={[onlyAlphabet, maxLength(20)]}
              control={control}
              placeholder={labels?.enterCity}
            />
          </Field>
        </Grid>

        <Grid item xs={6}>
          <Field
            name="state"
            error={errors.mailstate?.message}
            showError={errors.mailstate?.message}
            label="State"
          >
            <Dropdown
              disabled={watch("asSame")}
              {...register("mailstate")}
              control={control}
              options={stateOpt}
              placeholder={labels?.selectState}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="zipCode"
            error={errors.mailzipCode?.message}
            showError={errors.mailzipCode?.message}
            label="ZipCode"
          >
            <CoTextField
              disabled={watch("asSame")}
              type="number"
              // {...register("mailzipCode")}
              name="mailzipCode"
              normalizers={[number, maxLength(5)]}
              control={control}
              placeholder={labels?.enterZipCode}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="zip4code" label="Zip4Code">
            <CoTextField
              disabled={watch("asSame")}
              name="mailzipCodePluse"
              type="number"
              // {...register("mailzipCodePluse")}
              normalizers={[number, maxLength(5)]}
              control={control}
              placeholder={labels?.enterZip4Code}
            />
          </Field>
        </Grid>
      </Grid> */}
    </div>
  );
}

AddressInfo.propTypes = {};

export default AddressInfo;

export const documentUpdatePayload = (payload, documentId) => {
  const formData = new FormData();
  formData.append("files", payload.fileFormData);
  formData.append("individualId", payload.individualId);
  formData.append("controlId", payload.controlId);
  formData.append("docType", payload.documentTypeCd);
  formData.append("docCat", payload.lookupShortVal);
  formData.append("fileName", payload.verificationName);
  formData.append("createDt", payload.createDt);
  formData.append("createUserId", payload.createUserId);
  formData.append("sourceCaseId", payload.sourceCaseId);
  formData.append("sourceSystem", payload.sourceSystem);
  formData.append("documentId", documentId);

  return formData;
};

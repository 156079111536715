import { map } from "lodash";

export const RoomerBoarderPayload = (data, previousData) => {
  const {
    beginDate,
    endDate,
    mealsDay,
    householdMem,
    amountPaid,
    dirtyStatus,
    individualId,
    id,
    modifiedData,
  } = data;

  const payload = {
    status: true,
    dirtyStatus: dirtyStatus,
    individualId: individualId,
    roomerBoarder: {
      brderInd: "",
      amountPaid: amountPaid,
      dirtyStatus: dirtyStatus,
      effectiveEndDate: endDate ? endDate : null,
      effectiveBeginDate: beginDate ? new Date(beginDate).toISOString() : null,
      individualBoarderId: 0,
      numberOfMealsPerDay: mealsDay,
      numberOfHouseholdMembers: householdMem,
    },
    importSelected: true,
  };
  if (id) {
    const updatePayload = map(previousData, (val) => {
      if (id === val.id) {
        return payload;
      } else {
        return val;
      }
    });
    return updatePayload;
  } else {
    if (!previousData) {
      return [payload];
    } else {
      return [...previousData, payload];
    }
  }
};

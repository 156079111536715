import axios from "axios";
import { documentUpdatePayload, GET_DOCUMENTS } from "./ApiPayload";
import HttpClient from "../../HttpClient";
import { documentUploadPayload } from "../../../services/apiPayload";

const URL = process.env.REACT_APP_DMS_URL;

export const docFileUpload = (initialState = {}) => {
  return axios.post(
    `${URL}/docControl/documentDetails/upload`,
    documentUploadPayload(initialState),
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const getDocumentByCaseID = (caseId) => {
  return axios.get(`${URL}/docControl/getDocumentsByCaseId/${caseId}/KCARES`);
};

export const DownloadDocument = async (documentId, sourceCaseId) => {
  return await axios.post(
    `${URL}/docControl/downloadDocumentBy/${documentId}/${sourceCaseId}/KCARES`,
    {},
    { responseType: "blob" }
  );
};

export const documentEdit = (initialState = {}, documentId) => {
  return axios.post(
    `${URL}/docControl/documentDetails/edit`,
    documentUpdatePayload(initialState, documentId),
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const documentDelete = (documentId) => {
  return axios.delete(`${URL}/docControl/documentDetails/delete/${documentId}`);
};

export const getDocumentByDocumentID = (documentId) => {
  return axios.get(`${URL}/docControl/getDocuments/${documentId}`);
};

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import Field from "../../components/Field";
import { Controller, useForm } from "react-hook-form";
import YesNoOption from "../../components/YesNoOption";
import CoTextField from "../../controllers/CoTextField";
import Dropdown from "../../components/Dropdown";
import InfoIcon from "@mui/icons-material/Info";
import { formatCurrency, maxLength, number } from "../../utils/normalizers";
import useLoader from "../../hooks/useLoader";
import { saveEligible } from "../../utils/services/Eligible/apiRequests";
import { checkEligible } from "../../utils/services/Eligible/apiPayload";
import { showErrorToast } from "../../utils/services/toast";
import useLookup from "../../hooks/useLookup";
import EligibiltyInfo from "../AmIEligible/EligibilityInfo";
import { myElibility } from "../../services/AmIEligibility/apiRequest";
import { useSelector } from "react-redux";
import { getAuthToken } from "../../store/selectors/authSelector";
import EligibilitySummary from "./EligibilitySummary";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function ImEligible({ toggleClose }) {
  const { getOptions } = useLookup("Eligible");
  const [eligibleData, setEligibleData] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [isEligible, setIsEligible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const token = useSelector(getAuthToken);
  const tokenId = token?.sub;

  const { periodOpts } = useMemo(() => {
    return {
      periodOpts: getOptions("TimePeriod"),
    };
  }, [getOptions]);

  const validationSchema = yup.object({
    peoples: yup
      .string()
      .required("Please enter how many people live in your household"),
    oldAge: yup
      .string()
      .required("Please answer if anyone's age is 60 or older"),
    physical: yup
      .string()
      .required(
        "Please answer if anyone in the household has a physical or mental disability"
      ),
    income: yup
      .string()
      .required("Please enter the total gross income for your household"),
    gross: yup
      .string()
      .required("Please enter the total gross income for your household"),
  });

  const {
    control,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      peoples: "",
      oldAge: "",
      physical: "",
      gross: "",
      income: "",
    },
  });

  console.log(watch("oldAge"));
  const onSave = async () => {
    const payload = {
      peoples: getValues("peoples"),
      oldAge: getValues("oldAge"),
      physical: getValues("physical"),
      gross: getValues("gross"),
      income: getValues("income"),
    };
    try {
      showLoader();
      const res = await saveEligible(checkEligible(payload));
      if (res.status === 200) {
        setEligibleData(res.data);
        setFormSubmitted(true);
        setIsEligible(true);
        // toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  if (formSubmitted && isEligible) {
    return <EligibilitySummary data={eligibleData} toggleClose={toggleClose} />;
  }

  return (
    <>
      <Box sx={{ border: "1px solid #C2C2B7", padding: "1rem" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Field
              name="peopleLive"
              label="How many people live in your household, including you?"
              required
              title="If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them."
              error={errors?.peoples?.message}
              showError={errors?.peoples?.message}
            >
              <CoTextField
                name="peoples"
                control={control}
                normalizers={[number, maxLength(2)]}
                sx={{ width: "280px" }}
                placeholder="Please enter Number of members"
              />
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="oldAge"
              label="Is anyone age 60 or older?"
              required
              error={errors?.oldAge?.message}
              showError={errors?.oldAge?.message}
            >
              <Controller
                name="oldAge"
                control={control}
                render={({ field }) => {
                  return <YesNoOption {...field} name="oldAge" />;
                }}
              />
            </Field>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Field
              name="physical"
              label="Does anyone in the household have a physical or mental disability?"
              required
              helperText={errors.physical?.message}
              error={errors.physical?.message}
              title="A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability."
            >
              <Controller
                name="physical"
                control={control}
                render={({ field }) => {
                  return <YesNoOption {...field} name="physical" />;
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "start" }}>
            <Field
              name="grossIncome"
              label="What is the total gross income for your household?"
              required
              helperText={errors.gross?.message}
              error={errors.gross?.message}
              title="Gross income is the total income before anything is taken out, such as taxes or health insurance."
            >
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <CoTextField
                  name="gross"
                  control={control}
                  normalizers={[formatCurrency]}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <Typography variant="h6" sx={{ paddingTop: "1rem" }}>
                  per
                </Typography>
                <Dropdown
                  name="income"
                  control={control}
                  options={periodOpts}
                  error={errors.income?.message} // Pass the error here
                  showError={errors.income?.message}
                />
              </Stack>
            </Field>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="end"
          spacing={2}
          paddingTop="1rem"
        >
          <Button variant="outlined" onClick={toggleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            sx={{ fontSize: "1rem" }}
            onClick={handleSubmit(onSave)}
          >
            Get my results
          </Button>
        </Stack>
      </Box>
    </>
  );
}

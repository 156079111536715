import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import PageContainer from "../../../components/PageContainer";
import SelectableView from "../../../components/SelectableView";
import MemberMenuButton from "../../../components/SelectableView/MemberMenuButton";
import usePageApi from "../../../hooks/usePageApi";
import Field from "../../../components/Field";
import { Controller, useForm } from "react-hook-form";
import YesNoOption from "../../../components/YesNoOption";
import AddAuthRep from "../AddAuthRep";
import { isEmpty, map } from "lodash";
import Details from "./details";
import ScreenProvider from "../../../containers/ScreenProvider";
import usePageNav from "../../../hooks/usePageNav";
import useDynamicNav from "../../../hooks/useDynamicNav";
import AddIcon from "@mui/icons-material/Add";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { activeMembers } from "../../../store/selectors/appSelector";
import { setActiveMembers } from "../../../store/slices/appSlice";

function AuthorizedRep({ screenId }) {
  const activeLists = useSelector(activeMembers) ?? [];
  const dispatch = useDispatch();
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [apiData, setApiData] = useState([]);

  const { pageData, retrieveJson, labels, isFetchingData, isFetchingLabels } =
    usePageApi({
      screenId: 195,
      screenDefn: "/dashboard/consumer-app/intake-app/hoh/authRep",
      callRetrieveStatus: true,
    });

  const { pageData: memberData } = usePageApi({
    screenId: 158,
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
  });

  const userShema = yup.object({
    isAuthRep: yup.string().required("Please choose any option"),
  });

  const {
    watch,
    control,
    getValues,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(userShema),
    defaultValues: {
      isAuthRep: "",
      category: "",
    },
  });

  useMemo(() => {
    if (pageData) {
      const activeIds = pageData?.map((obj) => obj.id);
      dispatch(setActiveMembers([...activeLists, ...activeIds]));
      setApiData(pageData);
    }
  }, [pageData]);

  const modificationData = useMemo(
    () =>
      map(pageData, (item, index) => ({
        ...item,
        id: index + 1,
      })),
    [pageData]
  );

  useEffect(() => {
    if (modificationData.length) {
      setValue("isAuthRep", "Y");
    }
  }, [modificationData]);

  const modifiedData = map(apiData, (item, index) => ({
    ...item,
    id: index + 1,
  }));

  const columns = useMemo(() => {
    return [
      {
        headerName: "Name",
        value: "ertyu",
      },
      {
        headerName: "Address",
      },
      {
        headerName: "City",
      },
      {
        headerName: "State",
      },
      {
        headerName: "Zip Code",
      },
    ];
  }, [pageData]);

  const onclose = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
    setValue("isAuthRep", "N");
  };

  const AuthRep = watch("isAuthRep") === "Y";
  const check = "";
  const mudo = () => {
    setOpenAddModal(true);
  };

  return (
    <ScreenProvider labels={labels}>
      {openAddModal && (
        <AddAuthRep
          open={openAddModal}
          onClose={onclose}
          modifiedData={modifiedData}
          retrieveJson={retrieveJson}
          memberData={memberData}
        />
      )}
      <PageContainer
        onBack={() => navigations.toCitizenship()}
        onNext={handleSubmit(handleNext)}
        title="Authorised Representative"
      >
        {isEmpty(modificationData) && (
          <Field
            name="isAuthRep"
            error={errors.isAuthRep?.message}
            showError={errors.isAuthRep?.message}
            label="Do you have Allocate Authorized Representative?"
          >
            <Controller
              name="isAuthRep"
              control={control}
              render={({ field }) => {
                const { onChange, ...inputProps } = field;
                const _onChange = (e) => {
                  if (e.target.value === "Y" && modificationData.length === 0) {
                    setOpenAddModal(true);
                  }
                  onChange(e);
                };
                return <YesNoOption onChange={_onChange} {...inputProps} />;
              }}
            />
          </Field>
        )}
        {(AuthRep || modificationData.length > 0) && (
          <>
            <Stack alignItems="flex-end">
              <Button
                onClick={() => setOpenAddModal(true)}
                startIcon={<AddIcon />}
              >
                Add Auth Rep
              </Button>
            </Stack>
            <SelectableView
              loading={isFetchingData}
              loadingContent={isFetchingLabels}
              getId={(params) => params.id}
              data={modificationData}
              getName={(params) => {
                const { personName, providerName } =
                  params?.authorizedRepresentative ?? {};

                return `${personName?.firstName ?? providerName} ${
                  personName?.lastName
                }`;
              }}
              renderMenuButton={(params, options) => {
                return <MemberMenuButton member={params} options={options} />;
              }}
              showAdd={true}
              showEdit={true}
              showDelete={true}
              onEdit={() => setOpenEditModal(true)}
              onAdd={() => setOpenAddModal(true)}
            >
              {(selected) => {
                return (
                  <>
                    <Details selected={selected} />

                    <AddAuthRep
                      open={openEditModal}
                      onClose={onclose}
                      selected={selected}
                      modifiedData={modifiedData}
                      retrieveJson={retrieveJson}
                      memberData={memberData}
                    />
                  </>
                );
              }}
            </SelectableView>
          </>
        )}
      </PageContainer>
    </ScreenProvider>
  );
}

export default AuthorizedRep;

import React, { useEffect, useMemo, useState } from "react";
import usePageApi from "../../../hooks/usePageApi";
import { useForm } from "react-hook-form";
import PageContainer from "../../../components/PageContainer";
import Field from "../../../components/Field";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import ScreenProvider from "../../../containers/ScreenProvider";
import Dropdown from "../../../components/Dropdown";
import CCheckBox from "../../../components/checkBox";
import useLookUp from "../../../hooks/useLookup";
import CRadioButton from "../../../components/RadioButton";
import { postInstitution, postSmarty } from "../../../services/apiRequests";
import useLoader from "../../../hooks/useLoader";
import { DataGrid } from "@mui/x-data-grid";
import { addressPayload } from "../../../services/apiPayload";
import usePageNav from "../../../hooks/usePageNav";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CoTextField from "../../../controllers/CoTextField";
import {
  alphaNumeric,
  maxLength,
  number,
  onlyAlphabet,
  onlyAlphabetWithSpace,
} from "../../../utils/normalizers";
import * as yup from "yup";
import {
  onlyAlphabtes,
  mandatory,
  validCharacters,
  zipCodes,
} from "../../../ValidationRules";
import { showErrorToast } from "../../../utils/services/toast";
import { useSelector } from "react-redux";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import useDynamicNav from "../../../hooks/useDynamicNav";
import { find } from "lodash";
import dayjs from "dayjs";

export default function AddressInfo({ screenId }) {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const controlId = useSelector(selecttActiveContId);
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: 131,
    screenDefn: "/dashboard/consumer-app/interim/currentAddress",
    callRetrieveStatus: true,
  });

  const [open, setOpen] = useState(false);
  const [smarty, setSmarty] = useState(false);
  const [smartyAddress, setSmartyAddress] = useState("");
  const [rowsData, setRowsData] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [showLoader, hideLoader] = useLoader();
  const { getOptions } = useLookUp("addressInfo");
  const { stateOpt } = useMemo(() => {
    return {
      stateOpt: getOptions("State"),
    };
  }, [getOptions]);

  const {
    watch,
    control,
    getValues,
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: "onChange",

    defaultValues: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipcode: "",
      zip4code: "",
      mailline1: "",
      mailline2: "",
      mailcity: "",
      mailstate: "",
      mailzipCode: "",
      mailzipCodePluse: "",
      homeLess: "",
      institution: "",
      asSame: false,
      institutionId: "",
      institutionName: "",
      vendorBy: 1,
      vendorCity: "",
      vendorState: "",
      vendorZipCode: "",
      payloadAddress: "",
      ...pageData,
    },
  });

  const isHomeless = watch("homeLess");
  const isInstitution = watch("institution");
  const Column = [
    { field: "vendorId", headerName: "ID", flex: 1 },
    { field: "vendorName", headerName: "Name", flex: 1 },
    { field: "vendorTypeCd", headerName: "Type", flex: 1 },
    { field: "mmisVendorId", headerName: "MMIS LTC Provider ID", flex: 1 },
    { field: "mmisVendorSpecialityCd", headerName: "Specialities", flex: 1 },
    {
      field: "select",
      headerName: "Select",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button onClick={(e) => selectedInstitution(params?.row)}>
            Select
          </Button>
        );
      },
    },
  ];

  const selectedInstitution = ({ vendorName, vendorId }) => {
    setValue("institutionName", vendorName);
    setValue("institutionId", vendorId);
    toggleClose();
  };

  const onSave = async (data) => {
    setCurrentAddress(data);
    const payload = {
      addressId: 0,
      homeAddressYN: getValues("homeLess") ? "Y" : "N",
      institutionYN: getValues("institution") ? "Y" : "N",
      addressLine1: getValues("addressLine1") ?? "",
      addressLine2: getValues("addressLine2") ?? "",
      city: getValues("city") ?? "",
      zipcode: getValues("zipcode") ?? "",
      zip4code: getValues("zip4code") ?? "",
      state: getValues("state") ?? "",
      county: "",
      district: "",
      addressVerifiedYN: "",
    };
    if (errors && !isHomeless) {
      try {
        showLoader();
        const res = await postSmarty(payload);
        if (res.status === 200) {
          setSmartyAddress(res.data.addressSearches[0].results);
          setSmarty(true);
        }
      } catch (error) {
        alert(error);
      } finally {
        hideLoader();
      }
    } else if (isHomeless) {
      handleNext();
      // navigations.toContactInfo();
    }
  };

  const toggleClose = () => {
    setOpen(false);
    setSmarty(false);
  };

  const institutionSearch = async () => {
    try {
      showLoader();
      const res = await postInstitution(getValues("vendorState"));
      if (res.status === 200) {
        setRowsData(res.data.results);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const saveJsonApi = async () => {
    const payload = {
      addressId: 0,
      homeAddressYN: currentAddress?.homeLess ? "Y" : "N",
      institutionYN: currentAddress?.institution ? "Y" : "N",
      addressLine1: currentAddress?.addressLine1 ?? "",
      addressLine2: currentAddress?.addressLine2 ?? "",
      city: currentAddress?.city ?? "",
      zipcode: currentAddress?.zipcode ?? "",
      zip4code: currentAddress?.zip4code ?? "",
      state: currentAddress?.state ?? "",
      county: "",
      district: "",
      addressVerifiedYN: "N",
      asSame: getValues("asSame") ? "Y" : "N",
      controlId: controlId,
      mailline1: currentAddress?.mailline1 ?? "",
      mailline2: currentAddress?.mailline2 ?? "",
      mailcity: currentAddress?.mailcity ?? "",
      mailzipCode: currentAddress?.mailzipCode ?? "",
      mailzipCodePluse: currentAddress?.mailzipCodePluse ?? "",
      mailstate: currentAddress?.mailstate ?? "",
    };

    if (parseInt(watch("payloadAddress")) === 0) {
      saveJson(addressPayload(payload));
      handleNext();
    } else {
      if (smartyAddress) {
        const {
          addressLine1,
          addressVerifiedYN,
          city,
          state,
          zipcode,
          zip4code,
          county,
        } = smartyAddress[0] ?? {};
        const actualState = find(stateOpt, { label: state })?.value ?? state;
        const payload2 = {
          addressId: 0,
          homeAddressYN: getValues("homeLess") ? "Y" : "N",
          institutionYN: getValues("institution") ? "Y" : "N",
          addressLine1: addressLine1,
          addressLine2: "",
          city: city,
          zipcode: zipcode,
          zip4code: zip4code,
          state: actualState,
          county: county,
          district: "",
          addressVerifiedYN: addressVerifiedYN,
          asSame: getValues("asSame") ? "Y" : "N",
          controlId: controlId,
        };

        try {
          showLoader();

          const res = await saveJson(addressPayload(payload2));
          if (res?.status == 200) {
            handleNext();
          }
        } catch (error) {
          showErrorToast(error);
        } finally {
          hideLoader();
        }
      }
    }
  };

  useMemo(() => {
    if (pageData) {
      const { email, phoneNumberDetails, mailingAddress, homeAddress } =
        pageData[0]?.contactInformationDetails?.contactInformation ?? {};

      reset({
        ...homeAddress,
        mailline1: mailingAddress?.addressLine1,
        mailline2: mailingAddress?.addressLine2,
        mailcity: mailingAddress?.city,
        mailstate: mailingAddress?.state,
        mailzipCode: mailingAddress?.zipcode,
        mailzipCodePluse: mailingAddress?.zip4code,
        asSame: mailingAddress?.homeAddressYN === "Y" ? true : false,
      });
    }
  }, [pageData]);

  useEffect(() => {
    if (watch("asSame")) {
      setValue("mailline1", getValues("addressLine1"));
      setValue("mailline2", getValues("addressLine2"));
      setValue("mailcity", getValues("city"));
      setValue("mailstate", getValues("state"));
      setValue("mailzipCode", getValues("zipcode"));
      setValue("mailzipCodePluse", getValues("zip4code"));
    } else {
      setValue("mailline1", "");
      setValue("mailline2", "");
      setValue("mailcity", "");
      setValue("mailstate", "");
      setValue("mailzipCode", "");
      setValue("mailzipCodePluse", "");
    }
  }, [watch("asSame") === true]);

  useEffect(() => {
    if (smartyAddress?.length === 0) {
      setValue("payloadAddress", 0);
    } else {
      setValue("payloadAddress", 1);
    }
  }, [smartyAddress]);

  const onBack = () => {
    navigations.toStudentInfo();
  };

  return (
    <Grid m={2}>
      <ScreenProvider labels={labels}>
        <Dialog open={open} onClose={toggleClose} maxWidth="xl">
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h3">
                {labels?.institution ?? "Institution Search"}
              </Typography>
              <IconButton onClick={toggleClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <CRadioButton
              row
              name="vendorBy"
              control={control}
              radioArray={[
                {
                  label: "By Facility",
                  value: 0,
                },
                {
                  label: "By Address",
                  value: 1,
                },
              ]}
            />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field name="vendorCity" label="Vendor City">
                  <CoTextField
                    normalizers={[onlyAlphabet, maxLength(25)]}
                    name="vendorCity"
                    control={control}
                    placeholder={labels?.flatDetails}
                  />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="vendorState"
                  label="Vendor State"
                  error={errors.vendorState?.message}
                  showError={errors.vendorState?.message}
                  required
                >
                  <Dropdown
                    name="vendorState"
                    {...register("vendorState", {
                      // maxLength: 50,
                      // required: "Enter valid VendorState",
                    })}
                    control={control}
                    options={stateOpt}
                    placeholder={labels?.selectState}
                  />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field name="vendorZip" label="Vendor ZIP Code">
                  <CoTextField
                    name="vendorZipCode"
                    normalizers={[number, maxLength(5)]}
                    control={control}
                    placeholder={labels?.enterZipCode}
                  />
                </Field>
              </Grid>
              <Stack
                mt={2}
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                alignItems="center"
                ml={2}
                width="100%"
              >
                <Button variant="contained" onClick={toggleClose}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit(institutionSearch)}
                >
                  {labels?.search ?? " Search"}
                </Button>
              </Stack>
              {rowsData && (
                <DataGrid
                  getRowId={(row) => row.vendorId}
                  sx={{
                    marginTop: 2,
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontSize: 16,
                      fontWeight: 700,
                      padding: "0rem 0.6rem",
                      textAlign: "center",
                      color: "black",
                    },
                  }}
                  slots={{
                    noRowsOverlay: "noData",
                  }}
                  autoHeight
                  columns={Column ?? []}
                  rows={rowsData ?? []}
                />
              )}
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={smarty} onClose={toggleClose} title="Verify the address">
          <Box sx={{ width: "31.2rem", margin: 2, padding: 2 }}>
            <Typography variant="h4" marginBottom={2}>
              Home Address
            </Typography>

            <RadioGroup row>
              <Grid container direction="column">
                <Typography variant="h5" color={"gray"}>
                  Original Address:
                </Typography>
                <CRadioButton
                  row
                  // {...register("payloadAddress")}
                  name="payloadAddress"
                  control={control}
                  radioArray={[
                    {
                      label: `                               
                    ${getValues("addressLine1") ?? ""}
                    ${getValues("addressLine2") ?? ""}
                    ${getValues("city") ?? ""}
                    ${getValues("zipcode") ?? ""}
                    ${getValues("zip4code") ?? ""}
                    ${getValues("state") ?? ""}
                 `,
                      value: 0,
                    },
                  ]}
                />
              </Grid>

              <Grid>
                <Typography variant="h5" color={"gray"}>
                  Suggested Address:
                </Typography>
                <CRadioButton
                  row
                  // {...register("payloadAddress")}
                  name="payloadAddress"
                  control={control}
                  radioArray={[
                    {
                      label: ` ${smartyAddress[0]?.addressLine1 ?? "-"} 
                  ${smartyAddress[0]?.addressVerifiedYN ?? ""}
                  ${smartyAddress[0]?.city ?? ""}
                  ${smartyAddress[0]?.state ?? ""}
                  ${smartyAddress[0]?.zipcode ?? ""}
                  ${smartyAddress[0]?.zip4code ?? ""} `,
                      value: 1,
                      disabled: smartyAddress?.length === 0,
                    },
                  ]}
                />
              </Grid>
            </RadioGroup>

            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
              <Button variant="outlined" onClick={toggleClose}>
                Close
              </Button>
              <Button
                // disabled={!watch("payloadAddress") ? true : false}
                variant="contained"
                onClick={saveJsonApi}
              >
                Save & Next
              </Button>
            </Stack>
          </Box>
        </Dialog>
        <PageContainer
          title="Address Information"
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Typography variant="subtitle1">
            {labels?.residentialAddress ?? "Residential Address"}
          </Typography>
          <Grid container direction="row" spacing={2}>
            {!isInstitution && (
              <Grid item xs={6}>
                <CCheckBox
                  {...register("homeLess")}
                  control={control}
                  label={labels?.homeless ?? "Homeless"}
                />
              </Grid>
            )}
            {!isHomeless && (
              <Grid item xs={6}>
                <CCheckBox
                  {...register("institution")}
                  control={control}
                  label={labels?.institution ?? "Institution"}
                />
              </Grid>
            )}
          </Grid>

          {isInstitution && (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={5}>
                <Field label="Institution ID" required>
                  <TextField
                    name="institutionId"
                    control={control}
                    disabled={true}
                    value={getValues("institutionId")}
                    placeholder="Enter Institution ID"
                  />
                </Field>
              </Grid>
              <Grid item xs={5}>
                <Field label="Institution Name" required>
                  <TextField
                    disabled={true}
                    name="institutionName"
                    control={control}
                    value={getValues("institutionName")}
                    placeholder="Enter Institution Name"
                  />
                </Field>
              </Grid>
              <Stack justifyContent="flex-end" ml={2} mb={1.3}>
                <Button variant="contained" onClick={() => setOpen(true)}>
                  Search
                </Button>
              </Stack>
            </Grid>
          )}

          <Grid container spacing={2} direction={"row"} mt={1}>
            {!isHomeless && (
              <>
                <Grid item xs={6}>
                  <Field
                    name="addressLine1"
                    error={errors.addressLine1?.message}
                    showError={errors.addressLine1?.message}
                    label="Address Line 1"
                    required
                  >
                    <CoTextField
                      // {...register("addressLine1", {
                      //   required: "Address is required",
                      //   maxLength: 50,
                      // })}
                      name="addressLine1"
                      normalizers={[alphaNumeric, maxLength(50)]}
                      control={control}
                      placeholder={labels?.flatDetails}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="addressLine2" label="Address Line 2">
                    <CoTextField
                      // {...register("addressLine2")}
                      name="addressLine2"
                      normalizers={[alphaNumeric, maxLength(25)]}
                      control={control}
                      placeholder={labels?.areaDetails}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="city"
                    label="City"
                    error={errors.city?.message}
                    showError={errors.city?.message}
                    required
                  >
                    <CoTextField
                      // {...register("city", {
                      //   required: "City is required",
                      //   pattern: {
                      //     value: "/^[a-zA-Z]+$/",
                      //     message: "Enter valid details",
                      //   },
                      // })}
                      name="city"
                      normalizers={[onlyAlphabetWithSpace, maxLength(20)]}
                      control={control}
                      placeholder={labels?.enterCity}
                    />
                  </Field>
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              <Field
                name="state"
                label="State"
                error={errors.state?.message}
                showError={errors.state?.message}
                required
              >
                <Dropdown
                  // {...register("state")}
                  name="state"
                  control={control}
                  options={stateOpt}
                  placeholder={labels?.selectState}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="zipcode"
                label="Zip Code"
                error={errors.zipcode?.message}
                showError={errors.zipcode?.message}
                required
              >
                <CoTextField
                  // {...register("zipcode", {
                  //   required: "Zipcode is required",
                  //   maxLength: 50,
                  //   pattern: {
                  //     value: "^-?d+(.d+)?$",
                  //     message: "Enter valid details",
                  //   },
                  // })}
                  name="zipcode"
                  normalizers={[number, maxLength(5)]}
                  control={control}
                  placeholder={labels?.enterZipCode}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="zip4code"
                errors={errors.zip4code?.message}
                showError={errors.zip4code?.message}
                label="Zip +4 Code"
              >
                <CoTextField
                  // {...register("zip4code")}
                  name="zip4code"
                  normalizers={[number, maxLength(4)]}
                  control={control}
                  placeholder={labels?.enterZip4Code}
                />
              </Field>
            </Grid>
          </Grid>
          <Typography variant="subtitle1" mt={2}>
            {labels?.mailingAddress ?? "Mailing Address"}
          </Typography>
          <CCheckBox
            {...register("asSame")}
            control={control}
            label={labels?.sameAddress}
          />

          <Grid container spacing={2} direction={"row"} mt={1}>
            {!isHomeless && (
              <>
                <Grid item xs={6}>
                  <Field name="mailline1" label="Address Line 1">
                    <CoTextField
                      disabled={watch("asSame")}
                      // {...register("mailline1")}
                      name="mailline1"
                      normalizers={[alphaNumeric, maxLength(50)]}
                      control={control}
                      placeholder={labels?.flatDetails}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="mailline2" label="Address Line 2">
                    <CoTextField
                      disabled={watch("asSame")}
                      // {...register("mailline2")}
                      name="mailline2"
                      normalizers={[alphaNumeric, maxLength(25)]}
                      control={control}
                      placeholder={labels?.areaDetails}
                    />
                  </Field>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Field name="mailcity" label="City">
                <CoTextField
                  disabled={watch("asSame")}
                  // {...register("mailcity")}
                  name="mailcity"
                  normalizers={[onlyAlphabetWithSpace, maxLength(20)]}
                  control={control}
                  placeholder={labels?.enterCity}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field name="mailstate" label="State">
                <Dropdown
                  disabled={watch("asSame")}
                  control={control}
                  name="mailstate"
                  options={stateOpt}
                  placeholder={labels?.selectState}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="mailzipCode" label="Zip Code">
                <CoTextField
                  disabled={watch("asSame")}
                  // {...register("mailzipCode")}
                  name="mailzipCode"
                  normalizers={[number, maxLength(5)]}
                  control={control}
                  placeholder={labels?.enterZipCode}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="mailzipCodePluse" label="Zip +4 Code">
                <CoTextField
                  disabled={watch("asSame")}
                  // {...register("mailzipCodePluse")}
                  name="mailzipCodePluse"
                  normalizers={[number, maxLength(4)]}
                  control={control}
                  placeholder={labels?.enterZip4Code}
                />
              </Field>
            </Grid>
          </Grid>
        </PageContainer>
      </ScreenProvider>
    </Grid>
  );
}

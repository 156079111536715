import dayjs from "dayjs";

export const hohInfoPayload = (data) => {
  const {
    firstName = "",
    lastName = "",
    middleName = "",
    suffix = "",
    DOB = "",
    gender = "",
    martialStatus = "",
    areYouPregnant = "",
    SSN = "",
    expDueDate = null,
    controlId,
    prgmDetails,
    individualId,
    dirtyStatus,
  } = data;

  return {
    dirtyStatus: dirtyStatus,
    individualId: individualId ?? "",
    clearanceStatus: "N",
    controlId: controlId,
    screenDefn: "/dashboard/consumer-app/intake-app/hoh/hohInfo",
    status: true,
    importSelected: true,
    editable: false,
    alertMessage: data?.SSN
      ? "Our records indicate that SSN of the applicant exactly matches the information of an existing client. However, the name, DOB entered is different. Do you want to update the information or go ahead & submit your application?"
      : "Failure to provide your SSN may delay the processing of your application.",
    alertProceedYN: "Y",
    clientContainer: {
      navigationFlowRequested: false,
      client: {
        personName: {
          recordId: 0,
          firstName: firstName,
          lastName: lastName,
          middleName: middleName,
          suffix: suffix,
        },
        dob: DOB ?? null,
        dobVerification: {
          dirtyStatus: "",
          messages: [],
          verificationDocumentCd: "",
          verificationRequestedYN: "",
          docNames: {},
          documentDetails: [],
          verificationType: "",
          verificationSourceCode: "",
          verifiedYN: "",
          dueDate: null,
          automatedVerificationYN: "",
        },
        sourceSystem: "Enterprise",
        fortyQuartersInd: false,
        createMdmId: true,
        soSec: {
          recordId: 0,
          soSecPart1: data?.SSN ? data.SSN.substr(0, 3) : "",
          soSecPart2: data?.SSN ? data.SSN.substr(3, 2) : "",
          soSecPart3: data?.SSN ? data.SSN.substr(5) : "",
          ssnVerification: {
            dirtyStatus: "",
            retryCount: 0,
            messages: [],
            verificationDocumentCd: "",
            verificationRequestedYN: "",
            docNames: {},
            documentDetails: [],
            verificationType: "",
            verificationSourceCode: "",
            verifiedYN: "",
            dueDate: null,
            automatedVerificationYN: "",
          },
          groupNumber: 0,
        },
        headOfHouseholdYN: "Y",
        relationshipCd: "SE",
        gender: gender,
        ethnicity: "",
        race: {
          retryCount: 0,
          recordId: 0,
          primaryRace: "",
          otherRaces: [],
          groupNumber: 0,
        },
        maritalStatus: {
          marriedYN: martialStatus,
        },
        earnedIncomes: [],
        unearnedIncomes: [],
        dependentCareExpenses: [],
        unearnedIncomeApplications: [],
        shelterExpenses: [],
        shelterUtilityExpenses: [],
        utilityExpenses: [],
        disabledYN: "",
        blindYN: "",
        pregnantYN: areYouPregnant,
        individualDetails: {
          ethnicity: "",
          pregnancy: [
            {
              recordId: 0,
              pregnantYN: areYouPregnant,
              pregnancyDueDate: expDueDate
                ? dayjs(data.expDueDate).format("YYYY-MM-DD")
                : null,

              pregnancyStartDate: null,
              groupNumber: 0,
            },
          ],
          deathDetails: {
            dateOfDeath: null,
            state: "",
            deathCertificateNumber: "",
          },
        },
        hasSsnYN: "N",
      },
      paginationData: {
        pageBuffer: 0,
        pageNumber: 1,
        totalRecords: 0,
        resultsPerPage: 5,
      },
      sourceSystem: "C",
    },
    memberCreatedInWp: "N",
    caseId: "",
    populateExistingData: "",
    intakeProgramSelections: prgmDetails,
    editSsn: true,
  };
};

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { CloseRounded } from "@mui/icons-material";
import { FETCH_MEMBERS } from "../../../../store/types/membersListType";
import MultiStepper from "../../../../components/MultiStepper";
import { filter, find, includes, isElement, isEmpty, map, noop } from "lodash";
import { Controller, useForm } from "react-hook-form";
import Field from "../../../../components/Field";
import YesNoOption from "../../../../components/YesNoOption";
import Dropdown from "../../../../components/Dropdown";
import CDatePicker from "../../../../components/DatePicker";
import CoTextField from "../../../../controllers/CoTextField";
import {
  maxLength,
  name,
  number,
  onlyAlphabet,
} from "../../../../utils/normalizers";
import CRadioButton from "../../../../components/RadioButton";
import useLookup from "../../../../hooks/useLookup";
import AddressInfo from "./AddressInfo";
import StudentInfo from "./StudentInfo";
import DisabilityInfo from "./DisabilityInfo";
import HttpClient from "../../../../utils/HttpClient";
import { useSelector } from "react-redux";
import { selecttActiveContId } from "../../../../store/selectors/appSelector";
import dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import useLoader from "../../../../hooks/useLoader";
import usePageApi from "../../../../hooks/usePageApi";
import { trimSSN } from "../../../../utils/helpers";
import InputMask from "react-input-mask";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function MemberForm({
  open,
  toggle,
  onAddMember,
  isEdit,
  selected,
  pageData,
  choosenPrograms,
}) {
  const controlId = useSelector(selecttActiveContId) ?? 0;
  const dispatch = useDispatch();
  const [showLoader, hideLoader] = useLoader();
  const { getOptions } = useLookup("hoh");
  const { individualId = "" } = selected;
  const [maskSsn, setMaskSsn] = useState(true);
  const data = pageData;
  const memData = find(data, (item) => item?.individualId === individualId);
  const { pageData: contactInfo } = usePageApi({
    screenDefn: "/dashboard/consumer-app/interim/currentAddress",
    // controlId: controlId,
    screenId: 416,
  });
  const validationSchema = yup.object({
    personLive: yup.string().required("Please choose yes or no"),
    applyPerson: yup.string().when("personLive", {
      is: (value) => value === "Y",
      then: () => yup.string().required("Please choose yes or no"),
      otherwise: () => yup.string().notRequired(),
    }),
    firstName: yup.string().required("Please enter first name"),
    lastName: yup.string().required("Please enter last name"),
    dob: yup.string().required("Please select date of birth"),
    martialStatus: yup.string().required("Please select marital status"),
    gender: yup.string().required("Please select gender"),
    expDueDate: yup.string().when("areYouPregnant", {
      is: (value) => value === "Y",
      then: () => yup.string().required("Please select due date"),
      otherwise: () => yup.string().notRequired(),
    }),
    studentStatus: yup.string().when("isStudentYorN", {
      is: (isStudentYorN) => isStudentYorN === "Y",
      then: () => yup.string().required("Please select status"),
      otherwise: () => yup.string().notRequired(),
    }),
    line1: yup.string().required("Please enter line 1"),
    city: yup.string().required("Please select city"),
    state: yup.string().required("Please select state"),
    isStudentYorN: yup.string().required("Please select Yes or No"),
    zipcode: yup
      .string()
      .min(5, "Invalid zip code")
      .required("Please select zip code"),
    gDate: yup.string().when("isStudentYorN", {
      is: (isStudentYorN) => isStudentYorN === "Y",
      then: () =>
        yup
          .string()
          .test(
            "isvalid-grad-date",
            "Graduation date should be greater than dob",
            function (value, context) {
              const { dob } = context.parent ?? {};
              const validGDate = dayjs(dob).isBefore(value);
              return validGDate;
            }
          ),
      otherwise: () => yup.string().notRequired(),
    }),
  });

  const {
    control,
    formState: { errors, isValid },
    trigger,
    register,
    watch,
    getValues,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      City: memData?.studentInfo?.schoolAddress?.city ?? "",
      SSN:
        memData?.clientContainer?.client?.soSec?.soSecPart1 &&
        memData?.clientContainer?.client?.soSec?.soSecPart2 &&
        memData?.clientContainer?.client?.soSec?.soSecPart3
          ? `${memData?.clientContainer?.client?.soSec?.soSecPart1}-${memData?.clientContainer?.client?.soSec?.soSecPart2}-${memData?.clientContainer?.client?.soSec?.soSecPart3}`
          : "",

      addLine1: memData?.studentInfo?.schoolAddress?.addressLine1 ?? "",
      addLine2: memData?.studentInfo?.schoolAddress?.addressLine2 ?? "",
      applyPerson:
        memData?.clientContainer?.client?.aboutMember?.applyingFor ?? "",
      areYouPregnant: memData?.clientContainer?.client?.pregnantYN ?? "",
      asSame: "",
      city:
        memData?.clientContainer?.contactInformation?.homeAddress?.city ?? "",
      dob: memData?.clientContainer?.client?.dob ?? "",
      educationLevel: memData?.studentInfo?.educationLevel ?? "",
      ethnicity: memData?.clientContainer?.client?.ethnicity ?? "",
      expDueDate: memData?.clientContainer?.client?.individualDetails?.pregnancy
        ?.pregnancyDueDate
        ? dayjs(
            memData?.clientContainer?.client?.individualDetails?.pregnancy
              ?.pregnancyDueDate
          )
        : "",
      firstName: memData?.clientContainer?.client?.personName?.firstName ?? "",
      gDate: memData?.studentInfo?.graduationDate
        ? dayjs(memData?.studentInfo?.graduationDate)
        : "",
      gender: memData?.clientContainer?.client?.gender ?? "",
      goodstanding: memData?.studentInfo?.goodStanding ?? "",
      hearingImpairedYN: memData?.clientContainer?.client?.deafYN ?? "",
      homeLess: "",
      institution: "",
      isStudentYorN: memData?.studentInfo?.studentYN ?? "",
      lastGrade: memData?.studentInfo?.highestGrade ?? "",
      lastName: memData?.clientContainer?.client?.personName?.lastName ?? "",
      line1:
        memData?.clientContainer?.contactInformation?.homeAddress
          ?.addressLine1 ?? "",
      line2:
        memData?.clientContainer?.contactInformation?.homeAddress
          ?.addressLine2 ?? "",
      mailcity:
        memData?.clientContainer?.contactInformation?.mailingAddress?.city ??
        "",
      mailline1:
        memData?.clientContainer?.contactInformation?.mailingAddress
          ?.addressLine1 ?? "",
      mailline2:
        memData?.clientContainer?.contactInformation?.mailingAddress
          ?.addressLine2 ?? "",
      mailstate:
        memData?.clientContainer?.contactInformation?.mailingAddress?.state ??
        "",
      mailzipCode:
        memData?.clientContainer?.contactInformation?.mailingAddress?.zipcode ??
        "",
      mailzipCodePluse:
        memData?.clientContainer?.contactInformation?.mailingAddress
          ?.zip4code ?? "",
      martialStatus:
        memData?.clientContainer?.client?.maritalStatus?.marriedYN ?? "",
      middleName:
        memData?.clientContainer?.client?.personName?.middleName ?? "",
      personLive: memData?.clientContainer?.client?.aboutMember?.isLiving ?? "",
      physicallyImpairedYN: memData?.clientContainer?.client?.disabledYN ?? "",
      race: memData?.clientContainer?.client?.race?.primaryRace ?? "",
      schoolName: memData?.studentInfo?.schoolName ?? "",
      schoolNumber: memData?.studentInfo?.schoolNumber ?? "",
      state:
        memData?.clientContainer?.contactInformation?.homeAddress?.state ?? "",
      zipcode:
        memData?.clientContainer?.contactInformation?.homeAddress?.zipcode ??
        "",
      studentStatus: memData?.studentInfo?.studentStatus ?? "",
      suffix: memData?.clientContainer?.client?.personName?.suffix ?? "",
      visuallyImpairedYN: memData?.clientContainer?.client?.blindYN ?? "",
      zip4Code:
        memData?.clientContainer?.contactInformation?.homeAddress?.zip4code ??
        "",
      zipCode: memData?.studentInfo?.schoolAddress?.zipcode ?? "",
      zipCodePluse: memData?.studentInfo?.schoolAddress?.zip4code ?? "",
      stateSchl: memData?.studentInfo?.schoolAddress?.state ?? "",
    },
  });

  const [currentStep, setCurrentStep] = useState(0);
  const { suffix, martialStatus, gender } = useMemo(() => {
    return {
      suffix: getOptions("Suffix"),
      martialStatus: getOptions("MartialStatus"),
      gender: getOptions("Gender"),
    };
  }, [getOptions]);

  const stages = useMemo(() => {
    return [
      { title: "About", done: currentStep === 0 },
      { title: "Member Information", done: currentStep === 1 },
      { title: "Disability Information", done: currentStep === 2 },
      { title: "Address Information", done: currentStep === 3 },
      { title: "Student Information", done: currentStep === 4 },
    ];
  }, [currentStep]);

  const isPersonLive = watch("personLive") === "Y";
  const isApplyPerson = watch("applyPerson") === "Y";
  const isFemale = watch("gender") === "F";
  const isPregnant = watch("areYouPregnant") === "Y";

  useEffect(() => {
    if (isPersonLive && !isEmpty(contactInfo)) {
      setValue(
        "line1",
        contactInfo?.[0]?.contactInformationDetails?.contactInformation
          ?.homeAddress?.addressLine1
      );
      setValue(
        "line2",
        contactInfo?.[0]?.contactInformationDetails?.contactInformation
          ?.homeAddress?.addressLine2
      );
      setValue(
        "state",
        contactInfo?.[0]?.contactInformationDetails?.contactInformation
          ?.homeAddress?.state
      );
      setValue(
        "city",
        contactInfo?.[0]?.contactInformationDetails?.contactInformation
          ?.homeAddress?.city
      );
      setValue(
        "zipcode",
        contactInfo?.[0]?.contactInformationDetails?.contactInformation
          ?.homeAddress?.zipcode
      );
      setValue(
        "zip4code",
        contactInfo?.[0]?.contactInformationDetails?.contactInformation
          ?.homeAddress?.zip4code
      );
      setValue(
        "asSame",
        contactInfo?.[0]?.contactInformationDetails?.contactInformation
          ?.sameMailingAddressYN === "Y"
          ? true
          : false
      );
    }
  }, [isPersonLive, contactInfo]);

  const onNext = () => {
    if (currentStep < stages.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const onBack = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const fieldsObj = {
    0: ["applyPerson", "personLive"],
    1: [
      "firstName",
      "lastName",
      "dob",
      "martialStatus",
      "gender",
      "expDueDate",
    ],
    2: ["race"],
    3: ["addressLine1", "city", "state", "zipcode"],
    4: [""],
  };

  const onBackOrNext = async (newStep) => {
    let valid = false;
    valid = await trigger(fieldsObj[currentStep]);
    if (valid) {
      setCurrentStep(newStep);
    } else {
      toast.warn("Please provide missing/wrong details");
    }
  };

  const saveMember = async (data) => {
    const trimmedSsn = trimSSN(data.SSN);
    try {
      showLoader();
      const payload = {
        dirtyStatus: isEdit ? "UPDATE" : "INSERT",
        individualId,
        clearanceStatus: "N",
        controlId,
        status: true,
        importSelected: true,
        editable: false,
        realIndividualId: "",
        clientContainer: {
          navigationFlowRequested: false,
          paginationData: {
            resultsPerPage: 5,
            pageNumber: 1,
            totalRecords: 0,
            pageBuffer: 0,
          },
          sourceSystem: "C",
          client: {
            personName: {
              recordId: 0,
              firstName: data.firstName,
              middleName: data.middleName,
              lastName: data.lastName,
              suffix: "",
            },
            dob: dayjs(data.dob).format("YYYY-MM-DD"),
            soSec: {
              recordId: 0,
              soSecPart1: trimmedSsn ? trimmedSsn.substr(0, 3) : "",
              soSecPart2: trimmedSsn ? trimmedSsn.substr(3, 2) : "",
              soSecPart3: trimmedSsn ? trimmedSsn.substr(5) : "",
              ssnVerification: {
                dirtyStatus: "",
                retryCount: 0,
                messages: [],
                verificationDocumentCd: "",
                verificationRequestedYN: "",
                docNames: {},
                automatedVerificationYN: "",
                documentDetails: [],
                verificationType: "",
                verifiedYN: "",
                verificationSourceCode: "",
              },
              groupNumber: 0,
            },
            gender: data.gender,
            race: {
              retryCount: 0,
              recordId: 0,
              primaryRace: data.race,
              otherRaces: ["A"],
              groupNumber: 0,
            },
            headOfHouseholdYN: "N",
            relationshipCd: "",
            maritalStatus: {
              retryCount: 0,
              marriedYN: data.martialStatus,
              recordId: 0,
              groupNumber: 0,
            },
            ethnicity: data.ethnicity,
            pregnantYN: data.areYouPregnant,
            hasSsnYN: "N",
            individualDetails: {
              deathDetails: {
                retryCount: 0,
                state: "",
                deathCertificateNumber: "",
              },
              pregnancy: [
                {
                  pregnantYN: data.areYouPregnant,
                  pregnancyDueDate: data.expDueDate
                    ? dayjs(data.expDueDate).format("YYYY-MM-DD")
                    : null,
                  groupNumber: 0,
                },
              ],
              ethnicity: data.ethnicity,
            },
            disabilities: [],
            earnedIncomes: [],
            unearnedIncomes: [],
            dependentCareExpenses: [],
            unearnedIncomeApplications: [],
            shelterExpenses: [],
            shelterUtilityExpenses: [],
            utilityExpenses: [],
            disabledYN: data.physicallyImpairedYN,
            blindYN: data.visuallyImpairedYN,
            deafYN: data.hearingImpairedYN,
            dobVerification: {
              dirtyStatus: "",
              retryCount: 0,
              messages: [],
              verificationDocumentCd: "",
              verificationRequestedYN: "",
              docNames: {},
              automatedVerificationYN: "",
              documentDetails: [],
              verificationType: "",
              verifiedYN: "",
              verificationSourceCode: "",
            },
            aboutMember: {
              isLiving: data.personLive,
              applyingFor: data.applyPerson,
            },
            fortyQuartersInd: false,
            address: {
              zip4code: data.zipCodePluse,
              vendorId: 0,
              status: true,
              addressLine1: data.line1,
              addressLine2: data.line2,
              city: data.city,
              state: data.state,
              zipcode: data.zipcode,
              county: "",
            },
            createMdmId: true,
          },
          contactInformation: {
            email: "a2@b.com",
            preferredModeOfCommunication: "Paperless",
            homeAddress: {
              zip4code: data.zipCodePluse,
              vendorId: 0,
              status: true,
              addressLine1: data.line1,
              addressLine2: data.line2,
              city: data.city,
              state: data.state,
              zipcode: data.zipcode,
              county: "",
            },
            mailingAddress: {
              zip4code: data.mailzipCodePluse,
              vendorId: 0,
              status: true,
              addressLine1: data.mailline1,
              addressLine2: data.mailline2,
              city: data.mailcity,
              state: data.mailstate,
              zipcode: data.mailzipCode,
              county: "",
            },
            phoneNumberDetails: {
              phoneNumber: "324 243-2432",
              phoneNumberTypeCd: "",
              phoneNumberExtension: "",
              altPhoneNumber: "234 324-2424",
              altPhoneNumberTypeCd: "",
              altPhoneNumberExtension: "",
            },
            phoneNumberDetailsHstry: [],
            phoneNumber: {
              phoneNumber: "324 243-2432",
              phoneNumberType: "",
              phoneNumberExt: "",
            },
            altPhoneNumber: {
              phoneNumber: "234 324-2424",
              phoneNumberType: "",
              phoneNumberExt: "",
            },
          },
        },
        screenDefn: "/dashboard/consumer-app/intake-app/members/memberInfo",
        studentInfo: {
          studentYN: data.isStudentYorN,
          effectiveBeginDate: "2024-01-09",
          effectiveEndDate: "2024-01-25",
          studentStatus: data.studentStatus,
          educationLevel: data.educationLevel,
          highestGrade: data.lastGrade,
          schoolName: data.schoolName,
          schoolNumber: data.schoolNumber,
          graduationDate: data.gDate
            ? dayjs(data.gDate).format("YYYY-MM-DD")
            : null,
          goodStanding: data.goodstanding,
          studentStatusVerification: {
            retryCount: 0,
          },
          goodStandingVerification: {
            retryCount: 0,
          },
          graduationDateVerification: {
            retryCount: 0,
          },
          schoolAddress: {
            zip4code: data.zipCodePluse,
            vendorId: 0,
            status: true,
            addressLine1: data.addLine1,
            addressLine2: data.addLine2,
            city: data.City,
            state: data.stateSchl,
            zipcode: data.zipCode,
          },
          groupNumber: 0,
        },
        alertProceedYN: "Y",
        alertMessage:
          "Failure to provide your SSN may delay the processing of your application.",
        memberCreatedInWp: "N",
        caseId: "",
        populateExistingData: "",
        intakeProgramSelections: map(choosenPrograms || [], (programName) => {
          return {
            dirtyStatus: "",
            individualId,
            clearanceStatus: "",
            controlId: 0,
            status: false,
            importSelected: true,
            editable: true,
            importedFromWp: "",
            programSelected: {
              dirtyStatus: isEdit ? "update" : "insert",
              programId: 0,
              programName,
              programSubType: "",
              programStatusCode: "",
              applicationDate: dayjs().format("YYYY-MM-DD"),
              finalizeProgramIndicator: "",
              selectedIndicator: "Y",
              waiverIndicator: "",
              programMode: "",
              confirmProgramIndicator: "",
            },
          };
        }),
        editSsn: true,
      };

      const res = await HttpClient.post(
        "/eeapi/public/consumer/member",
        payload
      );

      if (res.status === 200) {
        dispatch({ type: FETCH_MEMBERS });
        toast.success("Member has been added successfully");

        onAddMember();
        toggle();
      }
    } catch (err) {
      toast.error("Unable to add member");
    } finally {
      hideLoader();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={toggle}
      component="form"
      onSubmit={handleSubmit(saveMember)}
      maxWidth="lg"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "80vw",
            height: "80vh",
          },
        },
      }}
      // fullWidth
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3">Add Household Member</Typography>
          <IconButton onClick={toggle}>
            <CloseRounded />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack sx={{ minHeight: "100%" }}>
          <MultiStepper steps={stages} />
          <Box
            sx={{
              border: "1px solid lightgrey",
              borderRadius: "0.5rem",
              p: "1rem",
              minHeight: "100%",
              mt: "1rem",
            }}
          >
            {currentStep === 0 && (
              <Grid container>
                <Grid item xs={8}>
                  <Field
                    name="personLive"
                    label="Is this person living with Head of Houshold?"
                    error={errors?.personLive?.message}
                    showError={errors?.personLive?.message}
                    required
                  >
                    <Controller
                      name="personLive"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} />;
                      }}
                    />
                  </Field>
                </Grid>
                {isPersonLive && (
                  <Grid item xs={8} mt="2rem">
                    <Field
                      name="applyPerson"
                      error={errors?.applyPerson?.message}
                      showError={errors?.applyPerson?.message}
                      label="Are you applying for this person?"
                      required
                    >
                      <Controller
                        name="applyPerson"
                        control={control}
                        render={({ field }) => {
                          return <YesNoOption {...field} />;
                        }}
                      />
                    </Field>
                  </Grid>
                )}

                {isApplyPerson && (
                  <Grid item xs={8} mt={"2rem"}>
                    <Field
                      name="chooseProgram"
                      label="Please Choose the member's associated programs"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="medicaid"
                            checked={includes(choosenPrograms, "MA")}
                            disabled
                          />
                        }
                        label="Medicaid"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="cashAssist"
                            checked={includes(choosenPrograms, "LT")}
                            disabled
                          />
                        }
                        label="Longterm/Wavier"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="cashAssist"
                            checked={includes(choosenPrograms, "CH")}
                            disabled
                          />
                        }
                        label="Cash Assistance"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="snap"
                            checked={includes(choosenPrograms, "FS")}
                            disabled
                          />
                        }
                        label="(SNAP) Supplemental Nutrition Assistance Program"
                      />
                    </Field>
                  </Grid>
                )}
              </Grid>
            )}
            {currentStep === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="firstName"
                    error={errors.firstName?.message}
                    showError={errors.firstName?.message}
                    required
                    label="First Name"
                  >
                    <CoTextField
                      normalizers={[onlyAlphabet, name, maxLength(20)]}
                      name="firstName"
                      control={control}
                      placeholder="Enter First Name"
                    />
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field name="middleName" label="Middle Name">
                    <CoTextField
                      normalizers={[onlyAlphabet, name, maxLength(15)]}
                      name="middleName"
                      control={control}
                      placeholder="Enter Middle Name"
                    />
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="lastName"
                    error={errors.lastName?.message}
                    showError={errors.lastName?.message}
                    label="Last Name"
                    required
                  >
                    <CoTextField
                      normalizers={[onlyAlphabet, name, maxLength(25)]}
                      name="lastName"
                      control={control}
                      placeholder="Enter Last Name"
                    />
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field name="suffix" label="Suffix">
                    <Dropdown
                      name="suffix"
                      control={control}
                      options={suffix}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="SSN" label="SSN">
                    <Controller
                      name="SSN"
                      control={control}
                      render={({ field }) => (
                        <InputMask
                          {...register("SSN")}
                          mask="999-99-9999"
                          maskChar={null}
                          value={field.value}
                          onBlur={field.onBlur}
                        >
                          {(params) => (
                            <TextField
                              // {...register("claimid")}
                              variant="outlined"
                              placeholder="Enter SSN Number"
                              {...params}
                              type={maskSsn ? "password" : "text"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      sx={{ marginRight: "-13px" }}
                                      onClick={() => setMaskSsn(!maskSsn)}
                                    >
                                      {maskSsn ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </InputMask>
                      )}
                    />
                    {/* <CoTextField
                      normalizers={[number, maxLength(9)]}
                      name="SSN"
                      control={control}
                      placeholder="Enter SSN Number"
                    /> */}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    error={errors.dob?.message}
                    showError={errors.dob?.message}
                    name="dob"
                    label="DOB"
                    required
                  >
                    <CDatePicker
                      name="dob"
                      control={control}
                      placeholder="Select Date(MM/DD/YYYY)"
                      disableFuture
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field
                    error={errors.martialStatus?.message}
                    showError={errors.martialStatus?.message}
                    name="martialStatus"
                    label="Marital Status"
                    required
                  >
                    <Dropdown
                      name="martialStatus"
                      control={control}
                      options={martialStatus}
                      placeholder="Please select Marital Status"
                    />
                  </Field>
                </Grid>

                <Grid item xs={6} mt="0.5rem">
                  <Field
                    name="gender"
                    error={errors.gender?.message}
                    showError={errors.gender?.message}
                    label="Gender"
                    required
                  >
                    <CRadioButton
                      row
                      name="gender"
                      control={control}
                      radioArray={gender}
                    />
                  </Field>
                </Grid>
                {isFemale && (
                  <Grid item xs={6}>
                    <Field name="areYouPregnant" label="Are you Pregnant?">
                      <Box>
                        <CRadioButton
                          row
                          name="areYouPregnant"
                          control={control}
                          radioArray={[
                            {
                              label: "Yes",
                              value: "Y",
                            },
                            {
                              label: "No",
                              value: "N",
                            },
                          ]}
                        />
                      </Box>
                    </Field>
                  </Grid>
                )}

                {isPregnant && (
                  <Grid item xs={6}>
                    <Field
                      error={errors.expDueDate?.message}
                      showError={errors.expDueDate?.message}
                      name="expDueDate"
                      label="Expected Due Date"
                      required
                    >
                      <CDatePicker
                        name="expDueDate"
                        control={control}
                        placeholder="Select Date(MM/DD/YYYY)"
                      />
                    </Field>
                  </Grid>
                )}
              </Grid>
            )}
            {currentStep === 2 && (
              <DisabilityInfo
                control={control}
                watch={watch}
                register={register}
                getValues={getValues}
                errors={errors}
              />
            )}
            {currentStep === 3 && (
              <AddressInfo
                control={control}
                watch={watch}
                register={register}
                contactInfo={watch("personLive") === "Y" ? contactInfo : ""}
                getValues={getValues}
                errors={errors}
                reset={reset}
                setValue={setValue}
              />
            )}
            {currentStep === 4 && (
              <StudentInfo
                control={control}
                watch={watch}
                register={register}
                getValues={getValues}
                errors={errors}
                reset={reset}
                setValue={setValue}
              />
            )}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" mr="1rem">
          <Button
            size="large"
            sx={{ mr: "0.5rem" }}
            variant="outlined"
            disabled={currentStep === 0}
            onClick={() => onBack()}
          >
            Back
          </Button>

          {currentStep === stages.length - 1 && (
            <Button
              type="submit"
              size="large"
              // disabled={!errors}
              variant="contained"
            >
              Save
            </Button>
          )}
          {currentStep < stages.length - 1 && (
            <Button
              size="large"
              onClick={() => onBackOrNext(currentStep + 1)}
              variant="contained"
            >
              Next
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

MemberForm.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  onAddMember: PropTypes.func,
  isEdit: PropTypes.bool,
  individualId: PropTypes.number,
  choosenPrograms: PropTypes.array,
  selected: PropTypes.shape({}),
};

MemberForm.defaultProps = {
  open: true,
  toggle: noop,
  onAddMember: PropTypes.func,
  isEdit: false,
  individualId: "",
  choosenPrograms: [],
  selected: {},
};

export default MemberForm;

import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Stack,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import usePageApi from "../../../hooks/usePageApi";
import CourtOrderModal from "../CourtOrderModal";
import { cloneDeep, filter, isEmpty, map } from "lodash";
import useLookup from "../../../hooks/useLookup";
import { totalAmount } from "../../../utils/helpers";
import ActionButtons from "../../../components/ActionButtons";
import useLoader from "../../../hooks/useLoader";
import UploadFile from "../../../components/FileUpload";
import { showSuccessToast } from "../../../utils/services/toast";
import { GetAmountModelCourtOrder } from "../constant";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
// import ActionButtons from "../../../components/";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export default function CourtOrderTable({ selected, getName }) {
  const [courtOpen, setCourtOpen] = useState(false);
  const [editCourtOpen, setEditCourtOpen] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const [apiData, setApiData] = useState([]);
  const [editData, setEditData] = useState({});
  const [rowData, setRowData] = useState();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const { getOptions, getLkpMatch } = useLookup("CourtOrder");
  const [docOpen, setDocOpen] = useState(false);

  const { courtOptions } = useMemo(() => {
    return {
      courtOptions: getOptions("Legal"),
    };
  }, [getOptions]);
  const toggleCourtOrder = () => {
    setCourtOpen(false);
    setEditCourtOpen(false);
  };
  const frequencyVal = useMemo(() => {
    return {
      frequencyVal: getOptions("Frequency"),
    };
  }, [getOptions]);
  const toggleClose = () => {
    setIsDeleteOpen(false);
  };
  const { dummyIndividualId } = selected;
  const { pageData, saveJson, labels, retrieveJson } = usePageApi({
    screenDefn: "/dashboard/consumer-app/intake-app/fin/courtOrder",
    screenId: 165,
  });
  const deletePopup = (data) => {
    let { id } = data;
    setDeleteData(id);
    setIsDeleteOpen(true);
  };
  const handleDelete = async () => {
    try {
      showLoader();
      if (modificationData && modificationData.length > 0) {
        const deletePayload = modificationData.filter(
          (item) => item.id !== deleteData
        );
        const res = await saveJson(deletePayload);
        if (res?.status === 200) {
          retrieveJson();
          showSuccessToast("Successfully Deleted");
          setIsDeleteOpen(false);
        }
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  //   const data = pageData;

  const columnData = [
    {
      field: "col1",
      headerName: ` ${labels?.supType ?? "Support Type"}`,
      renderCell: (params) =>
        `${getLkpMatch(
          "ChildSpousalExpense",
          params.row?.childSpousalExpenses?.suprTypeCd
        )}`,
      flex: 1,
    },
    {
      field: "col2",
      headerName: ` ${labels?.frequency ?? "Frequency"}`,
      renderCell: (params) =>
        `${getLkpMatch(
          "Frequency",
          params.row?.childSpousalExpenses?.expense[0]?.frequency
        )}`,
      flex: 1,
    },
    {
      field: "col3",
      headerName: ` ${labels?.amount ?? "Amount"}`,
      renderCell: (params) =>
        ` $ ${totalAmount(
          params.row?.childSpousalExpenses?.expense[0]?.amountModel
        )}`,
      flex: 1,
    },

    {
      field: "actions",

      headerName: `${labels?.actions ?? "Actions"}`,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => editCourtOrder(params?.row)}
              onDelete={() => deletePopup(params?.row)}
            />
          </div>
        );
      },
    },
  ];
  useMemo(() => {
    if (pageData) {
      setApiData(pageData);
    }
  }, [pageData]);
  const modificationData = map(apiData, (item, index) => ({
    ...item,
    id: index,
  }));
  useMemo(() => {
    setRowData(
      filter(modificationData, (value) => {
        if (value.individualId === dummyIndividualId) {
          return value;
        }
      })
    );
  }, [apiData, dummyIndividualId]);

  const editCourtOrder = (data) => {
    setEditCourtOpen(true);
    setEditData(data);
  };
  const handleSaveJson = async (data) => {
    const payload = {
      status: true,
      individualId: dummyIndividualId,
      caseId: "",
      dirtyStatus: "INSERT",
      childSpousalExpenses: {
        recordId: 0,
        dirtyStatus: "INSERT",
        effectiveBeginDate: data?.effectiveBeginDate ?? null,
        effectiveEndDate: data?.effectiveEndDate ?? null,
        suprTypeCd: data?.suprTypeCd,
        expense: [
          {
            frequency: data?.frequency,
            amountModel: [
              {
                label: "One Time Only Income",
                amount: data?.amount,
                recordId: 0,
                sortOrder: 1,
                dirtyStatus: "insert",
                verificationSourceCode: "",
              },
            ],
          },
        ],
        childSpslExpenseVerification: {
          dirtyStatus: "INSERT",
          verificationDocumentCd: "",
          verificationRequestedYN: "N",
          verificationType: "",
          verificationSourceCode: "NO",
          verifiedYN: "",
          dueDate: null,
          automatedVerificationYN: "N",
        },
      },
      importSelected: true,
    };
    let model = GetAmountModelCourtOrder(data);
    payload.childSpousalExpenses.expense[0].amountModel = model;
    let clonedPageData = cloneDeep(pageData || []);
    if (isEmpty(editData)) {
      //handling add data
      clonedPageData.push(payload);
    } else {
      //handling edit data
      clonedPageData = modificationData.map((item) => {
        if (item.id === editData.id) {
          return payload;
        }
        return item;
      });
    }
    const res = await saveJson(clonedPageData);
    if (res?.status == 200) {
      retrieveJson();
      setCourtOpen(false);
      toggleCourtOrder();
    }
  };

  return (
    <>
      <Grid mt={2}>
        <Stack direction={"row"} mb={1} justifyContent="space-between">
          <Button
            variant="outlined"
            size="small"
            startIcon={<UploadFileIcon />}
            // onClick={handleOpenDocModal}
            onClick={() => setDocOpen(true)}
          >
            {labels?.upload ?? " Upload files"}
          </Button>
          <Button onClick={() => setCourtOpen(true)} startIcon={<AddIcon />}>
            {labels?.add ?? "Add"} {labels?.cse ?? "Child Support Expense"}
          </Button>
        </Stack>
        <DataGrid
          //   rows={[data]}
          rows={rowData ?? []}
          columns={columnData}
          //   getRowId={(row) => row?.recordId}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "1.1rem",
              fontWeight: "700",
              padding: "0rem 0.6rem",
              color: "#000000",
            },
            "& .MuiDataGrid-row": {
              fontSize: "1rem",
              color: "black",
              padding: "0rem 0.6rem",
              textAlign: "center",
            },
          }}
          slots={{
            noRowsOverlay: "noData",
          }}
        />
      </Grid>
      <Dialog maxWidth="lg" open={courtOpen} onClose={toggleCourtOrder}>
        <DialogTitle>
          <Stack
            direction={"row"}
            spacing={1}
            width={"65rem"}
            justifyContent={"space-between"}
            mt={2}
          >
            <Typography variant="h3">
              {labels?.add ?? "Add"} {labels?.cse ?? "Child Support Expense"}
            </Typography>
            <IconButton onClick={toggleCourtOrder}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <CourtOrderModal
            toggleCourtOrder={toggleCourtOrder}
            labels={labels}
            createNew
            saveJson={saveJson}
            frequency={frequencyVal?.value}
            dummyIndividualId={dummyIndividualId}
            // pageData={pageData}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
            handleSaveJson={handleSaveJson}
          />
        </DialogContent>
      </Dialog>
      <Dialog maxWidth="lg" open={editCourtOpen} onClose={toggleCourtOrder}>
        <DialogTitle>
          <Stack
            direction={"row"}
            spacing={1}
            width={"65rem"}
            justifyContent={"space-between"}
            mt={2}
          >
            <Typography variant="h3">
              {labels?.add ?? "Add"} {labels?.cse ?? "Child Support Expense"}
            </Typography>
            <IconButton onClick={toggleCourtOrder}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <CourtOrderModal
            toggleCourtOrder={toggleCourtOrder}
            labels={labels}
            editData={editData}
            saveJson={saveJson}
            frequency={frequencyVal?.value}
            dummyIndividualId={dummyIndividualId}
            // pageData={pageData}
            retrieveJson={retrieveJson}
            handleSaveJson={handleSaveJson}
            modificationData={modificationData}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteOpen}
        onClose={toggleClose}
        fullWidth
        maxWidth="sm"
        keepMounted
      >
        <DialogContent>
          <Typography variant="h5grey">
            Are you sure, you want to delete this record?
          </Typography>
        </DialogContent>

        <DialogActions sx={{ margin: "0.8rem", textAlign: "center" }}>
          <Button variant="contained" color="primary" onClick={toggleClose}>
            No
          </Button>
          <Button color="error" variant="contained" onClick={handleDelete}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <UploadFile
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        activeIndex={dummyIndividualId}
        getName={getName}
        defaultDocType="LE"
        subTypeOptions={courtOptions}
      />
      {/* <Dialog maxWidth="lg" open={editCourtOpen} onClose={toggleCourtOrder}>
        <DialogContent>
          <MedicalExpModal
            toggleCourtOrder={toggleCourtOrder}
            labels={labels}
            data={data}
            onSave={onSave}
            getValues={getValues}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            // onClick={handleDeleteProgram}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}

import { all, takeEvery, put, takeLatest, select } from "redux-saga/effects";
import HttpClient, { authApi } from "../../utils/HttpClient";
import { FETCH_MEMBERS } from "../types/membersListType";
import { setActiveMembers } from "../slices/appSlice";
import { selecttActiveContId } from "../selectors/appSelector";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

export function* fetchMembers() {
  const controlId = yield select(selecttActiveContId);
  try {
    let HohData;
    const result = yield HttpClient.post(
      "/eeapi/public/consumer/jsonRetrieve",
      {
        screenDefn: "/dashboard/consumer-app/intake-app/members/memberInfo",
        controlId: controlId,
      }
    );
    const gethohData = yield HttpClient.post(
      "eeapi/public/consumer/hohinfo/jsonRetrieve",
      {
        screenDefn: [
          "/dashboard/consumer-app/intake-app/hoh/hohInfo",
          "/dashboard/consumer-app/interim/currentAddress",
          "/dashboard/consumer-app/intake-app/hoh/disabilityInfo",
        ],
        controlId: controlId,
      }
    );
    const parsedData = JSON.parse(gethohData.data ?? []);
    if (gethohData.status === 200 && !isEmpty(parsedData)) {
      HohData = parsedData?.[0]?.[0]?.individualId;
    }
    if (result.status === 200) {
      const parsedData = JSON.parse(result.data);
      const activeMembersId = [HohData];
      if (result.data === "No Json Doc found") {
        yield put(setActiveMembers(activeMembersId));
      } else {
        parsedData.forEach((element) => {
          if (element.dirtyStatus !== "DELETE")
            activeMembersId.push(element.individualId);
        });
        yield put(setActiveMembers(activeMembersId));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* watchMembers() {
  yield all([takeEvery(FETCH_MEMBERS, fetchMembers)]);
}

import { hohDetails } from "./apiPayload";
import { HOHDETAILS, DELETEMEMBERS } from "./apiUrls";
import { format } from "../../utils/helpers";
import HttpClient from "../../utils/HttpClient";

export const getHohDetails = (data) => {
  return HttpClient.post(format(HOHDETAILS), hohDetails(data));
};

export const deleteMember = (caseId, data, uuid) => {
  return HttpClient.post(format(DELETEMEMBERS, caseId), data, {
    headers: {
      "Content-Type": "aplication/plain",
    },
  });
};

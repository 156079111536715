import { Grid, Stack, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import usePageApi from "../../../../hooks/usePageApi";
import { filter } from "lodash";
import dayjs from "dayjs";
import useLookup from "../../../../hooks/useLookup";
import ActionButtons from "../../../../components/ActionButtons";
import Divider from "@mui/material/Divider";
import usePageNav from "../../../../hooks/usePageNav";

export default function MemberDatas({ selected, pageData, labels }) {
  const { individualId } = selected;
  const { getLkpMatch } = useLookup("memberSummary");
  const { navigations } = usePageNav();
  const [data, setData] = useState([]);
  // const { soSecPart1, soSecPart2, soSecPart3 } = soSec ?? {};
  return (
    <>
      {/* <Divider /> */}
      <Grid container spacing={2} xs={12}>
        <Grid
          item
          xs={11}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "left",
          }}
        >
          <Typography variant="h4grey">{labels?.name ?? "Name"}</Typography>
          <Typography width="50%">{selected?.name ?? ""}</Typography>
        </Grid>
        <Grid
          item
          xs={11}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4grey">{labels?.gender ?? "Gender"}</Typography>
          <Typography width="50%">
            {getLkpMatch("Gender", selected?.clientContainer?.client?.gender) ??
              "-"}
          </Typography>
        </Grid>

        <Grid
          item
          xs={11}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4grey">{labels?.dob ?? "DOB"}</Typography>
          <Typography width="50%">
            {dayjs(selected?.clientContainer?.client?.dob).format(
              "MM-DD-YYYY"
            ) ?? "-"}
          </Typography>
        </Grid>
        <Grid
          xs={11}
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4grey">
            {labels?.martialStatus ?? "Martial Status"}
          </Typography>
          <Typography width="50%">
            {getLkpMatch(
              "MartialStatus",
              selected?.clientContainer?.client?.maritalStatus?.marriedYN
            ) ?? "-"}
          </Typography>
        </Grid>
        <Grid
          xs={11}
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4grey">{labels?.race ?? "Race"}</Typography>
          <Typography width="50%">
            {getLkpMatch(
              "Race",
              selected?.clientContainer?.client?.race?.primaryRace
            ) ?? "-"}
          </Typography>
        </Grid>
        <Grid
          xs={11}
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4grey">
            {labels?.ethinicity ?? "Ethnicity"}
          </Typography>
          <Typography width="50%">
            {getLkpMatch(
              "Ethnicity",
              selected?.clientContainer?.client?.ethnicity
            ) ?? "-"}
          </Typography>
        </Grid>
        <Grid
          xs={11}
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4grey">
            {labels?.disability ?? "Disability"}
          </Typography>
          <Typography></Typography>
        </Grid>
        <Grid
          xs={11}
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ marginLeft: "1.2rem" }} variant="h4grey">
            Deaf
          </Typography>
          <Typography width="50%">
            {getLkpMatch("YesNo", selected?.clientContainer?.client?.deafYN)}
          </Typography>
        </Grid>
        <Grid
          xs={11}
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ marginLeft: "1.2rem" }} variant="h4grey">
            Blind
          </Typography>
          <Typography width="50%">
            {getLkpMatch("YesNo", selected?.clientContainer?.client?.blindYN)}
          </Typography>
        </Grid>
        <Grid
          xs={11}
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ marginLeft: "1.2rem" }} variant="h4grey">
            Disabled Physically
          </Typography>
          <Typography width="50%">
            {getLkpMatch(
              "YesNo",
              selected?.clientContainer?.client?.disabledYN
            )}
          </Typography>
        </Grid>
        <Grid
          xs={11}
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Typography variant="h4grey">
            {labels?.address ?? "Address"}
          </Typography>
          <Typography width="50%">
            {selected?.clientContainer?.client?.address?.addressLine1 ?? "-"}
            &nbsp;{selected?.clientContainer?.client?.address?.city ?? "-"}
            &nbsp;
            {getLkpMatch(
              "State",
              selected?.clientContainer?.client?.address?.state
            ) ?? "-"}
            &nbsp;{selected?.clientContainer?.client?.address?.zipcode ?? "-"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

import {
  Box,
  IconButton,
  Stack,
  Typography,
  Grid,
  TextField,
  Button,
  Paper,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as HomeMed } from "../../../assets/images/home.svg";
import { ReactComponent as Pill } from "../../../assets/images/pill.svg";
import { ReactComponent as Stethoscope } from "../../../assets/images/stethoscope.svg";
import { ReactComponent as Combine } from "../../../assets/images/combine.svg";
import CCheckBox from "../../../components/checkBox";
import { Controller, useForm } from "react-hook-form";
import CRadioButton from "../../../components/RadioButton";
import Field from "../../../components/Field";
import InputMask from "react-input-mask";
import usePageApi from "../../../hooks/usePageApi";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useLoader from "../../../hooks/useLoader";
import { medicarePayload } from "../../../services/MedicareDetails/apiPayload";
import CoTextField from "../../../controllers/CoTextField";
import {
  alphaNumeric,
  maxLength,
  formatCurrency,
} from "../../../utils/normalizers";
import { showSuccessToast } from "../../../utils/services/toast";
import { some } from "lodash";

export default function MedicarePopup({
  toggleMedicare,
  labels,
  createNew,
  editData,
  modificationData,
  dummyIndividualId,
  data,
  saveJson,
  retrieveJson,
  pageData,
}) {
  // const previousData = editData;
  const [showLoader, hideLoader] = useLoader();
  const [isAnyChecked, setIsAnyChecked] = useState(false);

  const validationSchema = yup.object(
    {
      partatype: yup.string().when(["partA"], {
        is: (partA) => partA,
        then: () => yup.string().required("Please Select any Options"),
        otherwise: () => yup.string().notRequired(),
      }),
      partbtype: yup.string().when(["partB"], {
        is: (partB) => partB,
        then: () => yup.string().required("Please Select any Options"),
        otherwise: () => yup.string().notRequired(),
      }),
      partctype: yup.string().when(["partC"], {
        is: (partC) => partC,
        then: () => yup.string().required("Please Select any Options"),
        otherwise: () => yup.string().notRequired(),
      }),
      partdtype: yup.string().when(["partD"], {
        is: (partD) => partD,
        then: () => yup.string().required("Please Select any Options"),
        otherwise: () => yup.string().notRequired(),
      }),
      appId: yup
        .string()
        .when(["partatype", "partbtype", "partctype", "partdtype"], {
          is: (partatype, partbtype, partctype, partdtype) => {
            const flag = some(
              [partatype, partbtype, partctype, partdtype],
              (type) => type === "A"
            );
            return flag;
          },
          then: () => yup.string().required("Please Enter Application id"),
          otherwise: () => yup.string().notRequired(),
        }),
      claimid: yup
        .string()
        .when(["partatype", "partbtype", "partctype", "partdtype"], {
          is: (partatype, partbtype, partctype, partdtype) => {
            return some(
              [partatype, partbtype, partctype, partdtype],
              (type) => type === "R"
            );
          },
          then: () => yup.string().required("Please Enter Claim id"),
          otherwise: () => yup.string().notRequired(),
        }),
    },
    [
      ["partatype", "partA"],
      ["partbtype", "partB"],
      ["partctype", "partC"],
      ["partdtype", "partD"],
    ]
  );

  const {
    medicareTypeA,
    medicareTypeB,
    medicareTypeC,
    medicareTypeD,
    medicareTypeAStatus,
    medicareTypeBStatus,
    medicareTypeCStatus,
    medicareTypeDStatus,
    claimid,
    applicationNum,
    premiumAmount,
  } = editData?.medicare ?? {};

  const {
    control,
    watch,
    getValues,
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      partA: medicareTypeA === "Y",
      partB: medicareTypeB === "Y",
      partC: medicareTypeC === "Y",
      partD: medicareTypeD === "Y",
      partatype: medicareTypeAStatus ?? "",
      partbtype: medicareTypeBStatus ?? "",
      partctype: medicareTypeCStatus ?? "",
      partdtype: medicareTypeDStatus ?? "",
      claimid: claimid ?? "",
      appId: applicationNum ?? "",
      premiumAmount: premiumAmount ?? "",
      dirtyStatus: createNew ? "insert" : "update",
      individualId: dummyIndividualId,
      id: editData?.id ?? null,
    },
  });

  const handleUpperCase = (value) => {
    const newValue = value.toUpperCase();
    return newValue;
  };

  const onSave = (values) => {
    showLoader();
    saveJson(medicarePayload(values, pageData))
      .then(() => {
        showSuccessToast("Successfully added the Medicare Details");
        retrieveJson();
        toggleMedicare();
      })
      .finally(() => {
        hideLoader();
      });
  };
  console.log(watch("partctype"));
  const isPartA = watch("partA");
  const isPartB = watch("partB");
  const isPartC = watch("partC");
  const isPartD = watch("partD");
  const ispartAtype = watch("partatype");
  const ispartBtype = watch("partbtype");
  const ispartCtype = watch("partctype");
  const ispartDtype = watch("partdtype");

  useEffect(() => {
    setIsAnyChecked(isPartA || isPartB || isPartC || isPartD);
  }, [isPartA, isPartB, isPartC, isPartD]);

  return (
    <>
      <Box
        sx={{
          border: "1px solid #e4e4e4",
          padding: "1rem",
          paddingBottom: "2rem",
        }}
      >
        <Typography
          variant="h4grey"
          sx={{
            paddingTop: "0.5rem",
          }}
        >
          {labels?.type ?? "Medicare Type"}
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            width: "70rem",
            gap: "2rem",
            flexWrap: "nowrap",
            paddingTop: "3rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <Grid item xs={6} border={2} borderColor={"silver"} borderRadius={2}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <HomeMed />
              <Typography>{labels?.HosIns ?? "Hospital Insurance"}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CCheckBox name="partA" label="Part A" control={control} />
            </div>
            {isPartA && (
              <div
                style={{
                  marginLeft: "4rem",
                  marginBottom: "3rem",
                }}
              >
                <Field
                  name="partatype"
                  label=""
                  helperText={errors.partatype?.message}
                  error={errors.partatype?.message}
                >
                  <CRadioButton
                    row
                    name="partatype"
                    control={control}
                    radioArray={[
                      {
                        label: "Receiving",
                        value: "R",
                      },
                      {
                        label: "Applied",
                        value: "A",
                      },
                    ]}
                  />
                </Field>
              </div>
            )}
          </Grid>
          <Grid item xs={6} border={2} borderColor={"silver"} borderRadius={2}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Stethoscope />
              <Typography>{labels?.mediIns ?? "Medical  Insurance"}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // marginLeft: '70px',
              }}
            >
              <CCheckBox name="partB" label="Part B" control={control} />
            </div>
            {isPartB && (
              <div
                style={{
                  marginLeft: "4rem",
                }}
              >
                <Field
                  name="partbtype"
                  label=""
                  helperText={errors.partbtype?.message}
                  error={errors.partbtype?.message}
                >
                  <CRadioButton
                    name="partbtype"
                    control={control}
                    radioArray={[
                      {
                        label: "Receiving",
                        value: "R",
                      },
                      {
                        label: "Applied",
                        value: "A",
                      },
                    ]}
                  />
                </Field>
              </div>
            )}
            {isPartB && (
              <div
                style={{
                  padding: "1rem",
                  width: "12rem",
                  marginLeft: "2rem",
                }}
              >
                <Field name="premiumAmount" label="Premium Amount">
                  <CoTextField
                    name="premiumAmount"
                    normalizers={[formatCurrency]}
                    control={control}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Field>
              </div>
            )}
          </Grid>
          <Grid item xs={6} border={2} borderColor={"silver"} borderRadius={2}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Combine />
              <Typography>{labels?.combine ?? " Combine A+B+D"}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // marginLeft: '70px',
              }}
            >
              <CCheckBox name="partC" label="Part C" control={control} />
            </div>
            {isPartC && (
              <div
                style={{
                  marginLeft: "4rem",
                }}
              >
                <Field
                  name="partctype"
                  label=""
                  helperText={errors.partctype?.message}
                  error={errors.partctype?.message}
                >
                  <CRadioButton
                    name="partctype"
                    control={control}
                    radioArray={[
                      {
                        label: "Receiving",
                        value: "R",
                      },
                      {
                        label: "Applied",
                        value: "A",
                      },
                    ]}
                  />
                </Field>
              </div>
            )}
          </Grid>
          <Grid item xs={6} border={2} borderColor={"silver"} borderRadius={2}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Pill />
              <Typography>{labels?.drugs ?? " Prescription drugs"}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // marginLeft: '70px',
              }}
            >
              <CCheckBox name="partD" label="Part D" control={control} />
            </div>
            {isPartD && (
              <div
                style={{
                  marginLeft: "4rem",
                }}
              >
                <Field
                  name="partdtype"
                  label=""
                  helperText={errors.partdtype?.message}
                  error={errors.partdtype?.message}
                >
                  <CRadioButton
                    name="partdtype"
                    control={control}
                    radioArray={[
                      {
                        label: "Receiving",
                        value: "R",
                      },
                      {
                        label: "Applied",
                        value: "A",
                      },
                    ]}
                  />
                </Field>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            paddingLeft: "0.5rem",
          }}
        >
          {((isPartA && ispartAtype === "R") ||
            (isPartB && ispartBtype === "R") ||
            (isPartC && ispartCtype === "R") ||
            (isPartD && ispartDtype === "R")) && (
            <Grid item xs={6} sx={{ marginTop: "1rem" }}>
              <Field
                name="claimid"
                label="Claim ID"
                required
                error={errors?.claimid?.message}
                showError={errors?.claimid?.message}
              >
                <Controller
                  name="claimid"
                  control={control}
                  render={({ field }) => (
                    <InputMask
                      {...register("claimid")}
                      mask="9aa9-aa9-aa99"
                      maskChar={null}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(handleUpperCase(e.target.value, e));
                      }}
                      onBlur={field.onBlur}
                    >
                      {(params) => (
                        <OutlinedInput
                          // {...register("claimid")}
                          variant="outlined"
                          placeholder="1AB7 TR6 MK78"
                          {...params}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Field>
            </Grid>
          )}
          {((isPartA && ispartAtype === "A") ||
            (isPartB && ispartBtype === "A") ||
            (isPartC && ispartCtype === "A") ||
            (isPartD && ispartDtype === "A")) && (
            <Grid item xs={6} mt="1rem">
              <Field name="appId" label="Application ID" required>
                <CoTextField
                  name="appId"
                  normalizers={[alphaNumeric, maxLength(11)]}
                  control={control}
                  helperText={errors.appId?.message}
                  error={errors.appId?.message}
                />
              </Field>
            </Grid>
          )}
        </Grid>
      </Box>

      <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} mt={2}>
        <Button variant="outlined" onClick={toggleMedicare}>
          Discard
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={!isAnyChecked}
          onClick={handleSubmit(onSave)}
        >
          Save
        </Button>
      </Stack>
    </>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import styles from "./index.module.css";
import SideNav from "../Layout/SideNav";
import NavBar from "../Layout/NavBar";
import AppDetailBar from "../../components/AppDetailBar";
import OutletWrapper from "../../components/OutletWrapper";
import usePageApi from "../../hooks/usePageApi";
import useMount from "../../hooks/useMount";
import { FETCH_NAV_OPTIONS } from "../../store/types/navTypes";
import { Box } from "@mui/material";

function ApplicationCont() {
  // const data = usePageApi({ screenId: 401 });

  useMount((dispatch) => {
    dispatch({ type: FETCH_NAV_OPTIONS });
  });

  return (
    <div className={styles.applicationCont}>
      {/* <Box sx={{ position: "sticky", top: 0 }}> */}
      <AppDetailBar />
      {/* </Box> */}
      <div className={styles.content}>
        <div className={styles.leftSection}>
          <SideNav />
        </div>
        <div className={styles.rightSection}>
          <OutletWrapper>
            <Outlet />
          </OutletWrapper>
        </div>
      </div>
    </div>
  );
}

ApplicationCont.propTypes = {};

export default ApplicationCont;

import React, { useEffect } from "react";
import useLookup from "../../../hooks/useLookup";
import styles from "./index.module.css";
import CCheckBox from "../../../components/checkBox";
import usePageApi from "../../../hooks/usePageApi";
import { useSelector } from "react-redux";
import { activeMembers } from "../../../store/selectors/appSelector";
import dayjs from "dayjs";
import { map, noop } from "lodash";
import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Stack,
} from "@mui/material";
import ScreenProvider from "../../../containers/ScreenProvider";
import Avatar from "../../../components/Avatar";
import { useForm, useFieldArray } from "react-hook-form";
import Dropdown from "../../../components/Dropdown";
import { Table, Button } from "@mui/material";
import { Box } from "@mui/material";
import { reationShipMemberdetails } from "../../../services/apiRequests";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import useLoader from "../../../hooks/useLoader";
import { showErrorToast } from "../../../utils/services/toast";

const RelationshipContent = ({ data, selected, options }) => {
  const { onDown = noop } = options;
  const controlId = useSelector(selecttActiveContId);
  const activeMembersList = useSelector(activeMembers);
  const { dummyIndividualId, gender = {} } = selected;
  const { labels, pageData, saveJson, retrieveJson, dirtyFields } = usePageApi({
    controlId: controlId,
    screenId: 201,
    screenDefn: "/dashboard/consumer-app/intake-app/members/relationship",
  });
  const [showLoader, hideLoader] = useLoader();
  const { control, handleSubmit, getValues, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      relationShips: [],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "relationShips",
  });

  useEffect(() => {
    setMemberDetails();
  }, [data, selected]);

  const setMemberDetails = async () => {
    if (selected) {
      showLoader();
      try {
        const res = await reationShipMemberdetails(
          dummyIndividualId,
          controlId,
          gender
        );
        if (res.status === 200) {
          const details = res.data?.relations ?? [];
          const filtered = data.filter(
            (obj) =>
              obj.dummyIndividualId !== selected.dummyIndividualId &&
              activeMembersList.includes(obj.dummyIndividualId)
          );
          const mappedFilter = filtered.map((obj) => {
            const filteredObj = details.find(
              (item) => item.individualId === obj.dummyIndividualId
            );
            if (
              filteredObj?.specifiedCaretakerYN ||
              filteredObj?.purchasePrepareTogetherYN
            ) {
              filteredObj["specifiedCaretakerYN"] =
                filteredObj?.specifiedCaretakerYN === "Y" ? true : false;
              filteredObj["purchasePrepareTogetherYN"] =
                filteredObj?.purchasePrepareTogetherYN === "Y" ? true : false;
            }

            return { ...obj, ...filteredObj };
          });
          setValue("relationShips", mappedFilter);
        }
      } catch (error) {
      } finally {
        hideLoader();
      }
    }
  };

  const handleSave = async (values) => {
    const isAllrelationMaped = values.relationShips?.every(
      (obj) => obj.relationshipCd
    );
    if (isAllrelationMaped) {
      const payload = [
        {
          dirtyStatus: "",
          importSelected: true,
          individualId: selected.dummyIndividualId,
          relations: map(getValues("relationShips"), (key) => {
            return {
              dirtyStatus: key.dirtyStatus === "insert" ? "UPDATE" : "INSERT",
              effectiveBeginDate: dayjs().format("YYYY-MM-DD"),
              effectiveEndDate: "9999-12-31",
              gender: "M",
              householdMemberId: null,
              individualId: key.dummyIndividualId,
              purchasePrepareTogetherYN: key.purchasePrepareTogetherYN
                ? "Y"
                : null,
              recordId: 0,
              relationshipCd: key.relationshipCd,
              specifiedCaretakerYN: key.specifiedCaretakerYN ? "Y" : null,
            };
          }),
          status: false,
        },
      ];

      const mergeData = !pageData ? payload : [...pageData, ...payload];
      const resFiltered = [];
      mergeData.forEach((abt) => {
        const index = resFiltered?.findIndex(
          (obj) => obj.individualId === abt.individualId
        );
        if (index >= 0) {
          resFiltered[index] = abt;
        } else resFiltered.push(abt);
      });

      try {
        showLoader();
        let res = await saveJson(resFiltered);
        if (res?.status === 200) {
          retrieveJson();
          onDown();
        }
      } catch (error) {
        showErrorToast(error);
      } finally {
        hideLoader();
      }
    }
  };

  const { getOptions, getLkpMatch } = useLookup("RelationshipInfo");
  const PrimaryLanguage = getOptions("IndividualRelationship");

  return (
    <>
      <ScreenProvider labels={labels}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bolder" }}>
                  Relationship
                </TableCell>
                <TableCell sx={{ fontWeight: "bolder" }}>Name</TableCell>
                <TableCell sx={{ fontWeight: "bolder" }}>Caretaker</TableCell>
                <TableCell sx={{ fontWeight: "bolder" }}>
                  SNAP Together / Prepare Together
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, ind) => {
                return (
                  <TableRow key={field.id}>
                    <TableCell>
                      <Box className={styles.realtionCell}>
                        <Dropdown
                          name={`relationShips[${ind}].relationshipCd`}
                          control={control}
                          options={PrimaryLanguage}
                          placeholder="Select relationship"
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box className={styles.realtionCell}>
                        <Avatar
                          name={`${field.firstName.charAt(0).toUpperCase()}
                          ""
                         ${field.lastName.charAt(0).toUpperCase()}`}
                        ></Avatar>
                        <Box className={styles.memberDetails}>
                          <Typography sx={{ fontWeight: "bolder" }}>
                            {field.firstName + " " + field.lastName}
                          </Typography>
                          <Typography>
                            {getLkpMatch("Gender", field.gender)}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <CCheckBox
                        name={`relationShips.${ind}.specifiedCaretakerYN`}
                        control={control}
                      />
                    </TableCell>
                    <TableCell>
                      <CCheckBox
                        name={`relationShips.${ind}.purchasePrepareTogetherYN`}
                        control={control}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row" justifyContent="flex-end" mt={2}>
          <Button
            onClick={handleSubmit(handleSave)}
            variant="contained"
            sx={{ padding: "0.7em 2.5em" }}
          >
            Save
          </Button>
        </Stack>
      </ScreenProvider>
    </>
  );
};

export default RelationshipContent;

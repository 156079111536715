export const checkEligible = (data) => {
  const {
    peoples = "",
    oldAge = "",
    physical = "",
    gross = "",
    income = "",
  } = data;
  return {
    numberOfHouseholdMember: peoples,
    olderThanSixty: oldAge === "Y" ? true : oldAge === "N" ? false : oldAge,
    disability: physical === "Y" ? true : physical === "N" ? false : physical,
    crossIncome: gross,
    type: income,
  };
};

import { all, fork } from "redux-saga/effects";
import watchAuth from "./authSaga";
import watchNavigations from "./navigationSaga";
import watchApp from "./appSaga";
import watchMembers from "./memberListSaga";

export default function* rootSaga() {
  yield all([
    fork(watchAuth),
    fork(watchNavigations),
    fork(watchApp),
    fork(watchMembers),
  ]);
}

import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  Grid,
  TextField,
  DialogActions,
} from "@mui/material";
import { useSelector } from "react-redux";
import { activeMembers } from "../../../store/selectors/appSelector";
import Field from "../../../components/Field";
import Dropdown from "../../../components/Dropdown";
import AuthRepRights from "../AuthRepContainer/AuthRepRights";
import CRadioButton from "../../../components/RadioButton";
import CInput from "../../../components/inputBox";
import useLookup from "../../../hooks/useLookup";
import { Controller, useForm } from "react-hook-form";
import CDatePicker from "../../../components/DatePicker";
import usePageApi from "../../../hooks/usePageApi";
import { authRepPayload } from "../../../services/apiPayload";
import { forEach } from "lodash";
import VendorSearch from "../../HouseholdInformation/AddressInfo/VendorSearch";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useLoader from "../../../hooks/useLoader";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../utils/services/toast";
import dayjs from "dayjs";
import CoTextField from "../../../controllers/CoTextField";
import {
  maxLength,
  number,
  onlyAlphabet,
  onlyAlphabetWithSpace,
} from "../../../utils/normalizers";

function AddAuthRep({
  open,
  onClose,
  selected,
  modifiedData,
  retrieveJson,
  memberData,
}) {
  const activeMembersId = useSelector(activeMembers);
  const [openModal, setOpenModal] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const { getOptions } = useLookup("AuthRep");
  const { relationshipOpts, typeOpts, stateOpts, accessTypeOptions } =
    useMemo(() => {
      return {
        relationshipOpts: getOptions("AuthRepRelation"),
        typeOpts: getOptions("AuthRepTypeCd"),
        stateOpts: getOptions("State"),
        accessTypeOptions: getOptions("AuthRepAccessType"),
      };
    }, [getOptions]);

  const { ScreenProvider, pageData, saveJson, labels } = usePageApi({
    screenId: 195,
    screenDefn: "/dashboard/consumer-app/intake-app/hoh/authRep",
  });

  const { pageData: hohInfo } = usePageApi({
    screenId: 195,
    screenDefn: "/dashboard/consumer-app/intake-app/hoh/hohInfo",
  });

  const hohIndividualId = hohInfo && hohInfo[0]?.individualId;

  const {
    authRepType,
    address,
    personName,
    relationshipCd,
    categoryType,
    individualId,
    authorizedRepresentative = {},
    authorizedRepresentativeDetails = [],
  } = selected?.authorizedRepresentative ?? {};

  const { dateOfBirth } = selected ?? {};

  const getMemberData = () => {
    let memData = [];
    forEach(memberData ?? [], (value) => {
      if (activeMembersId?.includes(value?.dummyIndividualId)) {
        memData.push({
          label: `${value?.firstName} ${value?.lastName}`,
          value: `${value?.firstName}-${value?.lastName}`,
        });
      }
    });
    return memData;
  };

  const validationSchema = Yup.object({
    // fName: Yup.string().required("First Name is required"),
    // lName: Yup.string().required("Last Name is required"),
    type: Yup.string().required("Type is required"),
    category: Yup.string().required("Please Select The Category"),
    relationship: Yup.string().required("Relationship is required"),
    add1: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("ZipCode is required"),
    fName: Yup.string().when("category", {
      is: (category) => {
        return category === "UI";
      },
      then: () => Yup.string().required("First Name is required."),

      otherwise: () => Yup.string().notRequired(),
    }),
    lName: Yup.string().when("category", {
      is: (category) => {
        return category === "UI";
      },
      then: () => Yup.string().required("Last Name is required."),

      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const {
    watch,
    control,
    getValues,
    setValue,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      isAuthRep: "",
      category: "",
      fName: "",
      type: "",
      city: "",
      state: "",
      authorizedRepresentativeDetails: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const onModalClose = () => {
    setOpenModal(false);
  };

  const onSave = async (data) => {
    const splitName = data?.hhMember?.split("-");
    const firstName = getValues("fName") ?? splitName[0];
    const lastName = getValues("lName") ?? splitName[1];
    const payload = {
      fName: firstName,
      mName: getValues("mName"),
      lName: lastName,
      relationship: getValues("relationship"),
      dateOfBirth: getValues("dob"),
      // dateOfBirth: dayjs(getValues("dob")).format("YYYY-MM-DD"),
      add1: getValues("add1"),
      add2: getValues("add2"),
      city: getValues("city"),
      state: getValues("state"),
      zipCode: getValues("zipCode"),
      zipCode4: getValues("zipCode4"),
      authRepType: getValues("type"),
      category: getValues("category"),
      hhMember: getValues("hhMember"),
      modifiedData,
      id: selected?.id ?? null,
      authorizedRepresentativeDetails: getValues(
        "authorizedRepresentativeDetails"
      ),
      hohIndividualId,
    };

    try {
      showLoader();
      const res = await saveJson(authRepPayload(payload));
      if (res.status === 200) {
        await retrieveJson();
        onClose();
        showSuccessToast("AuthRep added Successfully");
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formValues = watch();
  useEffect(() => {
    reset({
      ...formValues,
      city: address?.city,
      state: address?.state,
      add1: address?.addressLine1,
      add2: address?.addressLine2,
      zipCode: address?.zipcode,
      type: authRepType,
      category: categoryType,
      fName: personName?.firstName,
      lName: personName?.lastName,
      mName: personName?.middleName,
      relationship: relationshipCd,
      hhMember: `${personName?.firstName}-${personName?.lastName}`,
      authorizedRepresentativeDetails,
      dob: dateOfBirth ? dayjs(dateOfBirth) : "",
    });
  }, [selected]);

  const category = watch("category");

  useEffect(() => {
    watch("category") === "0" && setOpenModal(true);
  }, [category]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        // fullWidth
        maxWidth={"lg"}
        component="form"
        onSubmit={handleSubmit(onSave)}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              // width: "80vw",
              height: "80vh",
            },
          },
        }}
      >
        <DialogContent>
          {/* <Stack direction={"row"} justifyContent={"flex-end"}>
                <IconButton onClick={!AuthRep}>
                  <CloseRounded />
                </IconButton>
              </Stack> */}
          <Typography my="1rem" variant="h2">
            Authorized Representative
          </Typography>
          <Grid container xs={12} lg={10} spacing={2} p={2}>
            <Grid item xs={5} lg={5}>
              <Field
                name="type"
                label="Type"
                required
                error={errors?.type?.message}
                showError={errors?.type?.message}
              >
                <Dropdown
                  sx={{ mt: "1rem" }}
                  control={control}
                  name="type"
                  options={typeOpts}
                />
              </Field>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <AuthRepRights
                watch={watch}
                setValue={setValue}
                accessTypeOptions={accessTypeOptions}
              />
            </Grid>
            <Grid item xs={8}>
              {/* <Typography variant="subtitle1" sx={{ marginLeft: "1rem" }}>
                Category
              </Typography> */}
              <Stack direction="row" sx={{ marginLeft: "1rem" }}>
                <Field
                  name="category"
                  label="Category"
                  required
                  error={errors?.category?.message}
                  showError={errors?.category?.message}
                >
                  <CRadioButton
                    row
                    name="category"
                    control={control}
                    radioArray={[
                      {
                        label: "Provider",
                        value: 0,
                      },
                      {
                        label: "Non Case Member",
                        value: "UI",
                      },
                      ...(watch("type") === "FAM"
                        ? [
                            {
                              label: "Case Member",
                              value: "KI",
                            },
                          ]
                        : []),
                    ]}
                  />
                </Field>

                {watch("category") === "0" && (
                  <VendorSearch close={onModalClose} open={openModal} />
                )}
              </Stack>

              {watch("category") === "UI" && (
                <Grid container xs={12} lg={12} spacing={2} p={2}>
                  <Grid item xs={4} lg={4}>
                    <Field
                      name="fName"
                      label="First Name"
                      required
                      error={errors?.fName?.message}
                      showError={errors?.fName?.message}
                    >
                      <CoTextField
                        name="fName"
                        normalizers={[maxLength(20), onlyAlphabet]}
                        // {...register("fName")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={4} lg={4}>
                    <Field name="mName" label="Middle Name">
                      <CoTextField
                        name="mName"
                        normalizers={[maxLength(10), onlyAlphabet]}
                        // {...register("mName")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={4} lg={4}>
                    <Field
                      name="lName"
                      label="Last Name"
                      required
                      error={errors?.lName?.message}
                      showError={errors?.lName?.message}
                    >
                      <CoTextField
                        name="lName"
                        normalizers={[maxLength(20), onlyAlphabet]}
                        // {...register("lName")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field
                      name="relationship"
                      label="Relationship"
                      required
                      error={errors?.relationship?.message}
                      showError={errors?.relationship?.message}
                    >
                      <Dropdown
                        // sx={{ mt: "1rem" }}
                        control={control}
                        name="relationship"
                        // {...register("applicationLanguage")}
                        options={relationshipOpts}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field name="dob" label="DOB">
                      <CDatePicker
                        // sx={{ mt: "1rem" }}
                        control={control}
                        name="dob"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field
                      name="add1"
                      label="Address Line 1"
                      required
                      error={errors?.add1?.message}
                      showError={errors?.add1?.message}
                    >
                      <CoTextField
                        name="add1"
                        // {...register("add1")}
                        normalizers={[maxLength(50)]}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field name="add2" label="Address Line 2">
                      <CoTextField
                        name="add2"
                        normalizers={[maxLength(50)]}
                        // {...register("add2")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field
                      name="city"
                      label="City"
                      required
                      error={errors?.city?.message}
                      showError={errors?.city?.message}
                    >
                      <CoTextField
                        name="city"
                        normalizers={[maxLength(20), onlyAlphabetWithSpace]}
                        // {...register("city")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field
                      name="state"
                      label="State"
                      required
                      error={errors?.state?.message}
                      showError={errors?.state?.message}
                    >
                      <Dropdown
                        // sx={{ mt: "1rem" }}
                        control={control}
                        name="state"
                        // {...register("applicationLanguage")}
                        options={stateOpts}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field
                      name="zipCode"
                      label="Zip Code"
                      required
                      error={errors?.zipCode?.message}
                      showError={errors?.zipCode?.message}
                    >
                      <CoTextField
                        name="zipCode"
                        normalizers={[maxLength(5), number]}
                        // {...register("zipCode")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field name="zip4Code4" label="Zip +4 Code">
                      <CoTextField
                        name="zipcode4"
                        normalizers={[maxLength(4), number]}
                        // {...register("zipcode4")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                </Grid>
              )}

              {watch("category") === "KI" && (
                <Grid container xs={12} lg={12} spacing={2} p={2}>
                  <Grid item xs={6} lg={6}>
                    <Field name="hhMember" label="Household Member">
                      <Dropdown
                        control={control}
                        name="hhMember"
                        options={getMemberData()}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field name="C/O" label="Enter C/O">
                      <CInput name="C/O" control={control} />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field
                      name="relationship"
                      label="Relationship"
                      required
                      error={errors?.relationship?.message}
                      showError={errors?.relationship?.message}
                    >
                      <Dropdown
                        sx={{ mt: "1rem" }}
                        control={control}
                        name="relationship"
                        options={relationshipOpts}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field name="dob" label="DOB">
                      <CDatePicker
                        sx={{ mt: "1rem" }}
                        control={control}
                        name="dob"
                      />
                    </Field>
                  </Grid>

                  <Grid item xs={6} lg={6}>
                    <Field
                      name="add1"
                      label="Address Line 1"
                      required
                      error={errors?.add1?.message}
                      showError={errors?.add1?.message}
                    >
                      <CoTextField
                        name="add1"
                        normalizers={[maxLength(50)]}
                        // {...register("add1")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field name="add2" label="Address Line 2">
                      <CoTextField
                        name="add2"
                        normalizers={[maxLength(50)]}
                        // {...register("add2")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field
                      name="city"
                      label="City"
                      required
                      error={errors?.city?.message}
                      showError={errors?.city?.message}
                    >
                      <CoTextField
                        name="city"
                        normalizers={[maxLength(20), onlyAlphabetWithSpace]}
                        // {...register("city")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field
                      name="state"
                      label="State"
                      required
                      error={errors?.state?.message}
                      showError={errors?.state?.message}
                    >
                      <Dropdown
                        control={control}
                        name="state"
                        options={stateOpts}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field
                      name="zipCode"
                      label="Zip Code"
                      required
                      error={errors?.zipCode?.message}
                      showError={errors?.zipCode?.message}
                    >
                      <CoTextField
                        name="zipCode"
                        normalizers={[maxLength(5)]}
                        // {...register("zipCode")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Field name="zip4Code" label="Zip +4 Code">
                      <CoTextField
                        name="zipcode4"
                        normalizers={[maxLength(4)]}
                        // {...register("zipcode4")}
                        control={control}
                      />
                    </Field>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={onClose}>
              Discard
            </Button>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddAuthRep;

import React, { useState } from "react";
import {
  CardContent,
  CardMedia,
  Card,
  Grid,
  Typography,
  Button,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import styles from "./index.module.css";
import CashImage from "../../../assets/images/cash.jpg";
import SnapImage from "../../../assets/images/snap.jpg";
import MedicaidImage from "../../../assets/images/medicaid.jpg";
import UploadImage from "../../../assets/images/file-upload.png";
import TickImage from "../../../assets/images/tick-mark.jpg";
import ScheduleImage from "../../../assets/images/schedule.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AmIEligible from "../../AmIEligible";
import ImEligible from "../../Eligible";
import { CloseRounded } from "@mui/icons-material";

export default function CardDatas({ labels }) {
  const [open, setOpen] = useState(false);

  const toggleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h4" color="grey">
            {labels?.typeProgram ?? "Types of Programs"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4" color="grey">
            {labels?.tasksInterested ?? "What tasks are you intrested"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
          >
            <Card className={styles.cards} sx={{ width: "50%" }}>
              <CardMedia
                sx={{
                  height: 200,
                  width: "35%",
                  borderRadius: "8px",
                }}
                component="img"
                image={SnapImage}
              />
              <CardContent>
                <Typography variant="h4" color="grey">
                  {labels?.snap ?? " SNAP"}
                </Typography>
                <Typography variant="body1" color="grey">
                  {labels?.snapDetails ??
                    " SNAP provides food benefits to low-income families to supplement their grocery budget so they can afford the nutritious food essential to health and well-being."}
                </Typography>
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  sx={{ paddingTop: "2rem" }}
                >
                  <Typography variant="h4" color="#44A0E3">
                    {labels?.learnMore ?? "  Learn More"}
                  </Typography>
                  <ArrowForwardIcon />
                  {/* <img src={arrow_forward}></img> */}
                </Stack>
              </CardContent>
            </Card>
            <Card className={styles.cards} sx={{ width: "50%" }}>
              <CardMedia
                sx={{
                  height: 200,
                  width: "35%",
                  borderRadius: "8px",
                }}
                component="img"
                image={UploadImage}
              />
              <CardContent>
                <Typography variant="h4" color="grey">
                  {labels?.uploadDoc ?? "Upload a Document"}
                </Typography>
                <Typography variant="body1" color="grey">
                  {labels?.docDetails ??
                    "Upload files requested by your county worker that are not in your Upload Needed. Supports various format of PDF, JPG, and PNG"}
                </Typography>
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  sx={{ paddingTop: "2rem" }}
                >
                  <Button className={styles.btn} variant="contained">
                    {labels?.uploadDoc ?? " Upload Doc"}
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginBottom: "0.5rem" }}>
            <Card className={styles.cards} sx={{ width: "50%" }}>
              <CardMedia
                sx={{
                  height: 200,
                  width: "35%",
                  borderRadius: "8px",
                }}
                component="img"
                image={CashImage}
              />
              <CardContent>
                <Typography variant="h4" color="grey">
                  {labels?.cash ?? " CASH"}
                </Typography>
                <Typography variant="body1" color="grey">
                  {labels?.cashDetails ??
                    " If you have low income and limited resources, you may be able to receive cash assistance through Temporary Assistance for Needy Families (TANF) or General Assistance."}
                </Typography>
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  sx={{ paddingTop: "2rem" }}
                >
                  <Typography variant="h4" color="#44A0E3">
                    {labels?.learnMore ?? "  Learn More"}
                  </Typography>
                  <ArrowForwardIcon />
                  {/* <img src={arrow_forward}></img> */}
                </Stack>
              </CardContent>
            </Card>
            <Card className={styles.cards} sx={{ width: "50%" }}>
              <CardMedia
                sx={{
                  height: 200,
                  width: "35%",
                  borderRadius: "8px",
                }}
                component="img"
                image={TickImage}
              />
              <CardContent>
                <Typography variant="h4" color="grey">
                  {labels?.eligible ?? " Am I Eligible"}
                </Typography>
                <Typography variant="body1" color="grey">
                  {labels?.eligibleDetails ??
                    "You may qualify for free or low-cost health care through Medicaid based on your income and family size."}
                </Typography>
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  sx={{ paddingTop: "2rem" }}
                >
                  <Button
                    className={styles.btn}
                    variant="contained"
                    onClick={() => setOpen(true)}
                    // onClick={() => handleNavigate()}
                  >
                    Check Status
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginBottom: "0.5rem" }}>
            <Card className={styles.cards} sx={{ width: "50%" }}>
              <CardMedia
                sx={{
                  height: 200,
                  width: "35%",
                  borderRadius: "8px",
                }}
                component="img"
                image={MedicaidImage}
              />
              <CardContent>
                <Typography variant="h4" color="grey">
                  {labels?.medicaid ?? " MEDICAID"}
                </Typography>
                <Typography variant="body1" color="grey">
                  {labels?.mediDetails ??
                    " Medicaid is the primary program providing comprehensive coverage of health care and long-term services and supports to more than 90 million low-income people in the United States"}
                </Typography>
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  // sx={{ paddingTop: "1rem" }}
                >
                  <Typography variant="h4" color="#44A0E3">
                    {labels?.learnMore ?? "  Learn More"}
                  </Typography>
                  <ArrowForwardIcon />
                  {/* <img src={arrow_forward}></img> */}
                </Stack>
              </CardContent>
            </Card>
            <Card className={styles.cards} sx={{ width: "50%" }}>
              <CardMedia
                sx={{
                  height: 200,
                  width: "35%",
                  borderRadius: "8px",
                }}
                component="img"
                image={ScheduleImage}
              />
              <CardContent>
                <Typography variant="h4" color="grey">
                  {labels?.manageSchedule ?? " Manage Your Schedule"}
                </Typography>
                <Typography variant="body1" color="grey">
                  {labels?.manageDetails ??
                    " Real-time updates. Share with your family. Never miss caseworker appointments, View, Schedule, or reschedule your appointments"}
                </Typography>
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  sx={{ paddingTop: "2.2rem" }}
                >
                  <Button className={styles.btn} variant="contained">
                    {labels?.manageSchedule ?? "  Manage Schedule"}
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>
      {/* <AmIEligible open={open} setOpen={setOpen} /> */}
      <Dialog
        open={open}
        maxWidth="md"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "70vw",
              height: "90vh",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="h3">Am I eligible for benefits</Typography>
            <Typography variant="body2">
              Find out if you may be eligible in 10 seconds
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <ImEligible toggleClose={toggleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import usePageApi from "../../hooks/usePageApi";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CInput from "../../components/inputBox";
import Field from "../../components/Field";
import { useForm } from "react-hook-form";
import LoginImage from "../../assets/images/login-image.svg";
import CCheckBox from "../../components/checkBox";
import { ReactComponent as GLogo } from "../../assets/images/gLogo.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ReactComponent as MLogo } from "../../assets/images/mLogo.svg";
import usePageNav from "../../hooks/usePageNav";
import ScreenProvider from "../../containers/ScreenProvider";
import CoTextField from "../../controllers/CoTextField";
import { authenticateUser } from "../../store/sagas/authSaga";
import { useDispatch } from "react-redux";
import { setAuthToken } from "../../store/slices/authSlice";
import { showErrorToast, showSuccessToast } from "../../utils/services/toast";
import useLoader from "../../hooks/useLoader";
import { authApi, authApiCreate } from "../../utils/HttpClient";
function Login(props) {
  const { labels } = usePageApi({
    screenId: 427,
  });
  const dispatch = useDispatch();
  const [showLoader, hideLoader] = useLoader();
  const { navigations } = usePageNav();
  const [showPassword, setShowPassword] = useState(false);
  const [accessToken, setaccessToken] = useState("");
  const { watch, control, getValues, handleSubmit } = useForm({
    pass: "",
    email: "",
  });

  const login = async () => {
    const payload = {
      password: getValues("pass"),
      username: getValues("email"),
    };
    const responseToken = await authenticateUser(payload);
    if (responseToken) {
      dispatch(setAuthToken(responseToken));
      navigations.toDashboard();
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const register = () => {
    navigations.toRegistration();
  };

  const forgotPassowrd = async () => {
    const payload = {
      email: getValues("email"),
      username: getValues("email"),
    };
    if (payload.username) {
      try {
        showLoader();
        const responseToken = await authApiCreate.post(
          `/keycloak/${process.env.REACT_APP_REALMS}/updatePasswordRequiredAction`,
          payload
        );
        if (responseToken.status === 200) {
          showSuccessToast("Please check your mail for update password");
        }
      } catch (error) {
        showErrorToast("Server Error");
      } finally {
        hideLoader();
      }
    } else {
      showErrorToast("Enter Mail ID");
    }
  };

  return (
    <>
      <ScreenProvider labels={labels}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={10} sm={10} md={5} lg={4}>
              <Box
                sx={{
                  backgroundColor: "#44A0E3",
                  // borderRadius: "20px 0px 0px 20px",
                  borderRadius: {
                    xs: "20px 20px 0px 0px",
                    sm: "20px 20px 20px 20px",
                    lg: "20px 0px 0px 20px",
                    md: "20px 0px 0px 20px",
                  },
                  backgroundImage: `url(${LoginImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "bottom",
                  height: "100%",
                  boxShadow: 3,
                  WebkitBackgroundSize: "auto",
                }}
              >
                <Box
                  sx={{
                    paddingTop: "2rem",
                    paddingLeft: "2rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="h2" color="#fff">
                    Let us help you applying your scheme
                  </Typography>
                  <Typography variant="subtitle1" color="#fff">
                    Our process is quick and easy, to get a scheme from
                    Government to exact user
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={5}
              lg={4}
              sx={{
                padding: 2,
                // width: 500,
                // paddingBottom: 5,
                // borderRadius: "0px 20px 20px 0px",
                borderRadius: {
                  xs: "0px 0px 20px 20px",
                  sm: "20px 20px 20px 0px",
                  lg: "0px 20px 20px 0px",
                  md: "0px 20px 20px 0px",
                },
                boxShadow: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid container spacing={3} sx={{ justifyContent: "center" }}>
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h4grey">
                    Login to Your Account
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Field name="email" label="Email or Phone" required>
                    <CoTextField
                      name="email"
                      control={control}
                      placeholder={"Enter Maild Id or Mobile Number"}
                    />
                  </Field>
                </Grid>
                <Grid item xs={10}>
                  <Field name="pass" label="Password" required>
                    <CoTextField
                      name="pass"
                      placeholder="Enter your Password"
                      type={showPassword ? "text" : "password"}
                      // value={password}
                      // onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          login();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      control={control}
                    />
                  </Field>
                </Grid>
                <Grid item xs={10}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <CCheckBox
                      name="rememberMe"
                      label="Remember me"
                      control={control}
                    />
                    <Button onClick={forgotPassowrd}>
                      {labels?.forgotPassword ?? "Forget Password?"}
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={10}>
                  <Button
                    variant="contained"
                    onClick={() => login()}
                    sx={{
                      width: "100%",
                      padding: "15px",
                    }}
                  >
                    {labels?.login ?? " Login"}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    container
                    xs={10}
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item xs={5}>
                      <Divider
                        style={{ height: "0.3px", backgroundColor: "black" }}
                      />
                    </Grid>
                    <Grid item>
                      <span>{labels?.or ?? "Or"}</span>
                    </Grid>
                    <Grid item xs={5}>
                      <Divider
                        style={{ height: "0.3px", backgroundColor: "black" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  xs={10}
                  sx={{
                    justifyContent: "center",
                    gap: "1rem",
                    alignItems: "center",
                    paddingTop: "1rem",
                  }}
                >
                  <Grid item xs={4.5}>
                    <Link href="#">
                      <MLogo />
                    </Link>
                  </Grid>
                  <Grid item xs={4.5}>
                    <Link href="#">
                      <GLogo />
                    </Link>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h5" sx={{ color: "var(--grey-400)" }}>
                    {labels?.dontHaveAccount ?? "  Don't You Have an Account?"}
                    <span>
                      <Button variant="text" onClick={register}>
                        {labels?.signUp ?? "Sign Up"}
                      </Button>
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ScreenProvider>
    </>
  );
}

Login.propTypes = {};

export default Login;

import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    activeControlId: "",
    hohInfo: {},
    webReferenceId: "",
    screenStatuses: [],
    activeMembers: [],
  },
  reducers: {
    setActiveControlId(state, action) {
      state.activeControlId = action.payload;
    },
    setHohInfo(state, action) {
      state.hohInfo = action.payload;
    },
    setWebReferenceId(state, action) {
      state.webReferenceId = action.payload; //eg:WS00000924
    },
    setScreenStatuses(state, action) {
      state.screenStatuses = action.payload;
    },
    setActiveMembers(state, action) {
      state.activeMembers = action.payload;
    },
  },
});

export const {
  setActiveControlId,
  setActiveMembers,
  setHohInfo,
  setWebReferenceId,
  setScreenStatuses,
} = appSlice.actions;
export default appSlice.reducer;

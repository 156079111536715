import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SummaryContent from "../SummaryContent";
import TabularView from "../../../components/TabularView";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { sumBy } from "lodash";

function ShelterUti({ houseMembers, getLkpMatch, labels }) {
  const columns = useMemo(
    () => [
      {
        field: "",
        headerName: labels?.shelterExp ?? "Shelter Expense Total Amount",
        getValue({ shelterUtilityExpense = {} }) {
          return `${
            sumBy(
              shelterUtilityExpense.shelterExpenseTypes,
              "shelterExpenseAmount"
            ) ?? "-"
          } `;
        },
      },
      {
        field: "",
        headerName: labels?.utilExp ?? "Utility Expense Total Amount",
        getValue({ shelterUtilityExpense = {} }) {
          return `${
            sumBy(
              shelterUtilityExpense.shelterUtilityTypes,
              "utilityExpenseAmount"
            ) ?? "-"
          } `;
        },
      },

      {
        headerName: labels?.update ?? "Update",
        renderCell: (params) => (
          <IconButton>
            <Tooltip>
              <RestoreRoundedIcon />
            </Tooltip>
          </IconButton>
        ),
      },
    ],
    [houseMembers, getLkpMatch]
  );
  return (
    <SummaryContent title={labels?.shelterExp ?? "Shelter Utility"}>
      <TabularView
        sx={{
          table: {
            width: "100%",
          },
          td: {
            border: 0,
            padding: "0 1.25rem 0.5rem 0",
          },
          th: {
            border: 0,
            borderBottom: "0.06rem solid var(--grey-300)",
            padding: "0px 1.25rem 0.5rem 10px",
            // whiteSpace: "nowrap",
            // maxWidth: "150px",
            // overflow: "hidden",
            // textOverflow: "ellipsis",
            // display: "block",
          },
        }}
        headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
        cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
        columns={columns}
        data={houseMembers}
      />
    </SummaryContent>
  );
}

ShelterUti.propTypes = {};

export default ShelterUti;

import React, { useMemo, useState } from "react";
import {
  Button,
  Stack,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import usePageApi from "../../../hooks/usePageApi";
import MedicalExpModal from "../MedicalExpModal";
import ActionButtons from "../../../components/ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import useLookup from "../../../hooks/useLookup";
import { cloneDeep, filter, isEmpty, map } from "lodash";
import { GetAmountModelMedical } from "../constant";
import UploadFile from "../../../components/FileUpload";
import useLoader from "../../../hooks/useLoader";
import { showSuccessToast } from "../../../utils/services/toast";
import { styled } from "@mui/material/styles";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
// import ActionButtons from "../../../components/";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export default function MedicalExpTable({
  onSave,
  getValues,
  selected,
  getName,
}) {
  const [medExpOpen, setMedExpOpen] = useState(false);
  const [editMedExpOpen, setEditMedExpOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [apiData, setApiData] = useState({});
  const [deleteSingleData, setDeleteSingleData] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [docOpen, setDocOpen] = useState(false);
  const { getOptions, getLkpMatch } = useLookup("MedicalExpenses");
  const [showLoader, hideLoader] = useLoader();
  const selectedId = useSelector(selecttActiveContId);
  const { dummyIndividualId } = selected;
  const toggleMedicalExp = () => {
    setMedExpOpen(false);
    setEditData({});
  };

  // const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  //   "& ::-webkit-scrollbar": {
  //     width: "6px",
  //   },
  //   "& ::-webkit-scrollbar-track": {
  //     backgroundColor: "#f5f5f5",
  //   },
  //   "& ::-webkit-scrollbar-thumb": {
  //     borderRadius: "10px",
  //     boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
  //     backgroundColor: "#f5f5f5",
  //   },
  // }));

  const { medicalOptions } = useMemo(() => {
    return {
      medicalOptions: getOptions("Medical"),
    };
  }, [getOptions]);
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenDefn: "/dashboard/consumer-app/intake-app/fin/mediExpenses",
    screenId: 162,
  });
  useMemo(() => {
    if (pageData) {
      setApiData(pageData);
    }
  }, [pageData]);

  const columnData = [
    {
      field: "providerName",
      headerName: ` ${labels?.mediProvideName ?? "Medical Provider Name"}`,
      flex: 1,
      valueGetter: (params) => params?.row?.medicalExpenses?.providerName,
    },
    {
      field: "serviceProvided",
      headerName: ` ${labels?.serviceProv ?? "Service Provided"}`,
      flex: 1,
      renderCell: (params) =>
        `${getLkpMatch(
          "ServiceProvided",
          params?.row?.medicalExpenses?.serviceProvided
        )}`,
      valueGetter: (params) => params?.row?.medicalExpenses?.serviceProvided,
    },
    {
      field: "medicalExpenseType",
      headerName: ` ${labels?.expenseType ?? "Expense Type"}`,
      flex: 1,
      renderCell: (params) =>
        `${getLkpMatch(
          "MedicalExpenseType",
          params?.row?.medicalExpenses?.medicalExpenseType
        )}`,
      valueGetter: (params) => params?.row?.medicalExpenses?.medicalExpenseType,
    },

    {
      field: "actions",
      headerName: `${labels?.actions ?? "Actions"}`,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => editMedicare(params?.row)}
              onDelete={() => handleDeleteModal(params?.row)}
            />
          </div>
        );
      },
    },
  ];
  const handleDeleteModal = (data) => {
    let { id } = data;
    setDeleteSingleData(id);
    setIsDeleteOpen(true);
  };
  const editMedicare = (data) => {
    setMedExpOpen(true);
    setEditData(data);
  };
  const modificationData = map(apiData, (item, index) => ({
    ...item,
    id: index + 1,
  }));
  const filteredData = filter(modificationData, (value) => {
    return value.individualId === dummyIndividualId;
  });
  const handleDelete = async () => {
    try {
      showLoader();
      if (modificationData && modificationData.length > 0) {
        const payload = modificationData.filter(
          (item) => item.id !== deleteSingleData
        );
        const res = await saveJson(payload);
        if (res?.status === 200) {
          showSuccessToast("Successfully Deleted");
          retrieveJson();
          setDeleteSingleData("");
          setIsDeleteOpen(false);
        }
      }
    } catch (err) {
      console.log("ERROR", err);
    } finally {
      hideLoader();
    }
  };
  const handleSaveJson = async (data) => {
    showLoader();
    const payload = {
      screenDefn: "/dashboard/consumer-app/intake-app/fin/mediExpenses",
      individualId: dummyIndividualId,
      dirtyStatus: "INSERT",
      clearanceStatus: "",
      status: true,
      additionalInfo: "",
      medicalExpenses: {
        expense: [
          {
            frequency: data?.frequency,
            amountModel: [
              {
                label: "One Time Only Income",
                amount: data?.amount,
                recordId: 0,
                sortOrder: 1,
                dirtyStatus: "insert",
                verificationSourceCode: "",
              },
            ],
          },
        ],
        recordId: 0,
        dirtyStatus: "INSERT",
        retroSameAsCurrentYN: "N",
        providerName: data?.medicalProviderName,
        medicalExpenseType: data?.expense,
        serviceProvided: data?.service,
        medBPayer: "",
        medCPayer: "",
        medDPayer: "",
        effectiveEndDt: "9999-12-31",
        effectiveBeginDt: data?.date ?? null,
        medicalExpenseVerification: {
          verificationRequestedYN: "N",
          dirtyStatus: "INSERT",
          messages: [],
          verificationDocumentCd: "",
          docNames: {},
          documentDetails: [],
          verificationType: "",
          verificationSourceCode: "",
          verifiedYN: "",
          dueDate: null,
          automatedVerificationYN: "",
        },
        // expense: data?.frequency,
        retroMonths: [],
      },
      importSelected: true,
    };
    let model = GetAmountModelMedical(data);
    payload.medicalExpenses.expense[0].amountModel = model;
    let clonedPageData = cloneDeep(pageData || []);
    if (isEmpty(editData)) {
      //handling add data
      clonedPageData.push(payload);
    } else {
      //handling edit data
      clonedPageData = modificationData.map((item) => {
        if (item.id === editData.id) {
          return payload;
        }
        return item;
      });
    }
    const res = await saveJson(clonedPageData);
    if (res?.status == 200) {
      showSuccessToast("Succesfully added the Medical expenses");
      retrieveJson();
      setMedExpOpen(false);
    }
    hideLoader();
  };
  return (
    <>
      <Grid mt={2}>
        <Stack direction={"row"} mb={1} justifyContent="space-between">
          <Button
            variant="outlined"
            size="small"
            startIcon={<UploadFileIcon />}
            onClick={() => setDocOpen(true)}
          >
            {labels?.upload ?? " Upload files"}
          </Button>
          <Button onClick={() => setMedExpOpen(true)}>
            {labels?.addMedicare ?? "+ Add Medical Expenses"}
          </Button>
        </Stack>
        <DataGrid
          rows={filteredData}
          columns={columnData}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "1.1rem",
              fontWeight: "700",
              padding: "0rem 0.6rem",
              color: "#000000",
            },
            "& .MuiDataGrid-row": {
              fontSize: "1rem",
              color: "black",
              padding: "0rem 0.6rem",
              textAlign: "center",
            },
          }}
          slots={{
            noRowsOverlay: "noData",
          }}
        />
      </Grid>
      <Dialog maxWidth="lg" open={medExpOpen} onClose={toggleMedicalExp}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">
              {labels?.add + " " + labels?.medExpense ?? "Add Medical Expenses"}
            </Typography>
            <IconButton onClick={toggleMedicalExp}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <MedicalExpModal
            toggleMedicalExp={toggleMedicalExp}
            labels={labels}
            createMedicare
            onSave={onSave}
            pageData={pageData}
            getValues={getValues}
            saveJson={saveJson}
            handleSaveJson={handleSaveJson}
            editData={editData}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
          setDeleteSingleData("");
        }}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDeleteOpen(false);
              setDeleteSingleData("");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <UploadFile
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        activeIndex={dummyIndividualId}
        getName={getName}
        defaultDocType="ME"
        subTypeOptions={medicalOptions}
      />
    </>
  );
}

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SummaryContent from "../SummaryContent";
import TabularView from "../../../components/TabularView";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { Grid, IconButton, Tooltip } from "@mui/material";

function Assets({ houseMembers, getLkpMatch, labels }) {
  const columns = useMemo(
    () => [
      {
        flex: 1,
        field: "",
        headerName: labels?.type ?? "Type",
        getValue({ asset = {} }) {
          return `${
            asset.assetType === "1"
              ? "Liquid Asset"
              : asset.assetType === "2"
              ? "Insurance Policy"
              : asset.assetType === "3"
              ? "Motor Vechicle"
              : asset.assetType === "4"
              ? "Real Estate / Burial Plot"
              : asset.assetType === "5"
              ? "Other Property"
              : "-"
          } `;
        },
      },
      {
        flex: 1,
        field: "",
        headerName: labels?.fValue ?? "Face Val",
        getValue({ asset = {} }) {
          return `$ ${asset?.assetAmounts?.[0]?.faceAmount ?? "-"} `;
        },
      },
      {
        flex: 1,
        field: "",
        headerName: labels?.balanceVal ?? "Balance/Val",
        getValue({ asset = {} }) {
          return `$ ${asset?.assetAmounts?.[0]?.amount ?? "-"} `;
        },
      },
      {
        flex: 1,
        field: "",
        headerName: labels?.encumamount ?? "Encumbered Amt",
        getValue({ asset = {} }) {
          return `$ ${asset?.assetAmounts?.[0]?.encumberedAmount ?? "-"} `;
        },
      },
      {
        flex: 1,
        field: "",
        headerName: labels?.fmValue ?? "Fair Market Val",
        getValue({ asset = {} }) {
          return `$ ${asset?.assetAmounts?.[0]?.fareMarketValue ?? "-"} `;
        },
      },
      {
        flex: 1,
        field: "",
        headerName: labels?.cAmount ?? "Cash Amt",
        getValue({ asset = {} }) {
          return `$ ${asset?.assetAmounts?.[0]?.cashAmount ?? "-"} `;
        },
      },

      {
        flex: 1,
        headerName: labels?.update ?? "Update",
        renderCell: (params) => (
          <IconButton>
            <Tooltip>
              <RestoreRoundedIcon />
            </Tooltip>
          </IconButton>
        ),
      },
    ],
    [houseMembers, getLkpMatch]
  );
  return (
    <SummaryContent title={labels?.assets ?? "Assets"}>
      <TabularView
        sx={{
          table: {
            width: "100%",
          },

          td: {
            border: 0,
            padding: "0 1.25rem 0.5rem 0",
          },
          th: {
            border: 0,
            borderBottom: "0.06rem solid var(--grey-300)",
            padding: "0 1.25rem 0.5rem 0",
            alignContent: "start",
          },
        }}
        headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
        cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
        columns={columns}
        data={houseMembers}
      />
    </SummaryContent>
  );
}

Assets.propTypes = {};

export default Assets;

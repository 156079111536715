import { Grid, Typography, TextField, Box } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import Dropdown from "../../../components/Dropdown";
import { Controller, useForm } from "react-hook-form";
import PageContainer from "../../../components/PageContainer";
import CDatePicker from "../../../components/DatePicker";

import useLookup from "../../../hooks/useLookup";
import CFieldTitle from "../../../components/FieldTitle";
import usePageApi from "../../../hooks/usePageApi";
import YesNoOption from "../../../components/YesNoOption";
import Field from "../../../components/Field";
import usePageNav from "../../../hooks/usePageNav";
import CoTextField from "../../../controllers/CoTextField";
import {
  alphaNumeric,
  maxLength,
  number,
  onlyAlphabet,
  onlyAlphabetWithSpace,
} from "../../../utils/normalizers";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import ScreenProvider from "../../../containers/ScreenProvider";
import {
  zipCodes,
  mandatory,
  validCharacters,
  onlyAlphabtes,
} from "../../../ValidationRules";
import * as yup from "yup";
import { showErrorToast } from "../../../utils/services/toast";
import useLoader from "../../../hooks/useLoader";
import useDynamicNav from "../../../hooks/useDynamicNav";

function StudentInfo({ screenId }) {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const { getOptions } = useLookup("StudentInfo");
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: 413,
    screenDefn: "/application/hoh/student-info",
    callRetrieveStatus: true,
  });
  const [showLoader, hideLoader] = useLoader();
  const userShema = yup.object({
    isStudentYorN: mandatory("Student Status"),
    studentStatus: yup.string().when("isStudentYorN", {
      is: (isStudentYorN) => isStudentYorN === "Y",

      then: (userShema) => userShema.required("Please select student status"),
      otherwise: (userShema) => userShema.notRequired(),
    }),
  });
  const {
    watch,
    control,
    getValues,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(userShema),
    defaultValues: {
      isStudentYorN: "",
      studentStatus: "",
      educationLevel: "",
      lastGrade: "",
      schoolName: "",
      schoolNumber: "",
      gDate: "",
      goodstanding: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      zip4Code: "",
      ...pageData,
    },
  });

  const isStudentYorN = watch("isStudentYorN") === "Y";

  const {
    studentStatusOpts,
    educationLevelOpts,
    higherGradeOpts,
    goodStandingOpts,
    stateOpts,
  } = useMemo(() => {
    return {
      studentStatusOpts: getOptions("StudentStatusCode"),
      educationLevelOpts: getOptions("EducationalLevel"),
      higherGradeOpts: getOptions("HighestGrade"),
      goodStandingOpts: getOptions("GoodStandingSchoolAttendance"),
      stateOpts: getOptions("State"),
    };
  }, [getOptions]);

  const onSave = async () => {
    const payload = {
      studentInfo: {
        dirtyStatus: "UPDATE",
        recordId: 0,
        // effectiveBeginDate: "2023-07-31",
        // effectiveEndDate: "2023-10-01",
        studentStatus: getValues("studentStatus"),
        educationLevel: getValues("educationLevel"),
        highestGrade: getValues("lastGrade"),
        schoolName: getValues("schoolName"),
        schoolNumber: getValues("schoolNumber"),
        graduationDate: getValues("gDate") ?? null,
        goodStanding: getValues("goodstanding"),
        isStudentYorN: getValues("isStudentYorN"),
        studentStatusVerification: {
          retryCount: 0,
          verificationSourceCode: "NO",
        },
        goodStandingVerification: {
          retryCount: 0,
          verificationSourceCode: "NO",
        },
        graduationDateVerification: {
          retryCount: 0,
          verificationSourceCode: "NO",
        },
        schoolAddress: {
          vendorId: 0,
          status: true,
          addressLine1: getValues("addressLine1"),
          addressLine2: getValues("addressLine2"),
          city: getValues("city"),
          state: getValues("state"),
          zipcode: getValues("zipCode"),
          zip4code: getValues("zip4code"),
        },
        groupNumber: null,
        screenDefn: "/application/hoh/student-info",
        screenId: 413,
      },
    };
    try {
      showLoader();
      const res = await saveJson(payload);
      if (res?.status) {
        handleNext();
        // navigations.toCurrentAddress();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const onBack = () => {
    navigations.toRaceAndDisability();
  };
  const data = pageData?.studentInfo;

  useEffect(() => {
    if (data) {
      setValue("isStudentYorN", data.isStudentYorN);
      setValue("educationLevel", data.educationLevel);
      setValue("schoolName", data.schoolName);
      setValue("schoolNumber", data.schoolNumber);
      setValue("gDate", data.graduationDate ? dayjs(data.graduationDate) : "");
      setValue("goodstanding", data.goodStanding);
      setValue("lastGrade", data.highestGrade);
      setValue("addressLine1", data.schoolAddress.addressLine1);
      setValue("addressLine2", data.schoolAddress.addressLine2);
      setValue("city", data.schoolAddress.city);
      setValue("state", data.schoolAddress.state);
      setValue("zipCode", data.schoolAddress.zipcode);
      setValue("zip4code", data.schoolAddress.zip4code);
      setValue("studentStatus", data.studentStatus);
    }
  }, [data]);

  return (
    <ScreenProvider labels={labels}>
      <Box sx={{ paddingLeft: "1rem" }}>
        <PageContainer
          title="Student Information"
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Box sx={{ paddingTop: "1rem" }}>
            <Field
              name="areUStudent"
              error={errors.isStudentYorN?.message}
              showError={errors.isStudentYorN?.message}
              label="Are you a student?"
            >
              <Controller
                name="isStudentYorN"
                control={control}
                render={({ field }) => {
                  return <YesNoOption {...field} />;
                }}
              />
            </Field>
          </Box>

          {isStudentYorN && (
            <>
              <Grid item xs={12} spacing={2} sx={{ padding: "1rem" }}>
                <Field
                  name="selectStatus"
                  label="Select Status"
                  error={errors.studentStatus?.message}
                  showError={errors.studentStatus?.message}
                  titleVariant="h4"
                  required
                >
                  <Dropdown
                    control={control}
                    name="studentStatus"
                    {...register("studentStatus")}
                    options={studentStatusOpts}
                  />
                </Field>
              </Grid>
              <CFieldTitle label="School Information"></CFieldTitle>
              <Grid container spacing={2} sx={{ padding: "1rem" }}>
                <Grid item xs={6}>
                  <Field
                    name="levelOfEducation"
                    label="Level of Education"
                    titleVariant="h4"
                  >
                    <Dropdown
                      control={control}
                      name="educationLevel"
                      options={educationLevelOpts}
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="lastGradeCompleted"
                    label="Last Grade Completed"
                    titleVariant="h4"
                  >
                    <Dropdown
                      control={control}
                      name="lastGrade"
                      options={higherGradeOpts}
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="schoolName"
                    label="School Name"
                    titleVariant="h4"
                  >
                    <CoTextField
                      normalizers={[maxLength(20), onlyAlphabet]}
                      name="schoolName"
                      placeholder="Enter School Name"
                      control={control}
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="schoolNumber"
                    error={errors.schoolNumber?.message}
                    showError={errors.schoolNumber?.message}
                    label="School Number"
                    titleVariant="h4"
                  >
                    <CoTextField
                      normalizers={[maxLength(3), number]}
                      name="schoolNumber"
                      control={control}
                      placeholder="Enter School Number"
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="graduationDate"
                    label="Graduation Date"
                    titleVariant="h4"
                  >
                    <CDatePicker
                      {...register("gDate")}
                      control={control}
                      name="gDate"
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="goodStanding"
                    label="Good Standing"
                    titleVariant="h4"
                  >
                    <Dropdown
                      control={control}
                      name="goodstanding"
                      options={goodStandingOpts}
                    />
                  </Field>
                </Grid>
              </Grid>
              <CFieldTitle label="Address Of School"></CFieldTitle>
              <Grid container spacing={2} sx={{ padding: "1rem" }}>
                <Grid item xs={6}>
                  <Field
                    name="addressLine1"
                    label="Address Line 1"
                    titleVariant="h4"
                  >
                    <CoTextField
                      normalizers={[maxLength(35), alphaNumeric]}
                      name="addressLine1"
                      control={control}
                      placeholder="Enter Address"
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="addressLine2"
                    label="Address Line 2"
                    titleVariant="h4"
                  >
                    <CoTextField
                      normalizers={[maxLength(20), alphaNumeric]}
                      name="addressLine2"
                      control={control}
                      placeholder="Enter Address"
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field name="city" label="City" titleVariant="h4">
                    <CoTextField
                      normalizers={[maxLength(20), onlyAlphabetWithSpace]}
                      name="City"
                      control={control}
                      placeholder="Enter City"
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field name="state" label="State" titleVariant="h4">
                    <Dropdown
                      control={control}
                      name="state"
                      options={stateOpts}
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field name="zipcode" label="Zip Code" titleVariant="h4">
                    <CoTextField
                      normalizers={[maxLength(5), number]}
                      name="zipCode"
                      control={control}
                      placeholder="Enter Zip Code"
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field name="zip4code" label="Zip +4 Code" titleVariant="h4">
                    <CoTextField
                      normalizers={[maxLength(4), number]}
                      name="zip4Code"
                      control={control}
                      placeholder="Enter Zip Code"
                    />
                  </Field>
                </Grid>
              </Grid>
            </>
          )}
        </PageContainer>
      </Box>
    </ScreenProvider>
  );
}

export default StudentInfo;

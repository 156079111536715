export const memberPayload = (data) => {
  const { pageDataMem, pageDataRace, pageDataStud, pageDataCont, pageDataAdd } =
    data ?? {};
  const { personName, gender, dob, maritalStatus } =
    pageDataMem?.clientContainer?.client ?? {};
  const {
    race,
    ethnicity,
    hearingImpairedYN,
    physicallyImpairedYN,
    visuallyImpairedYN,
  } = pageDataRace;
  const { contactInformation } = pageDataAdd?.[0]?.contactInformationDetails;
  //   const {phoneNumber} = pageDataCont?.[0]?.
  const { homeAddress, email } = contactInformation ?? {};
  const { addressLine1, addressLine2, city, state, zipcode } =
    homeAddress ?? {};
  const { firstName, lastName, suffix } = personName ?? {};
  const { marriedYN } = maritalStatus ?? {};
  //   const { addressLine1, addressLine2, city, state } = schoolAddress ?? {}
  const { aboutMember } = pageDataStud?.clientContainer?.client ?? {};
  const { applyingFor, isLiving } = aboutMember ?? {};
  const {
    goodStanding,
    graduationDate,
    educationLevel,
    highestGrade,
    schoolName,
    schoolNumber,
    studentStatus,
    studentYN,
    schoolAddress,
  } = pageDataStud?.studentInfo ?? {};

  //   const {contactInformation} = pageDataCont?.

  return {
    dirtyStatus: "UPDATE",
    individualId: "",
    clearanceStatus: "N",
    controlId: "",
    status: true,
    importSelected: true,
    editable: false,
    realIndividualId: "",
    clientContainer: {
      navigationFlowRequested: false,
      paginationData: {
        resultsPerPage: 5,
        pageNumber: 1,
        totalRecords: 0,
        pageBuffer: 0,
      },
      sourceSystem: "C",
      contactInformation: {
        sameMailingAddressYN: true,
        preferredModeOfCommunication: "",
        preferredContactTime: null,
        entryDate: null,
        returnYN: null,
        futureReturnYN: null,
        homeAddress: {
          vendorId: 0,
          status: true,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          city: city,
          state: state,
          zipcode: zipcode,
          zip4code: "",
          county: "",
        },
        mailingAddress: {
          vendorId: 0,
          status: true,
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipcode: "",
          zip4code: "",
          county: "",
        },
        phoneNumber: {
          phoneNumberId: null,
          phoneNumberStartDate: null,
          phoneNumberEndDate: null,
          phoneNumber: "",
          phoneNumberExt: "",
          phoneNumberType: "",
        },
        altPhoneNumber: {
          phoneNumberId: null,
          phoneNumberStartDate: null,
          phoneNumberEndDate: null,
          phoneNumberExt: "",
          phoneNumberType: "",
        },
        phoneNumberDetails: {
          phoneNumber: "",
          phoneNumberExtension: "",
          phoneNumberTypeCd: "",
          altPhoneNumber: "",
          altPhoneNumberExtension: "",
          altPhoneNumberTypeCd: "",
        },
        phoneNumberDetailsHstry: [],
      },
      client: {
        personName: {
          recordId: 0,
          firstName: firstName,
          lastName: lastName,
          suffix: suffix,
        },
        dob: dob,
        soSec: {
          recordId: 0,
          soSecPart1: "",
          soSecPart2: "",
          soSecPart3: "",
          ssnVerification: {
            dirtyStatus: "",
            retryCount: 0,
            messages: [],
            verificationDocumentCd: "",
            verificationRequestedYN: "",
            docNames: {},
            automatedVerificationYN: "",
            documentDetails: [],
            verificationType: "",
            verifiedYN: "",
            verificationSourceCode: "",
          },
          groupNumber: 0,
        },
        gender: gender,
        race: {
          retryCount: 0,
          recordId: 0,
          primaryRace: race,
          otherRaces: [""],
          groupNumber: 0,
        },
        headOfHouseholdYN: "",
        relationshipCd: "",
        maritalStatus: {
          retryCount: 0,
          marriedYN: marriedYN,
          recordId: 0,
          groupNumber: 0,
        },
        ethnicity: ethnicity,
        pregnantYN: "",
        hasSsnYN: "",
        individualDetails: {
          deathDetails: {
            retryCount: 0,
            state: "",
            deathCertificateNumber: "",
          },
          pregnancy: [{}],
          ethnicity: ethnicity,
        },
        disabilities: [],
        earnedIncomes: [],
        unearnedIncomes: [],
        dependentCareExpenses: [],
        unearnedIncomeApplications: [],
        shelterExpenses: [],
        shelterUtilityExpenses: [],
        utilityExpenses: [],
        disabledYN: physicallyImpairedYN,
        blindYN: visuallyImpairedYN,
        deafYN: hearingImpairedYN,
        dobVerification: {
          dirtyStatus: "",
          retryCount: 0,
          messages: [],
          verificationDocumentCd: "",
          verificationRequestedYN: "",
          docNames: {},
          automatedVerificationYN: "",
          documentDetails: [],
          verificationType: "",
          verifiedYN: "",
          verificationSourceCode: "",
        },
        aboutMember: {
          isLiving: isLiving,
          applyingFor: applyingFor,
        },
        fortyQuartersInd: false,
        address: {
          vendorId: 0,
          status: true,
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipcode: "",
          zip4code: "",
          county: "",
        },
        createMdmId: true,
      },
    },
    screenDefn: "/dashboard/consumer-app/intake-app/members/memberInfo",
    alertProceedYN: "",
    alertMessage:
      " Failure to provide your SSN may delay the processing of your application.",
    memberCreatedInWp: "",
    caseId: "",
    populateExistingData: "",
    intakeProgramSelections: [
      {
        dirtyStatus: "",
        individualId: "",
        clearanceStatus: "",
        controlId: null,
        status: false,
        importSelected: true,
        editable: true,
        importedFromWp: "",
        programSelected: {
          dirtyStatus: " insert",
          programId: 0,
          programName: "",
          programSubType: "",
          programStatusCode: "",
          applicationDate: "",
          finalizeProgramIndicator: "",
          selectedIndicator: "",
          waiverIndicator: "",
          programMode: "",
          confirmProgramIndicator: "",
        },
      },
    ],
    editSsn: true,
    studentInfo: {
      studentYN: studentYN,
      effectiveBeginDate: "",
      effectiveEndDate: "",
      studentStatus: studentStatus,
      educationLevel: educationLevel,
      highestGrade: highestGrade,
      schoolName: schoolName,
      schoolNumber: schoolNumber,
      graduationDate: graduationDate,
      goodStanding: goodStanding,
      studentStatusVerification: {
        retryCount: 0,
        verificationSourceCode: null,
      },
      goodStandingVerification: {
        retryCount: 0,
        verificationSourceCode: null,
      },
      graduationDateVerification: {
        retryCount: 0,
        verificationSourceCode: null,
      },
      schoolAddress: {
        vendorId: 0,
        status: true,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: "",
        state: "",
        zipcode: "",
        zip4code: "",
      },
      groupNumber: 0,
    },
  };
};

export const hohDetails = (data) => {
  return {
    screenDefn: [
      "/dashboard/consumer-app/intake-app/hoh/hohInfo",
      "/dashboard/consumer-app/interim/currentAddress",
      "/dashboard/consumer-app/intake-app/hoh/disabilityInfo",
    ],
    controlId: data,
  };
};

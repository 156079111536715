import { map } from "lodash";
import dayjs from "dayjs";

export const institutionPayload = (data) => {
  //   const {} = data;
  return {
    search: {
      dirtyStatus: "",
      caseId: "",
      vendorCommonName: "",
      effectiveBeginDt: null,
      effectiveEndDt: null,
      corpYN: null,
      vendorTypeCd: "",
      federalTaxNumber: "",
      workerId: null,
      ldssId: "",
      location: "",
      vendorAddressBeginDt: null,
      vendorLine1Address: null,
      vendorState: data,
      vendorStreetAddress: null,
      vendorRateBeginDt: null,
      rateCd: "",
      vendorStatusBeginDt: null,
      statusCode: null,
      statusReasonCode: "",
      sourceSystem: "C",
    },
    results: [],
    paginationData: { resultsPerPage: 1000, pageNumber: 1 },
    range: null,
    systemCd: "C",
  };
};
export const relationshipMember = (gender, controlId) => {
  return {
    controlId: controlId,
    gender: gender,
    screenDefn: "/dashboard/consumer-app/intake-app/members/relationship",
  };
};
export const fileUploader = (
  file,
  documentTypeCd,
  custIndiviualId,
  lookupShortVal
) => {
  return {
    fileFormData: file ?? null,
    documentTypeCd: documentTypeCd,
    custIndiviualId: custIndiviualId,
    lookupShortVal: lookupShortVal,
    verificationName: null,
    role: "EE_SUPVRS,EE_SUPUSR,EE_VEND_ADMN",
    user: "7c438d573165cdd1552231ed3bdd33",
    sourceCaseId: "",
    sourceSystem: "EEAPI",
  };
};
export const smartyPayload = (data) => {
  return {
    addressSearches: [
      {
        search: {
          ...data,
        },
      },
    ],
  };
};
export const applyBenefits = (customeId, benefits) => {
  console.log(customeId, benefits);
  return benefits.map((dataObj) => {
    return {
      individualId: "",
      dirtyStatus: "INSERT",
      importedFromWp: "",
      clearanceStatus: "",
      controlId: customeId,
      importSelected: true,
      editable: true,
      programSelected: {
        programId: 0,
        dirtyStatus: "insert",
        programName: dataObj,
        programStatusCode: "",
        applicationDate: dayjs().format("YYYY-MM-DD"),
        selectedIndicator: "Y",
        programSubType: "",
        programMode: "",
        waiverIndicator: "",
        confirmProgramIndicator: "",
        finalizeProgramIndicator: "",
      },
    };
  });
};
export const getControlID = () => {
  return {
    appCd: "AP",
    benefitsCaseId: 0,
    caseId: 0,
    cboId: 0,
    screenDefinition:
      "/dashboard/consumer-app/intake-app/assistance/progSelect",
    src: "Customer",
    vendorId: 0,
  };
};
export const contactPayload = (data) => {
  const {
    phoneNumber = "",
    phoneNumberExtension = "",
    phoneNumberTypeCd = "",
    altPhoneNumber = "",
    altPhoneNumberExtension = "",
    altPhoneNumberType = "",
    email = "",
    alemail = "",
    preferredModeOfCommunication = "",
  } = data;
  return [
    {
      individualId: "",
      controlId: null,
      screenId: 149,
      screenDefn: "/dashboard/consumer-app/intake-app/hoh/contactInfo",
      dirtyStatus: "UPDATE",
      importSelected: true,
      contactInformationDetails: {
        contactInformation: {
          preferredModeOfCommunication: preferredModeOfCommunication,
          phoneNumberDetails: {
            phoneNumber: phoneNumber,
            altPhoneNumber: altPhoneNumber,
            phoneNumberTypeCd: phoneNumberTypeCd,
            phoneNumberExtension: phoneNumberExtension,
            altPhoneNumberExtension: altPhoneNumberExtension,
            altPhoneNumberType: altPhoneNumberType,
            altEmailId: alemail,
          },

          phoneNumber: {
            dirtyStatus: "",
            phoneNumber: phoneNumber,
            phoneNumberType: phoneNumberTypeCd,
            phoneNumberExt: phoneNumberExtension,
            phoneNumberStartDate: null,
            phoneNumberEndDate: null,
          },
          altPhoneNumber: {
            dirtyStatus: "",
            phoneNumber: altPhoneNumber,
            phoneNumberExt: altPhoneNumberExtension,
            phoneNumberType: altPhoneNumberType,
            phoneNumberStartDate: null,
            phoneNumberEndDate: null,
          },
          email: email,
        },
      },
    },
  ];
};

export const childSupport = (data) => {
  const {
    effectiveBeginDate = "",
    effectiveEndDate = "",
    amount = "",
    sortOrder = "",
    label = "",
    frequency = "",
    suprTypeCd = "",
    pay1 = "",
    pay2 = "",
    modificationData,
    dummyIndividualId,
    dirty,
    id,
  } = data;
  const createPayload = {
    status: true,
    individualId: dummyIndividualId,
    caseId: "",
    dirtyStatus: dirty,
    childSpousalExpenses: {
      recordId: 0,
      dirtyStatus: dirty,
      effectiveBeginDate: effectiveBeginDate ?? null,
      effectiveEndDate: effectiveEndDate ?? null,
      suprTypeCd: suprTypeCd,
      expense: [
        {
          frequency: frequency,
          amountModel: [
            {
              recordId: 0,
              dirtyStatus: dirty,
              verificationSourceCode: "",
              amount: pay1,
              sortOrder: sortOrder,
              label: "Pay 1",
            },
            {
              recordId: 0,
              dirtyStatus: dirty,
              verificationSourceCode: "",
              amount: pay2,
              sortOrder: sortOrder,
              label: "Pay 2",
            },
          ],
        },
      ],
      childSpslExpenseVerification: {
        dirtyStatus: dirty,
        verificationDocumentCd: "",
        verificationRequestedYN: "N",
        verificationType: "",
        verificationSourceCode: "NO",
        verifiedYN: "",
        dueDate: null,
        automatedVerificationYN: "N",
      },
    },
    importSelected: true,
  };
  console.log(modificationData, "modificationData");
  if (id) {
    const updatePayload = map(modificationData, (val) => {
      if (id === val.id) {
        return createPayload;
      } else {
        return val;
      }
    });
    console.log(updatePayload);

    return updatePayload;
  } else {
    if (!modificationData) {
      return [createPayload];
    } else {
      return [...modificationData, createPayload];
    }
  }
};

export const addressPayload = (data) => {
  const {
    addressId = "",
    homeAddressYN = "",
    institutionYN = "",
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    zipcode = "",
    zip4code = "",
    state = "",
    county = "",
    district = "",
    addressVerifiedYN = "",
    asSame = "",
    mailline1 = "",
    mailline2 = "",
    mailcity = "",
    mailstate = "",
    mailzipCode = "",
    mailzipCodePluse = "",
    controlId,
  } = data;

  const mailingAddress = {
    dirtyStatus: "UPDATE",
    addressId: 0,
    homeAddressYN: "Y",
    institutionYN: "N",
    addressLine1: asSame === "Y" ? addressLine1 : mailline1,
    addressLine2: asSame === "Y" ? addressLine2 : mailline2,
    city: asSame === "Y" ? city : mailcity,
    zipcode: asSame === "Y" ? zipcode : mailzipCode,
    zip4code: asSame === "Y" ? zip4code : mailzipCodePluse,
    state: asSame === "Y" ? state : mailstate,
    county: county,
    district: "",
    addressVerifiedYN: "N",
  };

  return [
    {
      status: true,
      individualId: "",
      controlId: controlId ?? "",
      screenId: 131,
      screenDefn: "/dashboard/consumer-app/interim/currentAddress",
      dirtyStatus: "UPDATE",
      contactInformationDetails: {
        additionalInformation: {
          preferredLanguage: "E",
          otherLanguage: "",
          interpreterNeededYN: "N",
          visuallyImpairedYN: "N",
          hearingImpairedYN: "N",
          physicallyImpairedYN: "N",
          hearingImpairedType: "",
        },
        homeInformation: { homeType: "", livingArrangement: "" },
        contactInformation: {
          homeAddress: {
            dirtyStatus: "INSERT",
            addressId: 0,
            homeAddressYN: homeAddressYN,
            institutionYN: institutionYN,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            zipcode: zipcode,
            zip4code: zip4code,
            state: state,
            county: county,
            district: "",
            addressVerifiedYN: addressVerifiedYN,
          },
          sameMailingAddressYN: asSame,
          previousAddresses: null,
          preferredModeOfCommunication: "2",
          preferredContactTime: "",
          email: "",
          entryDate: null,
          returnYN: "",
          futureReturnYN: "",
          mailingAddress: mailingAddress,
        },
      },
      importSelected: true,
      ldssDoCode: "DO",
    },
  ];
};

export const assetsPayload = (data) => {
  const {
    typeInsurance = "",
    policyNo = "",
    cashAmt = "",
    faceVal = "",
    insuranceName = "",
    Owned = "",
    terms = "",
    percentOfShare = "",
    individualId,
    accNo = null,
    assetAmount = null,
    dirty,
    modificationData,
    assetType,
    vehicleUse,
    year,
    license,
    make,
    model,
    register,
    stateMotor,
    encumamount,
    fmValue,
    vin,
    estateUse,
    line1,
    line2,
    city,
    zipCode,
    state,
    trySell,
    id,
  } = data;

  const createPayload = {
    asset: {
      use: estateUse,
      vin: vin,
      fdic: "",
      make: make,
      name: "",
      year: year,
      model: model,
      state: stateMotor,
      amount: 0,
      address: {
        city: city,
        state: state,
        zipcode: zipCode,
        addressLine1: line1,
        addressLine2: line2,
      },
      termInd: terms,
      recordId: 0,
      totalAmt: 0,
      assetType: assetType,
      locationId: 0,
      vehicalUse: vehicleUse,
      countableYN: "",
      dirtyStatus: dirty,
      assetAmounts: [
        {
          amount: assetAmount,
          recordId: null,
          cashAmount: cashAmt,
          faceAmount: faceVal,
          dirtyStatus: dirty,
          fareMarketValue: fmValue,
          effectiveEndDate: null,
          encumberedAmount: encumamount,
          amountVerification: null,
          effectiveBeginDate: null,
          amountReceivedVerification: null,
          fareMarketValueVerification: null,
          encumberedAmountVerification: null,
        },
      ],
      jointOwnerYN: Owned,
      policyNumber: policyNo,
      registration: register,
      transferDate: null,
      accountNumber: accNo,
      discoveryDate: null,
      institutionId: 0,
      licenseNumber: license,
      amountReceived: null,
      otherAssetName: "",
      percentOfShare: percentOfShare,
      assetDetailType: typeInsurance,
      institutionName: insuranceName,
      effectiveEndDate: null,
      reasonOfTransfer: "",
      usedForPenaltyYN: "",
      assetVerification: {
        verifiedYN: "N",
        verificationType: "",
        verificationDocumentCd: "",
        verificationSourceCode: "NO",
        automatedVerificationYN: "",
        verificationRequestedYN: "N",
      },
      effectiveBeginDate: null,
      undueHardshipReason: "",
      financialStateSearch: "MD",
      liceneseAgreementDate: null,
      intentBurialExpensesYN: "",
      transfereeRelationship: "",
      ownedByHouseholdMemberYN: "",
    },
    status: true,
    dirtyStatus: dirty,
    individualId: individualId,
    importSelected: true,
  };
  console.log(id);
  console.log(modificationData);

  if (id) {
    const updatePayload = map(modificationData, (val) => {
      if (id === val.id) {
        return createPayload;
      } else {
        return val;
      }
    });
    console.log(updatePayload);

    return updatePayload;
  } else {
    if (!modificationData) {
      return [createPayload];
    } else {
      return [...modificationData, createPayload];
    }
  }
};

export const authRepPayload = (data) => {
  const {
    fName,
    lName,
    mName,
    relationship,
    add1,
    add2,
    city,
    state,
    zipCode,
    zipCode4,
    authRepType,
    category,
    hhMember,
    modifiedData,
    authorizedRepresentativeDetails,
    id,
    hohIndividualId,
    dateOfBirth,
  } = data;

  const authorizedRepPayload = {
    individualId: hohIndividualId,
    dateOfBirth: dateOfBirth,
    dirtyStatus: "insert",
    status: true,
    authorizedRepresentative: {
      authRepId: 0,
      dirtyStatus: "insert",
      householdMemberYN: category === "UI" ? "N" : "Y",
      effectiveBeginDt: null,
      effectiveEndDt: null,
      individualId: hohIndividualId,
      relationshipCd: relationship ? relationship : null,
      authRepType: authRepType,
      categoryType: category,
      email: "",
      providerId: null,
      providerName: null,
      dateOfBirth: dateOfBirth,
      phoneNumber: {
        phoneNumber: "",
        phoneNumberExt: "",
        phoneNumberType: "",
      },
      personName: {
        firstName: fName ?? "",
        middleName: mName ? mName : "",
        lastName: lName ?? "",
      },
      address: {
        addressLine1: add1 ? add1 : "",
        addressLine2: add2 ? add2 : "",
        city: city ? city : "",
        zipcode: zipCode ? zipCode : "",
        state: state ? state : "",
        county: "",
        district: "",
      },

      authorizedRepresentativeDetails,
    },
    importSelected: true,
  };

  if (id) {
    const updatePayload = map(modifiedData, (val) => {
      console.log(id, 1);
      console.log(val, 2);
      if (id === val.id) {
        return authorizedRepPayload;
      } else {
        return val;
      }
    });
    console.log(updatePayload);

    return updatePayload;
  } else {
    if (!modifiedData) {
      return [authorizedRepPayload];
    } else {
      return [...modifiedData, authorizedRepPayload];
    }
  }
};
export const documentUploadPayload = (payload) => {
  const formData = new FormData();
  formData.append("files", payload.fileFormData);
  formData.append("individualId", payload.individualId);
  formData.append("controlId", payload.controlId);
  formData.append("docType", payload.documentTypeCd);
  formData.append("docCat", payload.lookupShortVal);
  formData.append("fileName", payload.verificationName);
  formData.append("createDt", payload.createDt);
  formData.append("createUserId", payload.createUserId);
  formData.append("sourceCaseId", payload.sourceCaseId);
  formData.append("sourceSystem", payload.sourceSystem);
  return formData;
};

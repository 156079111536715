import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../../components/PageContainer";
import { Box, Grid, Stack, Typography } from "@mui/material";
import Dropdown from "../../../components/Dropdown";
import Field from "../../../components/Field";
import { useForm } from "react-hook-form";
import useLookup from "../../../hooks/useLookup";
import usePageApi from "../../../hooks/usePageApi";
import FieldTitle from "../../../components/FieldTitle";
import usePageNav from "../../../hooks/usePageNav";
import ScreenProvider from "../../../containers/ScreenProvider";
import useLoader from "../../../hooks/useLoader";
import { showErrorToast } from "../../../utils/services/toast";
import useDynamicNav from "../../../hooks/useDynamicNav";
import * as yup from "yup";
function PreferredLang({ screenId }) {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: 401,
    screenDefn: "/dashboard/consumer-benefit-review/hoh/preferedLanguage",
    callRetrieveStatus: true,
  });

  const [showLoader, hideLoader] = useLoader();
  const {
    watch,
    control,
    getValues,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      languagetoSpeak: "E",
      languagetoRead: "E",
      applicationLanguage: "E",
      ...pageData,
    },
  });
  const { getOptions } = useLookup("PreferredLanguage");
  const languageOpts = getOptions("PrimaryLanguage");

  const data = pageData?.preflangInfo?.[0];

  useEffect(() => {
    if (data) {
      setValue("languagetoRead", data.languagetoRead);
      setValue("languagetoSpeak", data.languagetoSpeak);
      setValue("applicationLanguage", data.applicationLanguage);
    }
  }, [data]);

  const onSave = async () => {
    const payload = {
      preflangInfo: [
        {
          dirtyStatus: "INSERT",
          screenDefn: "/dashboard/consumer-benefit-review/hoh/preferedLanguage",
          screenId: 401,
          languagetoSpeak: getValues("languagetoSpeak"),
          languagetoRead: getValues("languagetoRead"),
          applicationLanguage: getValues("applicationLanguage"),
          individualId: "",
        },
      ],
    };
    try {
      showLoader();
      const res = await saveJson(payload);
      if (res?.status === 200) {
        const values = getValues();
        const isFilled = await yup
          .object({
            languagetoSpeak: yup.string().required(),
            languagetoRead: yup.string().required(),
            applicationLanguage: yup.string().required(),
          })
          .isValid(values);

        handleNext(isFilled);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const onBack = () => {
    navigations.toSnapQualifier();
  };

  const datas = pageData?.preflangInfo?.[0];

  return (
    <ScreenProvider labels={labels}>
      <Box sx={{ paddingLeft: "1rem" }}>
        <PageContainer
          title="Preferred Language"
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Stack
            rowGap={2}
            sx={{
              minWidth: "15.6rem",
              maxWidth: "30rem",
              marginTop: "1rem",
              gap: "3.5rem",
            }}
          >
            <Field
              name="languageRead"
              label="What language do you prefer to read?"
            >
              <Dropdown
                sx={{ mt: "1rem" }}
                control={control}
                name="languagetoRead"
                options={languageOpts}
              />
            </Field>
            <Field
              name="languageSpeak"
              label="What language do you prefer to speak?"
            >
              <Dropdown
                sx={{ mt: "1rem" }}
                control={control}
                name="languagetoSpeak"
                options={languageOpts}
              />
            </Field>
            <Field
              name="languageApplication"
              label="In what language would you like to complete this application?"
            >
              <Dropdown
                sx={{ mt: "1rem" }}
                control={control}
                name="applicationLanguage"
                options={languageOpts}
              />
            </Field>
            <div
              style={{
                background: "#D3E8F8",
                padding: "2rem",
              }}
            >
              <Typography variant="h4">
                {labels?.languageHelp ?? "Need Language Help?"}
              </Typography>
              <Typography variant="h4" sx={{ mt: ".5rem" }}>
                {labels?.needInterpreter ?? "If you need a free Interpreter"},
                <span style={{ color: "var(--primary-dark)" }}>
                  {labels?.contactOffice ??
                    "Please contact your county office."}
                </span>
              </Typography>
            </div>
          </Stack>
        </PageContainer>
      </Box>
    </ScreenProvider>
  );
}

PreferredLang.propTypes = {};

export default PreferredLang;

import { Avatar, Stack, Typography, Box, Grid } from "@mui/material";
import React, { useMemo } from "react";
import TabularView from "../../../components/TabularView";
import useLookup from "../../../hooks/useLookup";

function Details({ selected }) {
  const { getLkpMatch } = useLookup("AuthRep");
  const { authorizedRepresentative } = selected ?? {};
  const { firstName, lastName } = authorizedRepresentative?.personName ?? {};
  const { addressLine1, addressLine2, city, zipcode, state } =
    authorizedRepresentative?.address ?? {};

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={3}
          sx={{ flexDirection: "column", gap: "1rem", display: "flex" }}
        >
          <Stack xs={8}>
            <Typography variant="h4grey">{"Name"}</Typography>
          </Stack>
          <Stack xs={8}>
            <Typography variant="h4grey">{"Address"}</Typography>
          </Stack>
          <Stack xs={8}>
            <Typography variant="h4grey">{"City"}</Typography>
          </Stack>
          <Stack xs={8}>
            <Typography variant="h4grey">{"State"}</Typography>
          </Stack>
          <Stack xs={8}>
            <Typography variant="h4grey">{"Zip Code"}</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <Stack xs={8}>
            <Typography>
              {firstName} &nbsp; {lastName}
            </Typography>
          </Stack>
          <Stack xs={8}>
            {addressLine1} &nbsp; {addressLine2}
          </Stack>
          <Stack xs={8}>
            <Typography>{city}</Typography>
          </Stack>
          <Stack xs={8}>{getLkpMatch("State", state)}</Stack>
          <Stack xs={8}>
            <Typography>{zipcode}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default Details;

import React, { useEffect, useState } from "react";
import { Grid, Box, Paper, AppBar, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import "./LandingPage.css";
import carepic from "../../assets/images/caregrouppic_new.png";
import landing from "../../assets/images/Landing.png";
import frame from "../../assets/images/Help.png";
import Typography from "@mui/material/Typography";
import viewbenefit from "../../assets/images/view.svg";
import track from "../../assets/images/track.svg";
import submit from "../../assets/images/submit.svg";
import call from "../../assets/images/call.svg";
import location from "../../assets/images/location.svg";
import online from "../../assets/images/online.svg";
import usePageApi from "../../hooks/usePageApi";
import Footer from "../../containers/Layout/Footer";
import NavBar from "../../containers/Layout/NavBar";
import usePageNav from "../../hooks/usePageNav";
import { getAuthToken } from "../../store/selectors/authSelector";
import { useSelector } from "react-redux";
import { showWarningToast } from "../../utils/services/toast";
import { ReactComponent as CDC } from "../../assets/images/cdc.svg";
import { ReactComponent as SNAP } from "../../assets/images/snap.svg";
import { ReactComponent as Medicaid } from "../../assets/images/medicaid.svg";
import { ReactComponent as Energy } from "../../assets/images/energy-ass.svg";
import { ReactComponent as Cash } from "../../assets/images/headset.svg";
import { ReactComponent as Women } from "../../assets/images/women.svg";
import { ReactComponent as Outbound } from "../../assets/images/outbound.svg";
import { ReactComponent as Arrow } from "../../assets/images/arrow-forward.svg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const LandingPage = () => {
  const { navigations } = usePageNav();
  const token = useSelector(getAuthToken);

  // const [labels, setScreenLabels] = useState({});
  const [language, setLanguage] = useState(
    localStorage.getItem("language_code") || "en_US"
  );
  //   const { screenLabels, fetchScreenLables } = useScreenLabels(426);
  const { labels, pageData, saveJson, retrieveJson, dirtyFields } = usePageApi({
    // controlId: controlId,
    screenId: 426,
    screenDefn: "/dashboard/consumer-app/intake-app/members/relationship",
  });
  const [languageChanged, setLanguageChanged] = useState(false);
  useEffect(() => {
    // handleScreenLables();
    localStorage.setItem("language_code", language);
  }, [language]);

  const handleLanguage = () => {
    const newLanguage = language === "en_US" ? "en_ES" : "en_US";
    setLanguage(newLanguage);
    localStorage.setItem("language_code", newLanguage);
    // fetchScreenLables(newLanguage);
    setLanguageChanged(true);
  };

  const dashboard = () => {
    if (!token) {
      navigations.toLogin();
    } else {
      navigations.toDashboard();
    }
  };
  const createAccount = () => {
    navigations.toRegistration();
  };

  return (
    <React.Fragment>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <NavBar />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              className="landsecondgridmain"
              sx={{
                background: "#20374b",
                width: "auto",
                height: { lg: "90vh", md: "40vh" },
                paddingTop: "20px",
                paddingBottom: "20px",
                paddingLeft: "43px",
                paddingRight: "30px",
                position: "relative",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={10}>
                    <div className="landsecondgridleft">
                      <span className="kacre-k">{labels?.k ?? "K"}</span>
                      <span className="kcare-care">
                        {labels?.cares ?? "Connect"}
                      </span>
                      <span className="simplifies">
                        {labels?.simplifies ?? "Simplifies"}
                      </span>
                      <br />
                      <span className="benefitapp">
                        {labels?.kcares ?? "Benefit Application & Management"}
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt="1rem" ml="0.3rem" gap="10px">
                  <Button
                    variant="contained"
                    sx={{
                      padding: "10px 10px",
                      borderRadius: "10px",
                      width: { xs: "100%", sm: "200px" },
                      height: "67px",
                      border: "2px solid var(--white)",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                    onClick={() => dashboard()}
                  >
                    {labels?.applyForBenefits ?? "Apply for Benefits"}
                  </Button>
                  <Button
                    variant="text"
                    sx={{
                      padding: "10px 10px",
                      borderRadius: "10px",
                      width: { xs: "100%", sm: "200px" },
                      height: "67px",
                      border: "2px solid var(--white)",
                      fontSize: "18px",
                      color: "#2C2C2C",
                      backgroundColor: "var(--white)",
                      fontWeight: "600",
                    }}
                    onClick={() => createAccount()}
                  >
                    {labels?.createAnAccount ?? "Create an Account"}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{
                  backgroundImage: `url(${landing})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  height: { xs: "50vh", md: "50vh", lg: "auto" },
                  width: "100%",
                  position: { md: "absolute", lg: "static" },
                  left: { md: "30rem", lg: "0" },
                  top: { md: "-3rem", lg: "0" },
                }}
              ></Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "var(--landing-background)",
              paddingBottom: "1rem",
            }}
          >
            <Box sx={{ padding: "1rem" }}>
              <Grid container sx={{ padding: "1rem" }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <Typography variant="h1">
                    What Benefits Are Available To You?
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    paddingTop: "0.5rem",
                  }}
                >
                  <Typography variant="h5">
                    You Can Apply To More Than One Program At The Same Time.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding="1rem">
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Box
                    sx={{
                      backgroundColor: "#CAECE6",
                      borderRadius: "20px",
                      width: "100%",
                      height: "230px",
                      padding: "20px",
                    }}
                  >
                    <Stack direction="row" justifyContent="start">
                      <CDC />
                      <Typography
                        fontSize="20px"
                        fontWeight="700"
                        color="#6C6C6C"
                        sx={{
                          paddingTop: "0.5rem",
                          paddingLeft: "1rem",
                        }}
                      >
                        Child Development & Care (CDC)
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      paddingLeft="3.6rem"
                    >
                      <Typography variant="subtitle2">
                        States and territories receive funding from the federal
                        government to provide child care financial assistance to
                        help families with low-income pay for child care so they
                        can work or attend school.
                      </Typography>
                    </Stack>
                    {/* <Stack direction="row" justifyContent="end">
                      <Outbound />
                    </Stack> */}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Box
                    sx={{
                      backgroundColor: "#FFDFB9",
                      borderRadius: "20px",
                      width: "100%",
                      height: "230px",
                      padding: "20px",
                    }}
                  >
                    <Stack direction="row" justifyContent="start">
                      <SNAP />
                      <Typography
                        fontSize="20px"
                        fontWeight="700"
                        color="#6C6C6C"
                        sx={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                      >
                        SNAP Program
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      paddingLeft="4rem"
                    >
                      <Typography variant="subtitle2">
                        SNAP provides food benefits to low-income families to
                        supplement their grocery budget so they can afford the
                        nutritious food essential to health and well-being.
                      </Typography>
                    </Stack>
                    {/* <Stack direction="row" justifyContent="end">
                      <Outbound />
                    </Stack> */}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Box
                    sx={{
                      backgroundColor: "#D9EECE",
                      borderRadius: "20px",
                      width: "100%",
                      height: "230px",
                      padding: "20px",
                    }}
                  >
                    <Stack direction="row" justifyContent="start">
                      <Medicaid />
                      <Typography
                        fontSize="20px"
                        fontWeight="700"
                        color="#6C6C6C"
                        sx={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                      >
                        Medicaid
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      paddingLeft="4rem"
                    >
                      <Typography variant="subtitle2">
                        Medicaid is the primary program providing comprehensive
                        coverage of health care and long-term services and
                        supports to more than 90 million low-income people in
                        the United States.
                      </Typography>
                    </Stack>
                    {/* <Stack direction="row" justifyContent="end">
                      <Outbound />
                    </Stack> */}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Box
                    sx={{
                      backgroundColor: "#F9EAFC",
                      borderRadius: "20px",
                      width: "100%",
                      height: "230px",
                      padding: "20px",
                    }}
                  >
                    <Stack direction="row" justifyContent="start">
                      <Energy />
                      <Typography
                        fontSize="20px"
                        fontWeight="700"
                        color="#6C6C6C"
                        sx={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                      >
                        Energy Assistance
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      paddingLeft="4rem"
                    >
                      <Typography variant="subtitle2">
                        The Low Income Home Energy Assistance Program (LIHEAP)
                        can help you pay your heating or cooling bills or get
                        emergency services during an energy crisis.
                      </Typography>
                    </Stack>
                    {/* <Stack direction="row" justifyContent="end">
                      <Outbound />
                    </Stack> */}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Box
                    sx={{
                      backgroundColor: "#CEDCEE",
                      borderRadius: "20px",
                      width: "100%",
                      height: "230px",
                      padding: "20px",
                    }}
                  >
                    <Stack direction="row" justifyContent="start">
                      <Cash />
                      <Typography
                        fontSize="20px"
                        fontWeight="700"
                        color="#6C6C6C"
                        sx={{
                          paddingTop: "0.5rem",
                          paddingLeft: "1rem",
                        }}
                      >
                        Cash Assistance
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      paddingLeft="4rem"
                    >
                      <Typography variant="subtitle2">
                        If you have low income and limited resources, you may be
                        able to receive cash assistance through Temporary
                        Assistance for Needy Families (TANF) or General
                        Assistance.
                      </Typography>
                    </Stack>
                    {/* <Stack direction="row" justifyContent="end">
                      <Outbound />
                    </Stack> */}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Box
                    sx={{
                      backgroundColor: "#FDD0C1",
                      borderRadius: "20px",
                      width: "100%",
                      height: "230px",
                      padding: "20px",
                    }}
                  >
                    <Stack direction="row" justifyContent="start">
                      <Women />
                      <Typography
                        fontSize="20px"
                        fontWeight="700"
                        color="#6C6C6C"
                        sx={{
                          paddingTop: "0.5rem",
                          paddingLeft: "1rem",
                        }}
                      >
                        Women, Infants & Child (WIC)
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      paddingLeft="4rem"
                    >
                      <Typography variant="subtitle2">
                        If you have low income and limited resources, you may be
                        able to receive cash assistance through Temporary
                        Assistance for Needy Families (TANF) or General
                        Assistance.
                      </Typography>
                    </Stack>
                    {/* <Stack direction="row" justifyContent="end">
                      <Outbound />
                    </Stack> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid item xs={12} lg={12}>
                  <div className="manage-benefit">
                    {labels?.manageBenefits ?? "I Want to Manage My Benefits."}
                  </div>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Grid
                    container
                    spacing={2}
                    padding="2rem"
                    paddingBottom="3rem"
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <Stack direction="column" alignItems="center">
                        <img
                          src={viewbenefit}
                          style={{ width: "120px", height: "120px" }}
                        />
                        <Typography
                          variant="h4"
                          paddingTop="1rem"
                          textAlign="center"
                        >
                          View your <br /> Benefits Details
                        </Typography>
                        <Typography
                          variant="body1"
                          textAlign="center"
                          paddingTop="0.5rem"
                        >
                          This includes program status, <br /> benefit amount,
                          and other case details.
                          {/* {labels?.benefitDetail ??
                            "This includes program status,  benefit amount, and other case details."} */}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <Stack direction="column" alignItems="center">
                        <img
                          src={track}
                          style={{ width: "120px", height: "120px" }}
                        />
                        <Typography
                          variant="h4"
                          paddingTop="1rem"
                          textAlign="center"
                        >
                          Track Statuses <br /> and Reminders
                          {/* {labels?.trackStatusAndReminder ??
                            "Track Statuses and Reminders"} */}
                        </Typography>
                        <Typography
                          variant="body1"
                          textAlign="center"
                          paddingTop="0.5rem"
                        >
                          Track your progress and <br /> receive deadline
                          reminders.
                          {/* {labels?.trackDetails ??
                            "Track your progress and receive deadline reminders."} */}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <Stack direction="column" alignItems="center">
                        <img
                          src={submit}
                          style={{ width: "120px", height: "120px" }}
                        />
                        <Typography
                          variant="h4"
                          paddingTop="1rem"
                          textAlign="center"
                        >
                          Submit Documents <br /> and Renewals
                          {/* {labels?.submitDocAndRenew ??
                            "Submit Documents and Renewals"} */}
                        </Typography>
                        <Typography
                          variant="body1"
                          textAlign="center"
                          paddingTop="0.5rem"
                        >
                          Keep up with required <br /> reporting and report
                          changes online.
                          {/* {labels?.submitDetails ??
                            "Keep up with required reporting and report changes online."} */}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{ backgroundColor: "var(--primary-dark)" }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "2rem",
                }}
              >
                <Typography
                  fontSize="38px"
                  fontWeight="700"
                  color="var(--white)"
                >
                  Exploring Support and Benefits
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography
                  fontSize="22px"
                  fontWeight="500"
                  color="var(--white)"
                >
                  I would like to explore available support options and
                  benefits.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ paddingTop: "2rem", paddingBottom: "3rem" }}
              >
                <Grid container spacing={2} padding="2rem">
                  <Grid item xs={12} md={4}>
                    <Paper className="learnto">
                      <Grid container>
                        <Grid item xs={3}>
                          <img
                            src={call}
                            alt=""
                            style={{ width: "60px", height: "80px" }}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Stack direction="column">
                            <Typography variant="h4" fontWeight="700">
                              {labels?.benefitCal ??
                                "How can I learn to use BenefitsCal?"}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingTop: "1rem",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight="600"
                                color="var(--primary-dark)"
                                sx={{ marginRight: "1rem" }}
                              >
                                {labels?.videosOrBlogs ??
                                  "Watch How to Videos or Blogs"}
                              </Typography>
                              <Arrow />
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper className="learnto">
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <img
                            src={location}
                            alt=""
                            style={{ width: "60px", height: "80px" }}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Stack direction="column">
                            <Typography variant="h4" fontWeight="700">
                              {labels?.contactAndLocation ??
                                "Find My County Contact and location"}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingTop: "1rem",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight="600"
                                color="var(--primary-dark)"
                                sx={{ marginRight: "1rem" }}
                              >
                                {labels?.anOffice ?? "Find an Offices"}
                              </Typography>
                              <Arrow />
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper className="learnto">
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <img
                            src={online}
                            alt=""
                            style={{ width: "60px", height: "80px" }}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Stack direction="column">
                            <Typography variant="h4" fontWeight="700">
                              {labels?.onlineBenefit ??
                                "Learn Online Benefit Management here."}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingTop: "0.2rem",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight="600"
                                color="var(--primary-dark)"
                                sx={{ marginRight: "1rem" }}
                              >
                                {labels?.videosOrBlogs ??
                                  "Watch How to Videos or Blogs"}
                              </Typography>
                              <Arrow />
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                padding: "1rem",
                paddingTop: "4rem",
                paddingBottom: "3rem",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" padding="1rem">
                    <Grid item xs={12}>
                      <Typography variant="h2" color="var(--primary-dark)">
                        How Can We Help
                      </Typography>
                    </Grid>
                  </Stack>
                  <Grid container sx={{ padding: "1rem" }}>
                    <Grid item xs={12}>
                      <Stack direction="row">
                        <CheckBoxIcon sx={{ color: "var(--primary-dark)" }} />
                        <Typography
                          variant="h5"
                          fontWeight="700"
                          sx={{ paddingLeft: "1rem" }}
                        >
                          Get Personalized Assistance
                        </Typography>
                      </Stack>
                      <Stack direction="row" sx={{ paddingLeft: "2.5rem" }}>
                        <Typography variant="subtitle1">
                          Refers to tailored support that addresses individual
                          needs, providing specific and relevant solutions.
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row">
                        <CheckBoxIcon sx={{ color: "var(--primary-dark)" }} />
                        <Typography
                          variant="h5"
                          fontWeight="700"
                          sx={{ paddingLeft: "1rem" }}
                        >
                          Connect Online
                        </Typography>
                      </Stack>
                      <Stack direction="row" sx={{ paddingLeft: "2.5rem" }}>
                        <Typography variant="subtitle1">
                          Encourages consumption of nutritious foods, which can
                          improve seniors' overall health and well-being.
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row">
                        <CheckBoxIcon sx={{ color: "var(--primary-dark)" }} />
                        <Typography
                          variant="h5"
                          fontWeight="700"
                          sx={{ paddingLeft: "1rem" }}
                        >
                          Providing Guidance
                        </Typography>
                      </Stack>
                      <Stack direction="row" sx={{ paddingLeft: "2.5rem" }}>
                        <Typography variant="subtitle1">
                          Supports local farmers and the economy by promoting
                          sales at farmers' markets and other direct-to-consumer
                          outlets.
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    backgroundImage: `url(${frame})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "right",
                    height: { xs: "40vh", md: "auto" },
                    width: "100%",
                    paddingRight: "1rem",
                    paddingLeft: { xs: "2rem", md: "3rem" },
                  }}
                />
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default LandingPage;

import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import CRadioButton from "../../../components/RadioButton";
import PageContainer from "../../../components/PageContainer";
import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import usePageApi from "../../../hooks/usePageApi";
import Dropdown from "../../../components/Dropdown";
import Field from "../../../components/Field";
import useLookup from "../../../hooks/useLookup";
import CDatePicker from "../../../components/DatePicker";
import { Grid, TextField, Typography } from "@mui/material";
import usePageNav from "../../../hooks/usePageNav";
import ScreenProvider from "../../../containers/ScreenProvider";
import { hohInfoPayload } from "../../../services/HohInfo/apiPayload";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CoTextField from "../../../controllers/CoTextField";
import {
  maxLength,
  name,
  number,
  onlyAlphabet,
} from "../../../utils/normalizers";
import { yupResolver } from "@hookform/resolvers/yup";
import { first } from "lodash";
import {
  onlyAlphabtes,
  mandatory,
  // radioFields,
} from "../../../ValidationRules";
import * as yup from "yup";
import { DirtyLens, Login } from "@mui/icons-material";
import useLoader from "../../../hooks/useLoader";
import { showErrorToast } from "../../../utils/services/toast";
import { saveMember } from "../../../services/apiRequests";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import { useDispatch, useSelector } from "react-redux";
import { setHohInfo } from "../../../store/slices/appSlice";
import useDynamicNav from "../../../hooks/useDynamicNav";
import { trimSSN } from "../../../utils/helpers";
import InputMask from "react-input-mask";

const HOHInfo = ({ screenId }) => {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const [maskSsn, setMaskSsn] = useState(true);
  const dispatch = useDispatch();
  const [showLoader, hideLoader] = useLoader();
  const controlId = useSelector(selecttActiveContId);

  const { pageData, labels } = usePageApi({
    screenId,
    controlId: controlId,
    screenDefn: "/dashboard/consumer-app/intake-app/hoh/hohInfo",
    callRetrieveStatus: true,
  });

  const { pageData: prgmDetails } = usePageApi({
    screenDefn: "/dashboard/consumer-app/intake-app/assistance/progSelect",
    controlId: controlId,
  });

  const { getOptions } = useLookup("hoh");

  const userSchema = yup.object().shape(
    {
      firstName: onlyAlphabtes("First Name"),
      lastName: onlyAlphabtes("Last Name"),
      martialStatus: mandatory("Marital Status"),
      DOB: yup
        .date()
        .typeError("Choose Date")
        .max(dayjs().format("YYYY/MM/DD"))
        .required(),
      gender: mandatory("gender"),
      areYouPregnant: yup.string().when(["gender"], {
        is: (gender) => gender === "F",
        then: (userSchema) =>
          userSchema.required("Please choose pregancy details"),
        otherwise: (userSchema) => userSchema.notRequired(),
      }),
      expDueDate: yup.string().when("areYouPregnant", {
        is: (areYouPregnant) => areYouPregnant === "Y",
        then: (userSchema) => userSchema.required("This field is required"),
        otherwise: (userSchema) => userSchema.notRequired(),
      }),
    },
    ["gender"]
  );
  const { suffix, martialStatusOpts, gender } = useMemo(() => {
    return {
      suffix: getOptions("Suffix"),
      martialStatusOpts: getOptions("MartialStatus"),
      gender: getOptions("Gender"),
    };
  }, [getOptions]);
  const {
    watch,
    control,
    getValues,
    setValue,
    reset,
    //errors,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(userSchema),

    defaultValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      suffix: "",
      DOB: "",
      gender: "",
      areYouPregnant: "",
      SSN: "",
      martialStatus: "",
      expDueDate: null,
      ...pageData,
    },
  });
  const isFemale = watch("gender") === "F";
  const isPregnant = watch("areYouPregnant") === "Y";

  const onBack = () => {
    navigations.toPreferredLang();
  };

  const individualId = pageData?.[0]?.individualId;
  const onSave = async () => {
    const payload = {
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      middleName: getValues("middleName"),
      suffix: getValues("suffix"),
      gender: getValues("gender"),
      DOB: getValues("DOB"),
      martialStatus: getValues("martialStatus"),
      areYouPregnant: getValues("areYouPregnant"),
      expDueDate: getValues("expDueDate"),
      SSN: trimSSN(getValues("SSN")),
      prgmDetails,
      dirtyStatus: individualId ? "UPDATE" : "INSERT",
      controlId: controlId,
      individualId: individualId,
    };

    try {
      showLoader();
      const res = await saveMember(hohInfoPayload(payload));
      // const res = await saveJson(hohInfoPayload(payload));
      if (res?.status === 200) {
        dispatch(setHohInfo(res.data));
        // navigations.toRaceAndDisability();
        handleNext();
        dispatch({ type: "FETCH_HOH_INFO" });
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const data = pageData?.[0];

  useEffect(() => {
    if (data) {
      reset({
        firstName: data?.clientContainer?.client?.personName?.firstName,
        lastName: data?.clientContainer?.client?.personName?.lastName,
        middleName: data?.clientContainer?.client?.personName?.middleName,
        suffix: data?.clientContainer?.client?.personName?.suffix,
        DOB: data?.clientContainer?.client?.dob
          ? dayjs(data?.clientContainer?.client?.dob)
          : "",
        SSN:
          data?.clientContainer?.client?.soSec?.soSecPart1 &&
          data?.clientContainer?.client?.soSec?.soSecPart2 &&
          data?.clientContainer?.client?.soSec?.soSecPart3
            ? `${data?.clientContainer?.client?.soSec?.soSecPart1}-${data?.clientContainer?.client?.soSec?.soSecPart2}-${data?.clientContainer?.client?.soSec?.soSecPart3}`
            : "",
        gender: data?.clientContainer?.client?.gender,
        areYouPregnant: data?.clientContainer?.client?.pregnantYN,

        martialStatus: data?.clientContainer?.client?.maritalStatus?.marriedYN,
        expDueDate: data?.clientContainer?.client?.individualDetails
          ?.pregnancy?.[0]?.pregnancyDueDate
          ? dayjs(
              data?.clientContainer?.client?.individualDetails?.pregnancy?.[0]
                ?.pregnancyDueDate
            )
          : "",
      });
    }
  }, [data]);

  return (
    <ScreenProvider labels={labels}>
      <Box sx={{ paddingLeft: "1rem" }}>
        <PageContainer
          title="Your Information"
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Stack
            rowGap={2}
            sx={{
              minWidth: "15.6rem",
              maxWidth: "30rem",
              paddingTop: "0.5rem",
            }}
          >
            <Field
              name="firstName"
              label="First Name"
              required
              error={errors?.firstName?.message}
              showError={errors?.firstName?.message}
            >
              <CoTextField
                name="firstName"
                normalizers={[onlyAlphabet, name, maxLength(20)]}
                control={control}
                placeholder="Enter First Name"
              />
            </Field>
            <Field name="middleName" label="Middle Name">
              <CoTextField
                name="middleName"
                normalizers={[onlyAlphabet, name, maxLength(12)]}
                control={control}
                placeholder="Enter Middle Name"
              />
            </Field>
            <Field
              name="lastName"
              label="Last Name"
              required
              error={errors?.lastName?.message}
              showError={errors?.lastName?.message}
            >
              <CoTextField
                name="lastName"
                normalizers={[onlyAlphabet, name, maxLength(24)]}
                control={control}
                placeholder="Enter Last Name"
              />
            </Field>
            <Field name="suffix" label="Suffix">
              <Dropdown
                {...register("suffix")}
                name="suffix"
                placeholder="Select Suffix"
                control={control}
                options={suffix}
              />
            </Field>
            <Field name="SSN" label="SSN">
              <Controller
                name="SSN"
                control={control}
                render={({ field }) => (
                  <InputMask
                    {...register("SSN")}
                    mask="999-99-9999"
                    maskChar={null}
                    value={field.value}
                    onBlur={field.onBlur}
                  >
                    {(params) => (
                      <TextField
                        // {...register("claimid")}
                        variant="outlined"
                        placeholder="Enter SSN Number"
                        {...params}
                        type={maskSsn ? "password" : "text"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{ marginRight: "-13px" }}
                                onClick={() => setMaskSsn(!maskSsn)}
                              >
                                {maskSsn ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Field>
            <Field
              name="DOB"
              label="DOB"
              error={errors.DOB?.message}
              showError={errors.DOB?.message}
              required
            >
              <CDatePicker
                name="DOB"
                disableFuture
                {...register("DOB")}
                control={control}
                placeholder="Select Date(MM/DD/YYYY)"
              />
            </Field>
            <Field
              name="gender"
              error={errors.gender?.message}
              showError={errors.gender?.message}
              label="Gender"
              required
            >
              <CRadioButton
                row
                {...register("gender")}
                name="gender"
                control={control}
                radioArray={gender}
              />
            </Field>
            {isFemale && (
              <Field
                name="areYouPregnant"
                required={getValues("gender") === "F"}
                error={errors.areYouPregnant?.message}
                showError={errors.areYouPregnant?.message}
                label="Are you Pregnant?"
              >
                <Box>
                  <CRadioButton
                    {...register("areYouPregnant")}
                    name="areYouPregnant"
                    control={control}
                    radioArray={[
                      {
                        label: "Yes",
                        value: "Y",
                      },
                      {
                        label: "No",
                        value: "N",
                      },
                    ]}
                  />
                </Box>
              </Field>
            )}
            {isFemale && isPregnant && (
              <Field
                name="expDueDate"
                label="Expected Due Date"
                error={errors.expDueDate?.message}
                showError={errors.expDueDate?.message}
                required
              >
                <CDatePicker
                  minDate={dayjs()}
                  {...register("expDueDate")}
                  name="expDueDate"
                  control={control}
                  placeholder="Select Date(MM/DD/YYYY)"
                />
              </Field>
            )}

            <Field
              name="martialStatus"
              label="Marital Status"
              error={errors.martialStatus?.message}
              showError={errors.martialStatus?.message}
              required
            >
              <Dropdown
                name="martialStatus"
                control={control}
                options={martialStatusOpts}
                placeholder="Select Marital Status"
              />
            </Field>
          </Stack>
        </PageContainer>
      </Box>
    </ScreenProvider>
  );
};
export default HOHInfo;

import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { Stack } from "@mui/system";
import { CloseRounded, DeleteOutlineOutlined } from "@mui/icons-material";

function MemberDeleteModal({
  open,
  close,
  memberToDelete,
  caseId,
  onDelete,
  reasons,
}) {
  const { individualId, name } = memberToDelete ?? {};
  return (
    <Dialog open={open} onClose={close} component="form" maxWidth="md">
      <DialogContent>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <IconButton onClick={close}>
            <CloseRounded />
          </IconButton>
        </Stack>
        <Stack
          component="form"
          // onSubmit={formik.handleSubmit}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <DeleteOutlineOutlined
            sx={{ height: 100, width: 100, color: "#cf4242" }}
          />
          <Typography color="gray" variant="h3">
            Delete the Member?
          </Typography>
          <Typography my="1rem" variant="subtitle1">
            Do you really want to delete member <strong>{name}</strong>? You
            will not able to recover it.
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button color="error" variant="outlined" onClick={close}>
              Discard
            </Button>
            <Button
              color="error"
              sx={{ mt: "1rem" }}
              variant="contained"
              onClick={onDelete}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

MemberDeleteModal.propTypes = {};

export default MemberDeleteModal;

import React, { useEffect, useState } from "react";
import usePageApi from "../../../hooks/usePageApi";
import PageContainer from "../../../components/PageContainer";
import { Controller, useForm } from "react-hook-form";
import YesNoOption from "../../../components/YesNoOption";
import Field from "../../../components/Field";
import usePageNav from "../../../hooks/usePageNav";
import { Box, Grid } from "@mui/material";
import ScreenProvider from "../../../containers/ScreenProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import { mandatory } from "../../../ValidationRules";
import * as yup from "yup";
import useDynamicNav from "../../../hooks/useDynamicNav";

export default function PersonalRequest({ screenId }) {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const { labels, pageData, saveJson, retrieveJson } = usePageApi({
    screenId: 420,
    screenDefn: "/application/hoh/personal-benefit-request",
    callRetrieveStatus: true,
  });
  const userSchema = yup.object({
    isBenefits: yup.string().required("Please choose any option"),
  });
  const {
    control,
    watch,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
    defaultValues: {
      isBenefits: "",
      ...pageData,
    },
  });
  const onSave = async () => {
    const isBenefits = getValues("isBenefits");

    const payload = {
      dirtyStatus: "INSERT",

      screenDefn: "/application/hoh/personal-benefit-request",
      screenId: 420,
      memId: "",
      HohAlone: isBenefits,
    };
    await saveJson(payload);

    // if (isBenefits === "Y") {
    //   navigations.toCitizenship();
    // } else {
    //   navigations.toAddHoh();
    // }
    // navigations.toMemberSummary();
    handleNext(!!isBenefits);
  };

  const data = pageData;

  useEffect(() => {
    if (data) {
      setValue("isBenefits", data.HohAlone);
    }
  }, [data]);

  const onBack = () => {
    navigations.toContactInfo();
  };

  return (
    <>
      <ScreenProvider labels={labels}>
        <Box sx={{ paddingLeft: "1rem" }}>
          <PageContainer
            title="Personal Benefit Request"
            onNext={handleSubmit(onSave)}
            onBack={onBack}
          >
            <Grid container mt={"2rem"}>
              <Field
                name="benefits"
                label="Are You Requesting Benefit on your behalf?"
                error={errors.isBenefits?.message}
                showError={errors.isBenefits?.message}
              >
                <Controller
                  name="isBenefits"
                  control={control}
                  render={({ field }) => {
                    return <YesNoOption {...field} />;
                  }}
                />
              </Field>
            </Grid>
          </PageContainer>
        </Box>
      </ScreenProvider>
    </>
  );
}

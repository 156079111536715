import React, { useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer";
import ScreenProvider from "../../containers/ScreenProvider";
import SelectableView from "../../components/SelectableView";
import MemberMenuButton from "../../components/SelectableView/MemberMenuButton";
import usePageApi from "../../hooks/usePageApi";
import RoomerDetails from "./RoomerDetails";
import usePageNav from "../../hooks/usePageNav";
import useDynamicNav from "../../hooks/useDynamicNav";

export default function RoomerBoarder({ screenId }) {
  const [data, setData] = useState([]);
  // const { handleNext } = useDynamicNav({ screenId });
  const { navigations } = usePageNav();
  const [name, setName] = useState("");
  const { pageData, isFetchingLabels, labels, saveJson } = usePageApi({
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
    screenId,
    callRetrieveStatus: true,
  });

  useEffect(() => {
    if (pageData) {
      setData(pageData);
    }
  }, [pageData]);

  const onNext = () => {
    navigations.toAsset();
  };

  const onBack = () => {
    navigations.toMedicareDet();
  };
  return (
    <PageContainer
      title="Roomer Boarder Details"
      onNext={onNext}
      onBack={onBack}
    >
      <ScreenProvider labels={labels}>
        <SelectableView
          data={data}
          loading={isFetchingLabels}
          title="Members"
          showAdd={false}
          showEdit={false}
          showDelete={false}
          loadingContent={isFetchingLabels}
          getId={(params) => params.dummyIndividualId}
          getName={({ firstName, lastName }) => {
            setName(`${firstName} ${lastName}`);
            return `${firstName} ${lastName}`;
          }}
          renderMenuButton={(params, options) => {
            return (
              <MemberMenuButton
                member={params}
                options={options}
                // genderLkp={lkpFlatten?.Gender}
              />
            );
          }}
        >
          <RoomerDetails />
        </SelectableView>
      </ScreenProvider>
    </PageContainer>
  );
}

import React, { useEffect, useState } from "react";
import SelectableView from "../../components/SelectableView";
import MemberMenuButton from "../../components/SelectableView/MemberMenuButton";
import { map } from "lodash";
import usePageApi from "../../hooks/usePageApi";
import AssetContent from "./AssetContent";
import PageContainer from "../../components/PageContainer";
import usePageNav from "../../hooks/usePageNav";
import ScreenProvider from "../../containers/ScreenProvider";
import useDynamicNav from "../../hooks/useDynamicNav";

export default function Assets({ screenId }) {
  const { handleNext } = useDynamicNav({ screenId });
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const { navigations } = usePageNav();
  const {
    pageData,
    saveJson,
    retrieveJson,
    labels,
    isFetchingLabels,
    isFetchingData,
  } = usePageApi({
    screenId: 158,
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
    retrieveJson: true,
  });

  useEffect(() => {
    if (pageData) {
      setData(pageData);
    }
  }, [pageData]);

  const onNext = () => {
    handleNext();
    // navigations.toTransferAsset();
  };

  const onBack = () => {
    navigations.toRoomer();
  };
  return (
    <PageContainer title="Assets" onNext={onNext} onBack={onBack}>
      <ScreenProvider labels={labels}>
        <SelectableView
          loading={isFetchingData}
          loadingContent={isFetchingLabels}
          getId={(params) => params.dummyIndividualId}
          data={data}
          getName={({ firstName, lastName }) => {
            setName(`${firstName} ${lastName}`);
            return `${firstName} ${lastName}`;
          }}
          renderMenuButton={(params, options) => {
            return <MemberMenuButton member={params} options={options} />;
          }}
          showAdd={false}
          showEdit={false}
          showDelete={false}
        >
          <AssetContent getName={name} />
        </SelectableView>
      </ScreenProvider>
    </PageContainer>
  );
}

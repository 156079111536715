import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Stack,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Typography,
  IconButton,
} from "@mui/material";
import MedicarePopup from "../AddMedicare";
import { DataGrid } from "@mui/x-data-grid";
import usePageApi from "../../../hooks/usePageApi";
import ActionButtons from "../../../components/ActionButton";
import { filter, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import UploadFile from "../../../components/FileUpload";
import useLookup from "../../../hooks/useLookup";
import { showSuccessToast } from "../../../utils/services/toast";
import useLoader from "../../../hooks/useLoader";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export default function MedicareTable({ selected, getName }) {
  const [medOpen, setMedOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [createNew, setCreateNew] = useState(true);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [docOpen, setDocOpen] = useState(false);
  const { dummyIndividualId } = selected;
  const [showLoader, hideLoader] = useLoader();
  const { getOptions } = useLookup("ShelterUtility");
  const { medicalOptions } = useMemo(() => {
    return {
      medicalOptions: getOptions("Medical"),
    };
  }, [getOptions]);
  const { pageData, saveJson, labels, retrieveJson } = usePageApi({
    screenDefn: "/dashboard/consumer-app/intake-app/members/medicare",
    screenId: 154,
  });

  const AddMedicare = () => {
    setEditData({});
    setMedOpen(true);
    setCreateNew(true);
  };

  const toggleMedicare = () => {
    setMedOpen(false);
    setIsDeleteOpen(false);
  };

  const editMedicare = (data) => {
    setCreateNew(true);
    setMedOpen(true);
    setEditData(data);
  };

  const openDeleteDialog = (data) => {
    let { id } = data;
    setDeleteData(id);
    setIsDeleteOpen(true);
  };

  const medicareData = useMemo(() => [
    {
      field: "medicareTypeAStatus",
      headerName: `${labels?.typeA ?? "Medicare Type A"}`,
      width: 150,
      renderCell: (params) => {
        const medicareStatus = params.row?.medicare?.medicareTypeAStatus ?? "-";
        return medicareStatus === "A"
          ? "Applied"
          : medicareStatus === "R"
          ? "Receiving"
          : medicareStatus;
      },
    },
    {
      field: "medicareTypeBStatus",
      headerName: `${labels?.typeB ?? "Medicare Type B"}`,
      width: 150,
      renderCell: (params) => {
        const medicareStatus = params.row?.medicare?.medicareTypeBStatus ?? "-";
        return medicareStatus === "A"
          ? "Applied"
          : medicareStatus === "R"
          ? "Receiving"
          : medicareStatus;
      },
    },
    {
      field: "medicareTypeCStatus",
      headerName: ` ${labels?.typeC ?? "Medicare Type C"}`,
      width: 150,
      renderCell: (params) => {
        const medicareStatus = params.row?.medicare?.medicareTypeCStatus ?? "-";
        return medicareStatus === "A"
          ? "Applied"
          : medicareStatus === "R"
          ? "Receiving"
          : medicareStatus;
      },
    },
    {
      field: "medicareTypedDStatus",
      headerName: `${labels?.typeD ?? "Medicare Type D"}`,
      width: 150,
      renderCell: (params) => {
        const medicareStatus = params.row?.medicare?.medicareTypeDStatus ?? "-";
        return medicareStatus === "A"
          ? "Applied"
          : medicareStatus === "R"
          ? "Receiving"
          : medicareStatus;
      },
    },
    {
      field: "premiumAmount",
      headerName: `${labels?.premAmount ?? "Premium Amount"}`,
      renderCell: (params) => {
        const premiumAmount = params.row?.medicare?.premiumAmount;
        return premiumAmount ? `$ ${premiumAmount}` : "";
      },

      width: 150,
    },
    {
      field: "actions",
      headerName: `${labels?.actions ?? "Actions"}`,
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => editMedicare(params?.row)}
              onDelete={() => openDeleteDialog(params?.row)}
            />
          </div>
        );
      },
    },
  ]);

  const modificationData = map(pageData, (item, index) => ({
    ...item,
    id: index + 1,
  }));

  useEffect(() => {
    setColumnsData(medicareData);
    setRowData(
      filter(
        modificationData,
        (value) => value.individualId === dummyIndividualId
      )
    );
  }, [pageData, dummyIndividualId]);

  const handleDelete = async () => {
    try {
      showLoader();
      if (modificationData && modificationData.length > 0) {
        const deletePayload = modificationData.filter(
          (item) => item.id !== deleteData
        );
        const res = await saveJson(deletePayload);
        if (res.status === 200) {
          showSuccessToast("Succesfully Deleted");
          retrieveJson();
          setDeleteData("");
          setIsDeleteOpen(false);
        }
      }
    } catch (err) {
    } finally {
      hideLoader();
    }
  };
  return (
    <>
      <Grid mt={2}>
        <Stack direction={"row"} mb={1} justifyContent="space-between">
          <Button
            variant="outlined"
            size="small"
            startIcon={<UploadFileIcon />}
            // onClick={handleOpenDocModal}
            onClick={() => setDocOpen(true)}
          >
            {labels?.upload ?? " Upload files"}
          </Button>
          <Button onClick={AddMedicare} startIcon={<AddIcon />}>
            {labels?.addMedicare ?? "+ Add Medicare Details"}
          </Button>
        </Stack>
        <DataGrid
          autoHeight
          rows={rowData ?? []}
          columns={medicareData ?? []}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            marginTop: 2,
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: 16,
              fontWeight: 700,
              textAlign: "center",
              color: "black",
            },
          }}
          slots={{
            noRowsOverlay: "noData" ?? "No data",
          }}
        />
      </Grid>
      <Dialog
        maxWidth="lg"
        open={medOpen}
        onClose={toggleMedicare}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              // width: "80vw",
              height: "80vh",
            },
          },
        }}
      >
        <DialogTitle>
          <Stack
            direction={"row"}
            spacing={1}
            mb={2}
            justifyContent={"space-between"}
            mt={2}
          >
            <Typography variant="h3">
              {labels?.addMedicare ?? "Add Medicare Details"}
            </Typography>
            <IconButton onClick={toggleMedicare}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <MedicarePopup
            toggleMedicare={toggleMedicare}
            labels={labels}
            createNew={createNew}
            pageData={modificationData}
            saveJson={saveJson}
            dummyIndividualId={dummyIndividualId}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
            editData={editData}
          />
        </DialogContent>
      </Dialog>
      {/* <Dialog maxWidth="lg" open={editOpen} onClose={toggleMedicare}>
        <DialogContent>
          <MedicarePopup
            toggleMedicare={toggleMedicare}
            labels={labels}
            pageData={pageData}
            editData={editData}
            saveJson={saveJson}
            modificationData={modificationData}
            dummyIndividualId={dummyIndividualId}
          />
        </DialogContent>
      </Dialog> */}

      <Dialog open={isDeleteOpen} onClose={toggleMedicare}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleMedicare} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            // onClick={handleDeleteProgram}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <UploadFile
        setDocOpen={setDocOpen}
        docOpen={docOpen}
        activeIndex={dummyIndividualId}
        getName={getName}
        subTypeOptions={medicalOptions}
        defaultDocType="ME"
      />
    </>
  );
}

import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CDatePicker from "../../../components/DatePicker";
import Field from "../../../components/Field";
import usePageApi from "../../../hooks/usePageApi";
import { Controller, useForm } from "react-hook-form";
import YesNoOption from "../../../components/YesNoOption";

import { shelterPayload } from "../../../services/SheleterUtility/apiPayload";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useLoader from "../../../hooks/useLoader";
import { showSuccessToast } from "../../../utils/services/toast";
import CoTextField from "../../../controllers/CoTextField";
import { formatCurrency } from "../../../utils/normalizers";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export default function ShelterModal({
  labels,
  saveJson,
  retrieveJson,
  modifiedData,
  toggleShelter,
  createShelter,
  editData,
  dummyIndividualId,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [showLoader, hideLoader] = useLoader();
  const previousData = editData?.shelterUtilityExpense;

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const schemaValidation = yup.object({
    bDate: yup.string().when("activeTab", {
      is: () => {
        return activeTab === 0;
      },
      then: (schema) => schema.required("Please select the Begin Date"),
    }),
  });

  const {
    watch,
    control,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
    defaultValues: {
      bDate: previousData?.bgnDt ? dayjs(previousData?.bgnDt ?? "") : "",
      eDate: previousData?.endDt ? dayjs(previousData?.endDt ?? "") : "",
      meap: previousData?.meapDnd ?? "",
      rentPayHouse: previousData?.heatCoolInd ?? "",
      subsHouse: previousData?.subzHousingInd ?? "",
      rentPayWater: previousData?.noWaterInd ?? "",
      rent: previousData?.shelterExpenseTypes?.[0]?.shelterExpenseAmount ?? "",
      mortgage:
        previousData?.shelterExpenseTypes?.[1]?.shelterExpenseAmount ?? "",
      groundRent:
        previousData?.shelterExpenseTypes?.[2]?.shelterExpenseAmount ?? "",
      condoFee:
        previousData?.shelterExpenseTypes?.[3]?.shelterExpenseAmount ?? "",
      taxes: previousData?.shelterExpenseTypes?.[4]?.shelterExpenseAmount ?? "",
      homeowner:
        previousData?.shelterExpenseTypes?.[5]?.shelterExpenseAmount ?? "",
      gas: previousData?.shelterUtilityTypes?.[0]?.utilityExpenseAmount ?? "",
      electric:
        previousData?.shelterUtilityTypes?.[1]?.utilityExpenseAmount ?? "",
      water: previousData?.shelterUtilityTypes?.[2]?.utilityExpenseAmount ?? "",
      oil: previousData?.shelterUtilityTypes?.[3]?.utilityExpenseAmount ?? "",
      sewage:
        previousData?.shelterUtilityTypes?.[4]?.utilityExpenseAmount ?? "",
      garbage:
        previousData?.shelterUtilityTypes?.[5]?.utilityExpenseAmount ?? "",
      phone: previousData?.shelterUtilityTypes?.[0]?.utilityExpenseAmount ?? "",
      otherUtils:
        previousData?.shelterUtilityTypes?.[6]?.utilityExpenseAmount ?? "",
    },
  });

  const handleNext = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };
  const onTabChange = (e, value) => {
    setActiveTab(value);
  };

  const onSave = async () => {
    try {
      showLoader();
      const payload = {
        bDate: getValues("bDate"),
        eDate: getValues("eDate"),
        meap: getValues("meap"),
        rentPayHouse: getValues("rentPayHouse"),
        subsHouse: getValues("subsHouse"),
        rentPayWater: getValues("rentPayWater"),
        rent: getValues("rent"),
        mortgage: getValues("mortgage"),
        groundRent: getValues("groundRent"),
        condoFee: getValues("condoFee"),
        taxes: getValues("taxes"),
        homeowner: getValues("homeowner"),
        gas: getValues("gas"),
        electric: getValues("electric"),
        water: getValues("water"),
        oil: getValues("oil"),
        sewage: getValues("sewage"),
        garbage: getValues("garbage"),
        phone: getValues("phone"),
        otherUtils: getValues("otherUtils"),
        dirty: createShelter ? "insert" : "update",
        id: editData?.id ?? null,
        dummyIndividualId,
        modifiedData,
      };
      const res = await saveJson(shelterPayload(payload));
      if (res.status === 200) {
        showSuccessToast("Succesfully added the Shelter Details");
        retrieveJson();
        toggleShelter();
      }
    } catch (err) {
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", mt: "1rem" }}>
        <Tabs onChange={onTabChange} value={activeTab} variant="fullWidth">
          <Tab label="Shelter Expenses" {...a11yProps(0)} />
          <Tab label="Utility Expenses" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          <Grid container spacing={2} mt="2rem">
            <Grid item xs={6}>
              <Field
                name="bDate"
                label="Begin Date"
                required
                helperText={errors.bDate?.message}
                error={errors.bDate?.message}
              >
                <CDatePicker
                  {...register("bDate")}
                  name="bDate"
                  control={control}
                  disableFuture
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="eDate" label="End Date">
                <CDatePicker name="eDate" control={control} />
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt="1rem">
            <Grid item xs={12}>
              <Typography variant="h4grey">
                {labels?.shelterCosts ?? "Shelter Costs:"}
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              padding: 2,
              paddingBottom: 5,
              // boxShadow: 3,
              border: "1px solid var(--grey-250)",
              borderRadius: "5px",
              marginTop: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={5}
                sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
              >
                <Stack>
                  <Field
                    name="meap"
                    label="Have you received MEAP within the past 12months?"
                  >
                    <Controller
                      name="meap"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} />;
                      }}
                    />
                  </Field>
                </Stack>

                <Stack>
                  <Field
                    name="subsHouse"
                    label="Do you live in subsidized housing?"
                  >
                    <Controller
                      name="subsHouse"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} />;
                      }}
                    />
                  </Field>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
              >
                <Stack>
                  <Field
                    name="rentPayWater"
                    label="Do you pay for water/wastewater seperately from your rent?"
                  >
                    <Controller
                      name="rentPayWater"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} />;
                      }}
                    />
                  </Field>
                </Stack>
                <Stack>
                  <Field
                    name="rentPayHouse"
                    label="Do you pay for heating/cooling separately from your rent?"
                  >
                    <Controller
                      name="rentPayHouse"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} />;
                      }}
                    />
                  </Field>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2} mt="1rem">
            <Grid item xs={12}>
              <Typography variant="h4grey">
                {labels?.urExpense ?? " Please select your expense type:"}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1 }}>
            <Box
              sx={{
                padding: 2,
                paddingBottom: 5,
                marginTop: 2,
                gap: "2rem",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  paddingBottom: 2,
                  paddingRight: 2,
                  border: "1px solid var(--grey-250)",
                  borderRadius: "5px",
                }}
              >
                <Grid item xs={4}>
                  <Field name="rent" label="Rent">
                    <CoTextField
                      name="rent"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="mortgage" label="Mortgage">
                    <CoTextField
                      name="mortgage"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="groundRent" label="Ground/Lot Rent">
                    <CoTextField
                      name="groundRent"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  paddingBottom: 2,
                  paddingRight: 2,
                  border: "1px solid var(--grey-250)",
                  borderRadius: "5px",
                }}
              >
                <Grid item xs={4}>
                  <Field name="condoFee" label="Condo Fee">
                    <CoTextField
                      name="condoFee"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="taxes" label="Taxes">
                    <CoTextField
                      name="taxes"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="homeowner" label="Homeowner's Insurance">
                    <CoTextField
                      name="homeowner"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Grid container mt="1rem">
            <Grid item xs={12}>
              <Typography variant="h4grey">
                {labels?.utilType ?? " Please Select your Utility type:"}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ border: "1px solid #e4e4e4", padding: "1rem" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field name="gas" label="Gas">
                  <CoTextField
                    name="gas"
                    control={control}
                    normalizers={[formatCurrency]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> $</InputAdornment>
                      ),
                    }}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="electric" label="Electric">
                  <CoTextField
                    name="electric"
                    control={control}
                    normalizers={[formatCurrency]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> $</InputAdornment>
                      ),
                    }}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="water" label="Water">
                  <CoTextField
                    name="water"
                    control={control}
                    normalizers={[formatCurrency]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> $</InputAdornment>
                      ),
                    }}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="oil" label="Oil">
                  <CoTextField
                    name="oil"
                    control={control}
                    normalizers={[formatCurrency]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> $</InputAdornment>
                      ),
                    }}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="sewage" label="Sewage">
                  <CoTextField
                    name="sewage"
                    control={control}
                    normalizers={[formatCurrency]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> $</InputAdornment>
                      ),
                    }}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="garbage" label="Garbage">
                  <CoTextField
                    name="garbage"
                    control={control}
                    normalizers={[formatCurrency]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> $</InputAdornment>
                      ),
                    }}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="phone" label="Phone">
                  <CoTextField
                    name="phone"
                    control={control}
                    normalizers={[formatCurrency]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> $</InputAdornment>
                      ),
                    }}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="otherUtils" label="Other Utilities">
                  <CoTextField
                    name="otherUtils"
                    control={control}
                    normalizers={[formatCurrency]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> $</InputAdornment>
                      ),
                    }}
                  />
                </Field>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </Box>
      {activeTab === 0 && (
        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} mt={2}>
          <Button variant="outlined" onClick={toggleShelter}>
            Discard
          </Button>
          <Button variant="contained" onClick={handleSubmit(handleNext)}>
            Next
          </Button>
        </Stack>
      )}
      {activeTab == 1 && (
        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} mt={2}>
          <Button variant="outlined" onClick={toggleShelter}>
            Discard
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit(onSave)}
          >
            Save
          </Button>
        </Stack>
      )}
    </>
  );
}

import { createContext, useMemo, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HttpClient from "./utils/HttpClient";
import PrivateRoute from "./containers/PrivateRoute";
import Login from "./pages/Login";
import RaceDisability from "./pages/HouseholdInformation/RaceDisability";
import Layout from "./containers/Layout";
import PageNotFound from "./pages/PageNotFound";
import HohContainer from "./containers/HohContainer";
import ApplicationCont from "./containers/ApplicationCont";
import PreferredLang from "./pages/HouseholdInformation/PreferredLang";
import PersonalRequest from "./pages/HouseholdInformation/PersonalBenifitRequest";
import StudentInfo from "./pages/HouseholdInformation/StudentInfo";
import HOHInfo from "./pages/HouseholdInformation/HOHInfo";
import GetStarted from "./pages/GetStarted";
import Dashboard from "./pages/Dashboard";
import MedicareDetails from "./pages/MedicareDetails";
import CourtOrder from "./pages/CourtOrder";
import MemberContainer from "./containers/MemberContainer";
import FinancialContainer from "./containers/FinancialContainer";
import StrikerStatus from "./pages/Striker";
import MedicalExpenses from "./pages/MedicalExpenses";

import AddressInfo from "./pages/HouseholdInformation/AddressInfo";
import ContactInfo from "./pages/HouseholdInformation/ContactInfo";
import AssistanceContainer from "./containers/AssistanceContainer";
import SnapQualifier from "./pages/SnapQualification";
import MemberInfo from "./pages/MemberInformation/MemberInfo";
import RaceDisabilityMember from "./pages/MemberInformation/RaceDisability";
import AddressInfoMember from "./pages/MemberInformation/AddressInfoMember";
import ContactInfoMember from "./pages/MemberInformation/ContactInfoMember";
import StudentInfoMember from "./pages/MemberInformation/StudentInfoMember";
import MemberSummary from "./pages/MemberInformation/MemberSummary";
import AddHoh from "./pages/MemberInformation/AddHoh";
import Assets from "./pages/MemberAssets";
import ShelterUtility from "./pages/ShelterUtility";
import CaseSummary from "./pages/CaseSummary";
import AuthRep from "./pages/AuthRep";
import RelationshipInfo from "./pages/RelationshipInfo";
import EarnedIncome from "./pages/EarnedIncome";
import AuthorizedRep from "./pages/AuthRep/AuthRepContainer";
import Citizenship from "./pages/Citizenship";
// import RelationshipInfo from "./pages/RelationshipInfo";
// import EarnedIncome from "./pages/EarnedIncome";
import AppSubmit from "./pages/AppSubmit";
import ProgramQuestionsContainer from "./containers/ProgramQuestionsContainer";
import HOHDeclInquiry from "./pages/ProgramQuestions/HohDeclarationInquiry";
import FileuploadContainer from "./containers/FileUploadContainer";
import UnearnedIncome from "./pages/FinancialInformation/UnEarnedIncome";
import DocumentUpload from "./pages/DocumentUpload";
import TransferAssets from "./pages/TransferAsset";
import { useSelector } from "react-redux";
import { getAuthToken } from "./store/selectors/authSelector";
import Registration from "./pages/Registration";
import LandingPage from "./pages/LandingPage";
import AmIEligible from "./pages/AmIEligible";
import RoomerBoarder from "./pages/RoomerBoarder";
import EventEmitter from "tiny-eventemitter";
import ImEligible from "./pages/Eligible";
export const eventEmitter = new EventEmitter();
export const PageContext = createContext();

function App() {
  const [currentPage, setCurrentPage] = useState("Home page");
  const token = useSelector(getAuthToken);
  const [expandSideMenu, setExpandSideMenu] = useState(true);
  const [screenId, setScreenId] = useState(-1);
  console.log(token, "token");

  useMemo(() => {
    HttpClient.interceptors.request.handlers = [];
    HttpClient.interceptors.request.use(
      (config) => {
        if (token && token?.sub) {
          config.headers = {
            ...config.headers,
            uniqueid: token?.sub,
            uid: "localUser",
            role: "cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN",
            // role: token?.resource_access?.devcp?.roles[0],
          };
        }
        // if (config.url.includes("uniqueId")) {
        //   config.url = config.url.replace(
        //     "{uniqueId}",
        //     config.headers.Uniqueid
        //   );
        // }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [token]);

  return (
    <div className="App">
      <PageContext.Provider
        value={{
          currentPage,
          setCurrentPage,
          expandSideMenu,
          setExpandSideMenu,
          screenId,
          setScreenId,
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate to="landingPage" />} />
          <Route path="landingPage" element={<LandingPage />} />
          <Route path="login" element={<Login />} />
          <Route path="registration" element={<Registration />} />
          <Route
            path=""
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          >
            {/* <Route path="" element={<Navigate to="dashboard" />} /> */}
            <Route path="dashboard" element={<Dashboard screenId={428} />} />
            <Route path="getstarted" element={<GetStarted screenId={429} />} />
            {/* <Route path="/" element={<Navigate to="dashboard" />} /> */}

            <Route path="dashboard" element={<Dashboard />} />
            <Route path="getstarted" element={<GetStarted />} />

            <Route path="application" element={<ApplicationCont />}>
              <Route path="amIeligible" element={<ImEligible />} />
              <Route path="application-submit" element={<AppSubmit />} />
              <Route path="summary" element={<CaseSummary screenId={167} />} />
              <Route path="assistance" element={<AssistanceContainer />}>
                <Route
                  path="snap-qualifier"
                  element={<SnapQualifier screenId={197} />}
                />
              </Route>
              <Route path="" element={null} />
              <Route path="hoh" element={<HohContainer />}>
                <Route
                  path="preferred-lang"
                  element={<PreferredLang screenId={401} />}
                />
                <Route
                  path="currentAddress"
                  element={<AddressInfo screenId={131} />}
                />
                <Route
                  path="contact-info"
                  element={<ContactInfo screenId={149} />}
                />
                <Route
                  path="personal-benefit-request"
                  element={<PersonalRequest screenId={420} />}
                />
                <Route
                  path="student-info"
                  element={<StudentInfo screenId={413} />}
                />
                <Route
                  path="head-of-household"
                  element={<HOHInfo screenId={148} />}
                />

                <Route
                  path="race-disability"
                  element={<RaceDisability screenId={126} />}
                />
              </Route>
              <Route path="member" element={<MemberContainer />}>
                <Route
                  path="addHousehold-member"
                  element={<AddHoh screenId={418} />}
                />
                <Route
                  path="member-info"
                  element={<MemberInfo screenId={422} />}
                />
                <Route
                  path="disability-info"
                  element={<RaceDisabilityMember screenId={417} />}
                />
                <Route
                  path="address-info"
                  element={<AddressInfoMember screenId={416} />}
                />
                <Route
                  path="contact-info"
                  element={<ContactInfoMember screenId={414} />}
                />
                <Route
                  path="student-info"
                  element={<StudentInfoMember screenId={419} />}
                />
                <Route
                  path="member-summary"
                  element={<MemberSummary screenId={151} />}
                />
                <Route
                  path="member-relationship"
                  element={<RelationshipInfo screenId={201} />}
                />
                <Route
                  path="citizenship"
                  element={<Citizenship screenId={150} />}
                />
                <Route
                  path="auth-rep"
                  element={<AuthorizedRep screenId={195} />}
                />
                <Route
                  path="medicare-details"
                  element={<MedicareDetails screenId={154} />}
                />
                <Route path="roomer-boarder" element={<RoomerBoarder />} />
              </Route>

              <Route path="financial" element={<FinancialContainer />}>
                <Route path="assets" element={<Assets screenId={158} />} />
                <Route
                  path="transfer-assets"
                  element={<TransferAssets screenId={430} />}
                />
                <Route
                  path="potential-assets"
                  element={<div screenId={159}>Potential Assets</div>}
                />
                <Route
                  path="earned-income"
                  element={<EarnedIncome screenId={160} />}
                />
                <Route
                  path="unearned-income"
                  element={<UnearnedIncome screenId={161} />}
                />
                <Route
                  path="striker"
                  element={<StrikerStatus screenId={314} />}
                />
                <Route
                  path="medical-expenses"
                  element={<MedicalExpenses screenId={162} />}
                />
                <Route
                  path="shelter-utility"
                  element={<ShelterUtility screenId={163} />}
                />
                <Route
                  path="care-expenses"
                  element={<div screenId={429}>Care Expenses</div>}
                />
                <Route
                  path="court-order"
                  element={<CourtOrder screenId={165} />}
                />
                {/* <Route path="student-info" element={<StudentInfo />} />
                <Route path="head-of-household" element={<HOHInfo />} />
                <Route path="race-disability" element={<RaceDisability />} />
                <Route path="court-order" element={<CourtOrder />} />
                <Route path="race-disability" element={<RaceDisability />} /> */}
                {/* <Route path="assets" element={<Assets />} /> */}
              </Route>
              <Route path="upload" element={<FileuploadContainer />}>
                <Route
                  path="document-upload"
                  element={<DocumentUpload screenId={166} />}
                />
              </Route>

              <Route
                path="questions"
                element={<ProgramQuestionsContainer screenId={169} />}
              >
                <Route
                  path="hoh-inquiry"
                  element={<HOHDeclInquiry screenId={169} />}
                />
              </Route>
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </PageContext.Provider>
    </div>
  );
}

export default App;
